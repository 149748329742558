import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import messages from "../messages.json";

const AddGroupInterest = () => {
  const { id } = useParams(); // Extract category ID from URL params
  const [formData, setFormData] = useState({
    name: "",
  });
  console.log("this is test",formData);
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchCategoryData(id);
    }
  }, [id]);

  const fetchCategoryData = async (interestId) => {
    try {
      const response = await axios.get(`${apiUrl}/getGroupInterestById/${interestId}`);
      const categoryInfo = response.data;
      setFormData({
        name: categoryInfo.name,
      });
    } catch (error) {
      console.error(messages.error.failedFetchData, error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("check data before api",formData);
    try {
      if (id) {
        await axios.put(`${apiUrl}/updateInterestGroup/${id}`, formData);
        toast.success(messages.success.update, { autoClose: 2000 });
      } else {
        await axios.post(`${apiUrl}/addGroupInterest`, formData);
        toast.success(messages.success.dataAdded, { autoClose: 2000 });
      }
      setFormData({ name: "" });
      
      setTimeout(() => {
        navigate("/group-interest");
      }, 1000);
    } catch (error) {
      console.error(messages.error.errorAdding, error);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value
    }));
  };

  const handleClose = () => {
    navigate("/group-interest");
  };

  return (
    <div className="container mt-4">
     <div className="row">
      <div className="col-md-8 mx-auto">
      <div className="card p-0 ">
        <div className=" card-body w-full p-0 w-full pb-3">
          <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
            <h2 className="card-title mb-0">{id ? "Update Interest" : "Add Interest"}</h2>
            <FaTimes className="text-danger cursor-pointer" onClick={handleClose} />
          </div>
          <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
            <div className="mb-3 col-md-12">
              <label htmlFor="name" className="form-label">
                Interest
              </label>
              <input
                type="text"
                className="form-control"
                autoComplete="off"
                id="name"
                placeholder="Enter Interest"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90 ms-2">
              {id ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </div>
      </div>
     </div>
    </div>
  );
};

export default AddGroupInterest;
