import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import InterestInformation from "../forms/InterestInformation";
import DeleteConfirmation from "../forms/DeleteConformation";
import { toast, ToastContainer } from "react-toastify";
import messages from "../messages.json";
import Pagination from "../components/Pagination";
import CustomTable from "../components/commonComponent/CustomTable";

const Interest = () => {
  const [interests, setInterests] = useState([]);
  const [filteredInterests, setFilteredInterests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [interestsPerPage] = useState(10);
  const [selectedInterest, setSelectedInterest] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [interestToDelete, setInterestToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/sendInterestAndPreference`);
        setInterests(response.data.data.events);
        setFilteredInterests(response.data.data.events);
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };

    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    filterInterests();
  }, [searchInput, interests, selectedStatuses]);

  const filterInterests = () => {
    let filtered = interests;

    if (searchInput !== "") {
      filtered = filtered.filter(
        (interest) =>
          interest.name &&
          interest.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    if (selectedStatuses.length > 0) {
      filtered = filtered.filter((interest) =>
        selectedStatuses.includes(interest.isActive ? "Active" : "Inactive")
      );
    }

    setFilteredInterests(filtered);
  };

  const handleShowDeleteModal = (interestId) => {
    setInterestToDelete(interestId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setInterestToDelete(null);
  };

  const handleDelete = async () => {
    if (interestToDelete) {
      try {
        await axios.delete(`${apiUrl}/deleteInterest/${interestToDelete}`);
        setInterests(interests.filter((interest) => interest._id !== interestToDelete));
        toast.success(messages.success.delete, {
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate('/kitty-interest');
        }, 2500);
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setInterestToDelete(null);
      }
    }
  };

  const handleUpdate = (interestId) => {
    navigate(`/kitty-interest/update/${interestId}`);
  };

  const handleStatusToggle = async (interestId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await axios.patch(`${apiUrl}/updateInterestStatus/${interestId}`, {
        isActive: updatedStatus,
      });

      setInterests(
        interests.map((interest) =>
          interest._id === interestId
            ? { ...interest, isActive: updatedStatus }
            : interest
        )
      );
      toast.success(
        `Interest status updated to ${updatedStatus ? "Active" : "Inactive"}`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const handleView = async (interestId) => {
    try {
      const response = await axios.get(`${apiUrl}/getInterestById/${interestId}`);
      setSelectedInterest(response.data);
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedInterest(null);
  };

  const indexOfLastInterest = currentPage * interestsPerPage;
  const indexOfFirstInterest = indexOfLastInterest - interestsPerPage;
  const currentInterests = filteredInterests.slice(indexOfFirstInterest, indexOfLastInterest);
  const totalPages = Math.ceil(filteredInterests.length / interestsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevState) =>
      prevState.includes(status)
        ? prevState.filter((item) => item !== status)
        : [...prevState, status]
    );
  };

  const columns = [
    { header: 'S.No', accessor: 'id' },
    { header: 'Interest', accessor: 'name' },
   
  ];  


  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">Interests Information</h2>
        </div>
        <div className="col-auto">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <span className="input-group-text">
              <FaSearch />
            </span>
          </div>
        </div>
        <div className="col-auto">
          <div className="dropdown d-inline-block me-2">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1"
              type="button"
              onClick={() => setShowStatusDropdown(!showStatusDropdown)}
            >
              Status
              <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showStatusDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Active"
                      id="status-active"
                      checked={selectedStatuses.includes("Active")}
                      onChange={() => handleStatusChange("Active")}
                    />
                    <label className="form-check-label" htmlFor="status-active">
                      Active
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Inactive"
                      id="status-inactive"
                      checked={selectedStatuses.includes("Inactive")}
                      onChange={() => handleStatusChange("Inactive")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="status-inactive"
                    >
                      Inactive
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="col-auto">
          <Link to="/kitty-interest/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add Interest
          </Link>
        </div>
      </div>
    
      <CustomTable
        tableData={filteredInterests}
        columns={columns}
        handleStatusToggle={handleStatusToggle}
        showStatus={true}
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView: true,
          showEdit: true,
          showDelete: true,
        }}
      />
      {showModal && selectedInterest && (
        <InterestInformation
          show={showModal}
          handleClose={handleCloseModal}
          interest={selectedInterest}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmation
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={handleDelete}
        />
      )}
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default Interest;
