import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

const BarChart = ({ data }) => (
  <Chart
    options={{
      chart: { type: 'bar' },
      xaxis: { categories: ['Statistics'] },
    }}
    series={data}
    type="bar"
    height="300"
  />
);

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BarChart;
