import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";


const AddVenueCategoryForm = () => {
  const { id } = useParams(); 
  const [formData, setFormData] = useState({
    name: "",
  });
  
  const [imagePreview, setImagePreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null); // State to hold the image URL

  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchinterestData(id);
    }
  }, [id]);

  const fetchinterestData = async (venuecategoryId) => {
    try {
      const response = await axios.get(`${apiUrl}/getVenueCategoryById/${venuecategoryId}`);
      const interestData = response.data;
      setFormData({
        name: interestData?.name,
        image: interestData?.image
      });
      setImagePreview(interestData?.image); // Set initial image preview if editing
    } catch (error) {
      console.error("Failed to fetch interest data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

  

    try {
      const dataToSend = {
        name: formData?.name,
       
      };

      console.log("Payload to send:", dataToSend);

      if (id) {
        await axios.put(`${apiUrl}/updateVenueCategory/${id}`, dataToSend);
        toast.success("venuecategory Updated Successfully", {
          autoClose: 2000,
        });
      } else {
        await axios.post(`${apiUrl}/addVenueCategory`, dataToSend);
        toast.success("venuecategory Added Successfully", {
          autoClose: 2000,
        });
      }

      setFormData({
        name: "",
        
      });
      setImagePreview(null);
      setImageUrl(null);

      setTimeout(() => {
        navigate('/kitty-venue-category');
      }, 1000);
    } catch (error) {
      console.error("Failed to submit venuecategory data:", error);
    }
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
    setImagePreview(URL.createObjectURL(file)); // Update image preview
    console.log("File selected:", file); // Log file details
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleClose = () => {
    navigate('/kitty-venue-category');
  };
  const handleImageRemove = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: null,
    }));
    setImagePreview(null); // Remove image preview
  };

  return (
    <div className="container mt-4">
     <div className="row">
       <div className="col-md-8 mx-auto">
        <div className="card p-0">
        <div className=" card-body w-full p-0 pb-3">
          <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
            <h2 className="card-title mb-0">{id ? "Update interest" : "Add venuecategories"}</h2>
            <FaTimes className="text-danger cursor-pointer" onClick={handleClose} />
          </div>
          <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
            <div className="mb-3 col-md-12 p-2">
              <label htmlFor="name" className="form-label">Venue Category Name</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="name"
                placeholder="venuecategory name"
                value={formData?.name}
                onChange={handleChange}
                required
              />
            </div>
        
            <button type="submit" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90 ms-2">
              {id ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </div>
</div>
     </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default AddVenueCategoryForm;
