import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

const GroupInformation = ({ show, handleClose, group }) => {
  console.log("Group Data:", group);
  const [users, setUsers] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllUsersList`);
        // console.log(response,"check the useriiddddddddddddddddddddd");

        setUsers(response?.data?.data);
      } catch (error) {
        console.log("Error fetching users data", error);
      }
    };

    fetchUserList();
  }, [apiUrl]);

  const getUserNameById = (userId) => {
    console.log(userId,"uuseridddd");
  
    return userId?.fullname ? userId?.fullname : 'Unknown User';
  };

  return (
<Modal show={show} onHide={handleClose} className="modal-lg modal-dialog-centered">
  <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-7xl mx-auto">
    <Modal.Header className="bg-[#0e121a] text-white border-b border-gray-200">
      <Modal.Title className="text-xl font-semibold">Group Information</Modal.Title>
      <Button variant="close" onClick={handleClose} className="text-white hover:text-gray-400">
        &times;
      </Button>
    </Modal.Header>

    <Modal.Body className="p-6">
      {group ? (
        <div className="flex flex-wrap space-y-2">
          <div className="w-1/2">
            <p className="text-gray-700 mb-[10px]"><strong>Name:</strong> <span className="text-gray-500 font-medium capitalize">{group.name}</span></p>
            <p className="text-gray-700 mb-[10px]"><strong>Group Type:</strong> <span className="text-gray-500">{group.groupType}</span></p>
            <p className="text-gray-700 mb-[10px]"><strong>Description:</strong> <span className="text-gray-500">{group.description}</span></p>
            <p className="text-gray-700 mb-[10px]"><strong>Rules and Regulation:</strong> <span className="text-gray-500">{group.rulesAndRegulation}</span></p>
            <p className="text-gray-700 mb-[10px]"><strong>Group City/Area:</strong> <span className="text-gray-500">{group.groupCityArea}</span></p>
            <p className="text-gray-700 mb-[10px]"><strong>Contribution Amount:</strong> <span className="text-gray-500">{group.contributionAmount}</span></p>
            <p className="text-gray-700 mb-[10px]"><strong>Status:</strong> <span className={`${group.isActive ? 'text-green-500' : 'text-red-500'}`}>{group.isActive ? 'Active' : 'Inactive'}</span></p>
          </div>

          <div className="w-1/2">
            <p className="text-gray-700 mb-[10px]"><strong>Image:</strong></p>
            <img 
              src={group.image} 
              alt={group.name} 
              className="w-1/2 max-h-64 object-cover rounded-md shadow-md mb-4"
            />
            <p className="text-gray-700 mb-[10px]"><strong>Group Frequency:</strong></p>
            <ul className="list-disc ml-4 text-gray-500">
              {group.groupFrequencyId ? <li>{group?.groupFrequencyId?.name}</li> : <li>No frequency</li>}
            </ul>

            <p className="text-gray-700 mb-[10px]"><strong>Group Interests:</strong></p>
            <ul className="list-disc ml-4 text-gray-500">
              {group?.groupInterestId && group?.groupInterestId?.length > 0 ? (
                group?.groupInterestId.map(interest => (
                  <li key={interest?._id}>{interest?.name}</li>
                ))
              ) : (
                <li>No interests</li>
              )}
            </ul>
          </div>

          <div className="w-full">
            <p className="text-gray-700 mb-[10px]"><strong>Group Members:</strong></p>
            <ul className="list-disc ml-4 text-gray-500">
              {group?.userIds && group?.userIds?.length > 0 ? (
                group?.userIds.map(user => (
                  <li key={user?._id}>{getUserNameById(user?.userId)}</li>
                ))
              ) : (
                <li>No members</li>
              )}
            </ul>
          </div>
        </div>
      ) : (
        <p className="text-gray-600">Loading...</p>
      )}
    </Modal.Body>

    <Modal.Footer className="bg-gray-100 p-3">
      <Button variant="secondary" onClick={handleClose} className="bg-gray-600 text-white hover:bg-gray-700 transition duration-200">
        Close
      </Button>
    </Modal.Footer>
  </div>
</Modal>

  );
};

export default GroupInformation;
