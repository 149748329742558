import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { DatePicker } from "antd";
import moment from 'moment';  // Import moment for date handling
import messages from "../messages.json";

const AddFeedbackForm = () => {
  const { id } = useParams(); 
  const [formData, setFormData] = useState({
    userId: "",
    description: "",
    createdAt: null  // Initialize as null for DatePicker compatibility
  });
  
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  // Fetch feedback data when the component loads and if 'id' exists
  useEffect(() => {
    if (id) {
      fetchFeedbackData(id);
    }
  }, [id]);

  const fetchFeedbackData = async (FeedbackId) => {
    try {
      const response = await axios.get(`${apiUrl}/getFeedbackById/${FeedbackId}`);
      const FeedbackData = response.data;
      setFormData({
        description: FeedbackData.description,
        createdAt: moment(FeedbackData.createdAt),  // Use moment to handle date
      });
    } catch (error) {
      console.error("Failed to fetch Feedback data:", error);
    }
  };

  // Fetch user data from local storage
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user._id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: user._id,
      }));
    } else {
      console.error('User ID not found in local storage');
    }
  }, []);

  // Handle date change for DatePicker
  const handleDateTimeChange = (key, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [key]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSend = {
        userId: formData.userId,
        description: formData.description,
        createdAt: formData.createdAt  // Send the date in its moment format
      };

      if (id) {
        await axios.put(`${apiUrl}/updateFeedback/${id}`, dataToSend);
        toast.success("Feedback Updated Successfully", {
          autoClose: 2000,
        });
      } else {
        await axios.post(`${apiUrl}/createFeedback`, dataToSend);
        toast.success(messages.success.dataAdded, { autoClose: 2000 });
      }

      // Reset form
      setFormData({
        userId: "",
        description: "",
        createdAt: null
      });

      // Navigate back to feedback page after 1 second
      setTimeout(() => {
        navigate('/kitty-feedback');
      }, 1000);
    } catch (error) {
      console.error("Failed to submit Feedback data:", error);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleClose = () => {
    navigate('/kitty-feedback');
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="card p-0">
            <div className=" card-body w-full p-0 pb-3">
              <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
                <h2 className="card-title mb-0">{id ? "Update Feedback" : "Add Feedback"}</h2>
                <FaTimes className="text-danger cursor-pointer" onClick={handleClose} />
              </div>
              <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
                <div className="mb-3 col-md-6 p-2">
                  <label htmlFor="description" className="mb-2 block text-sm font-medium text-black dark:text-white">
                    Description
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    id="description"
                    placeholder="description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-md-6 p-2">
                  <label className="mb-2 block text-sm font-medium text-black dark:text-white">Date</label>
                  <DatePicker
                    onChange={(date) => handleDateTimeChange("createdAt", date)}
                    value={formData.createdAt ? moment(formData.createdAt) : null}  // Ensure the value is in moment format
                    className="form-control"
                  />
                </div>
                <button type="submit" className="flex ms-2 justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
                  {id ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default AddFeedbackForm;
