import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { FaTimes } from "react-icons/fa";


const AddUserForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // Extract user ID from URL params
  const [formData, setFormData] = useState({
    fullname: "",
    dob: "",
    specificintrests: "",
    email: "",
    profession: "",
    location: "",
    phoneNumber: "",
    emergencyNumber: "",
    about: "",
    sociallinks: {
      instaurl: "",
      Linkedinurl: "",
      Websiteurl: "",
    },
  });
  const [suggestions, setSuggestions] = useState([]);
  const [errors, setErrors] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (id) {
      fetchUserData(id);
    }
  }, [id]);

  const fetchUserData = async (userId) => {
    try {
      const response = await axios.get(`${apiUrl}/getuserById/${userId}`);
      console.log(response.data.data, "testing");
    
      const userData = response.data.data;
      
      // Extract social links
      const socialLinks = userData.sociallinks.length > 0 ? userData.sociallinks[0] : { instaurl: "", Linkedinurl: "", Websiteurl: "" };
    
      // Set form data
      setFormData(prevFormData => ({
        ...prevFormData,  // Keep previous form data intact
        fullname: userData.fullname,
        email: userData.email,
        profession: userData.profession,
        specificintrests: userData.specificintrests,
        location: userData.location,
        dob: userData.dob ? userData.dob.split("T")[0] : "", // Ensure date format is correct
        about: userData.about,
        phoneNumber: userData.phoneNumber,
        emergencyNumber: userData.emergencyNumber,
      }));
    
      // Set social links separately to ensure it updates correctly
      setFormData(prevFormData => ({
        ...prevFormData,  // Keep previous form data intact
        sociallinks: {
          instaurl: socialLinks.instaurl || "",
          Linkedinurl: socialLinks.Linkedinurl || "",
          Websiteurl: socialLinks.Websiteurl || "",
        }
      }));
    
    } catch (error) {
      console.error("Error fetching user data", error);
    }
    
  };

  const validate = () => {
    const newErrors = {};
    const currentDate = new Date();

    if (!formData.fullname) {
      newErrors.fullname = "fullname is required";
    }

    if (!formData.dob) {
      newErrors.dob = "Date of Birth is required";
    } else if (new Date(formData.dob) > currentDate) {
      newErrors.dob = "Date of Birth cannot be in the future";
    }
  
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formData.profession) {
      newErrors.profession = "Profession is required";
    }
  
    if (!formData.location) {
      newErrors.location = "Location is required";
    }
    if (!formData.emergencyNumber) {
      newErrors.emergencyNumber = "Emergency Number is required";
    } else if (!/^\d{10}$/.test(formData.emergencyNumber)) {
      newErrors.emergencyNumber = "Emergency Number must be 10 digits";
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone Number must be 10 digits";
    }
  
    return newErrors;
  };
  const handleBlur = (e) => {
    const { id } = e.target;
    const validationErrors = validate();
    
    if (validationErrors[id]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: validationErrors[id],
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});

    try {
      if (id) {
        await axios.put(`${apiUrl}/updateUserInfo/${id}`, formData);
        toast.success("User Updated Successfully", {
          autoClose: 2000,
        });
      } else {
        await axios.post(`${apiUrl}/adduserInfo`, formData);
        toast.success("User Added Successfully", {
          autoClose: 2000,
        });
      }
      setFormData({
        fullname: "",
        dob: "",
        about: "",
        specificintrests:"",
        email: "",
        profession: "",
        location: "",
        phoneNumber: "",
        emergencyNumber: "",
        sociallinks: [{
          instaurl: "",
          Linkedinurl: "",
          Websiteurl: "",
        }],
        
      });
      // Delay navigation after toast message
      
      setTimeout(() => {

        navigate("/kitty-users");
      }, 2500); // Adjust the delay as needed
    } catch (error) {
      console.error("Failed to submit user data:", error);
      toast.error(error?.response?.data?.error, {
        autoClose: 2000,
      });
    }
   
  };

  const handleChange = async (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));

    // Reset the error for the current field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));

    if (id === "location" && value.length > 2) {
      const locationApiUrl = `https://api.olamaps.io/places/v1/autocomplete?input=${value}&api_key=TdoidCwf5FxL1rVCqS7KnbWT8Gq561obQaMWzBoR`;
      try {
        const response = await axios.get(locationApiUrl);
        setSuggestions(response.data.predictions || []);
      } catch (error) {
        console.error("Error fetching location suggestions:", error);
      }
      
    } else if (id === "location") {
      setSuggestions([]);
    }
  };
  

  const handleSelect = (suggestion) => {
    const { description } = suggestion;
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: description,
    }));
    setSuggestions([]);
  };

  const handleClose = () => {
    navigate("/kitty-users");
  };
  return (
    <div className="container mt-4">
     <div className="row">
      <div className="col-md-8 mx-auto">
      <div className="card p-0 ">
        <div className=" card-body w-full p-0 pb-3 w-full p-0 pb-3">
          <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center">
            <h2 className="card-title mb-0">{id ? "Update User" : "Add User"}</h2>
            <FaTimes
              className="text-danger cursor-pointer"
              onClick={handleClose}
            />
          </div>

          <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="fullname" className="mb-2 block text-sm font-medium text-black dark:text-white">
                Full Name
              </label>
              <input
                type="text"
              
                autoComplete="off"
                className="form-control"
                id="fullname"
                placeholder="Enter full name"
                value={formData.fullname}
                onChange={handleChange}
                onBlur={handleBlur}
               
              />
              {errors.fullname && (
                <div className="text-danger mt-1">{errors.fullname}</div>
              )}
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="dob" className="mb-2 block text-sm font-medium text-black dark:text-white">
                Date of Birth
              </label>
              <input
                type="date"
                autoComplete="off"
                className="form-control"
                id="dob"
                placeholder="Enter date of birth"
                value={formData.dob}
                onChange={handleChange}
                onBlur={handleBlur}
                
              />
              {errors.dob && (
                <div className="text-danger mt-1">{errors.dob}</div>
              )}
            </div>
           
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="email" className="mb-2 block text-sm font-medium text-black dark:text-white">
                Email
              </label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
             
              />
              {errors.email && (
                <div className="text-danger mt-1">{errors.email}</div>
              )}
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="location" className="mb-2 block text-sm font-medium text-black dark:text-white">
                Location
              </label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="location"
                placeholder="Enter location"
                value={formData.location}
                onChange={handleChange}
                onBlur={handleBlur}
               
              />
              {suggestions.length > 0 && (
                <ul className="suggestions-list">
                  {suggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => handleSelect(suggestion)}>
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="profession" className="mb-2 block text-sm font-medium text-black dark:text-white">
                Profession
              </label>
              <select
                type="text"
                autoComplete="off"
                className="form-control"
                id="profession"
                placeholder="Enter profession"
                value={formData.profession}
                onChange={handleChange}
                onBlur={handleBlur}
               
              >
                <option value="">Select Profession type</option>
                <option value="Human Resource Manager">Human Resource Manager</option>
                <option value="Teacher">Teacher</option>
                <option value="Lawyer">Lawyer</option>
                <option value="Nurse Practitioner">Nurse Practitioner</option>
                <option value="Pharmacist">Pharmacist</option>
                <option value="Public Relations">Public Relations</option>
                <option value="Psychologist">Psychologist</option>
                <option value="Speech Therapist">Speech Therapist</option>
                <option value="Dental Hygienist">Dental Hygienist</option>
                <option value="Computer Programmer">Computer Programmer</option>
                <option value="Childcare Workers">Childcare Workers</option>
                <option value="Hairdresser">Hairdresser</option>
                <option value="Occupational Therapist">Occupational Therapist</option>
                <option value="CEO">CEO</option>
                <option value="Dietitians and Nutritionists">Dietitians and Nutritionists</option>
                <option value="Education">Education</option>
                <option value="Marketing Manager">Marketing Manager</option>
                <option value="Registered Nurse">Registered Nurse</option>
                <option value="Surgeon">Surgeon</option>
                <option value="Veterinarian">Veterinarian</option>
                <option value="Management Analyst">Management Analyst</option>
                <option value="Financial Analyst">Financial Analyst</option>
                <option value="Physician">Physician</option>
                <option value="IT Managers">IT Managers</option>
                <option value="HomeMaker">HomeMaker</option>
                <option value="Other">Other</option>

              </select>
              {errors.profession && (
                <div className="text-danger mt-1">{errors.profession}</div>
              )}
            
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="phoneNumber" className="mb-2 block text-sm font-medium text-black dark:text-white">
                Phone Number
              </label>
              <input
                type="tel"
                autoComplete="off"
                className="form-control"
                id="phoneNumber"
                placeholder="Enter phone number"
                value={formData.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.phoneNumber && (
                <div className="text-danger mt-1">{errors.phoneNumber}</div>
              )}
            </div>
            <div className="mb-3 col-md-6 p-2"> 
              <label htmlFor="specificintrests" className="mb-2 block text-sm font-medium text-black dark:text-white">
              Specific Intrests
              </label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="specificintrests"
                placeholder="Enter Interest"
                value={formData.specificintrests}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="emergencyNumber" className="mb-2 block text-sm font-medium text-black dark:text-white">
                Emergency Number
              </label>
              <input
                type="tel"
                autoComplete="off"
                className="form-control"
                id="emergencyNumber"
                placeholder="Enter emergency number"
                value={formData.emergencyNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                
              />
              {errors.emergencyNumber && (
                <div className="text-danger mt-1">{errors.emergencyNumber}</div>
              )}
            </div>
            <div className="mb-3 col-md-12 p-2">
              <label htmlFor="about" className="mb-2 block text-sm font-medium text-black dark:text-white">
                About me
              </label>
              <textarea
                className="form-control"
                id="about"
                rows="3"
                placeholder="Enter details about the user"
                value={formData.about}
                onChange={handleChange}
                
              />
            </div>

            <div className="mb-3 col-md-12 p-2">
              <label htmlFor="sociallinks" className="form-label">
                Social Links
              </label>
            <div className="flex ">
              
              <div className="mb-3 col-md-4 ">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="instaurl"
                  placeholder="Instagram URL"
                  value={formData.sociallinks.instaurl}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      sociallinks: {
                        ...formData.sociallinks,
                        instaurl: e.target.value,
                      },
                    })
                  }
                />
              </div>
              <div className="mb-3 col-md-4 px-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="Linkedinurl"
                  placeholder="LinkedIn URL"
                  value={formData.sociallinks.Linkedinurl}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      sociallinks: {
                        ...formData.sociallinks,
                        Linkedinurl: e.target.value,
                      },
                    })
                  }
                />
              </div>
              <div className="mb-3 col-md-4 ">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="Websiteurl"
                  placeholder="Website URL"
                  value={formData.sociallinks.Websiteurl}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      sociallinks: {
                        ...formData.sociallinks,
                        Websiteurl: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>
            </div>
            <button type="submit" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray h-10 mt-8 hover:bg-opacity-90 ms-2">
              {id ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </div>
      </div>
     </div>
      <ToastContainer />
    </div>
  );
};

export default AddUserForm;
