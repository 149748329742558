// src/pages/group.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import { usePermissions } from '../hooks/PermissionsContext';

import axios from "axios";

import messages from '../messages.json';
import { toast, ToastContainer } from "react-toastify";
import GroupInformation from "../forms/GroupInformation";

import DeleteConfirmationModal from "../forms/DeleteConformation";
import CustomTable from "../components/commonComponent/CustomTable";

const Group = () => {
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [groupsPerPage] = useState(10);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectedGroupCityArea, setSelectedGroupCityArea] = useState([]);
  const [selectedGroupType, setSelectedGroupType] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [totalPages, setTotalPages] = useState(1); // Total pages from backend
  const [totalCount, settotalCount] = useState(); // Total pages from backend
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { hasPermission } = usePermissions();

  const showView = hasPermission('Groups', 'View');
  const showAdd = hasPermission('Groups', 'Add');
  const showEdit = hasPermission('Groups', 'Update');
  const showDelete = hasPermission('Groups', 'Delete');
 // Determine which actions to show
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllGroups`,{
        params:{
          page:currentPage,
          limit: totalCount,
          name: searchInput,
        },
      });
        setGroups(response.data.data);
        console.log(response?.data?.totalGroups,'cooooooooooooooo')

        settotalCount(response?.data?.totalGroups);
        setTotalPages(response?.data.data?.totalPages);

        setFilteredGroups(response?.data?.data);
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };

    fetchData();
  }, [currentPage, groupsPerPage, searchInput,totalCount]); 

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllUsersList`);
        setUsers(response?.data?.data);  // Assuming the users are in response.data.data
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    fetchUserList();
  }, [totalCount]);

  const getAdminNameById = (userId) => {
    // If userId is an object with the `fullname` property
    return userId && userId.fullname ? userId.fullname : 'unknown user';
  };
  



  useEffect(() => {
    filterGroups();
  }, [searchInput, groups, selectedGroupCityArea, selectedGroupType, selectedStatuses]);

  const filterGroups = () => {
    let filtered = groups;
    if (searchInput !== ''){
      filtered = filtered?.filter(group => 
        group.name && group.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    if (selectedGroupCityArea.length > 0){
      filtered = filtered?.filter(group => 
        selectedGroupCityArea.includes(group?.groupCityArea)
      );
    }

    if(selectedGroupType.length > 0){
      filtered = filtered?.filter(group => 
        selectedGroupType.includes(group?.groupType)
      );
    }
    if (selectedStatuses.length > 0){
      filtered = filtered?.filter(group => 
        selectedStatuses.includes(group?.isActive ? 'Active' : 'Inactive')
      );
    }

    setFilteredGroups(filtered);
  }

  const handleUpdate = (id) => {
    navigate(`/kitty-group/update/${id}`);
  };

  const handleShowDeleteModal = (groupId) => {
    setGroupToDelete(groupId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setGroupToDelete(null);
  };

  const handleDelete = async () => {
    if (groupToDelete)
      try {
        await axios.delete(
          `${apiUrl}/deleteGroup/${groupToDelete}`
        );
        setGroups(groups.filter((group) => group._id !== groupToDelete));
        toast.success(messages.success.delete, { autoClose: 2000 });
      } catch (error) {
        console.error(messages?.error?.errorDeleting, error);
        toast.error(messages?.error?.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setGroupToDelete(null);
      }
  };

  const handleStatusToggle = async (groupId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      const response = await axios.patch(
        `${apiUrl}/updateGroupStatus/${groupId}`,
        {
          isActive: updatedStatus,
        }
      );

      setGroups(
        groups.map((group) =>
          group._id === groupId
            ? { ...group, isActive: updatedStatus }
            : group
        )
      );
      toast.success(
        ` Group status updated to ${updatedStatus ? "Active" : "Inactive"}`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages?.error?.errorUpdating, error);
      toast.error(messages?.error?.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const indexOfLastGroup = currentPage * groupsPerPage;
  const indexOfFirstGroup = indexOfLastGroup - groupsPerPage;
  const currentGroups = filteredGroups.slice(indexOfFirstGroup, indexOfLastGroup);
  // const totalPages = Math.ceil(filteredGroups.length / groupsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleView = async (groupId) => {
    try {
      const response = await axios.get(`${apiUrl}/getGroupById/${groupId}`);
      console.log("API Response:", response.data.data);
      setSelectedGroup(response?.data?.data);
      setShowModal(true);
    } catch (error) {
      console.error(messages?.error?.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedGroup(null);
  };

  const uniqueGroupCityArea = [...new Set(groups.map(group => group.groupCityArea))];
  const handleGroupCityAreaChange = (name) => {
    setSelectedGroupCityArea(prevState => 
      prevState.includes(name) ? 
      prevState.filter(item => item !== name) :
      [...prevState, name]
    );
  };

  const handleStatusChange = (status) => {
    setSelectedStatuses(prevState => 
      prevState.includes(status) ? 
      prevState.filter(item => item !== status) : 
      [...prevState, status]
    );
  };

  const columns = [
    { header: 'S.No', accessor: 'id' },
    { header: 'Group Name', accessor: 'name' },
    // { header: 'Description', accessor: 'description' },
    // { 
    //   header: 'Group Users', 
    //   accessor: 'userIds',
    //   Cell: ({ value }) => (
    //     <>
    //       {value.map((user, index) => (
    //         <div key={index}>
    //           <p>Name: {user?.userId?.fullname || 'Unknown'}</p>
    //         </div>
    //       ))}
    //     </>
    //   ) 
    // },
    // { header: 'Rules And Regulation', accessor: 'rulesAndRegulation' },
    // { header: 'Location', accessor: 'location' },
    // { header: 'Profession', accessor: 'profession' },
  ];

  const uniqueGroupType = [...new Set(groups.map(group => group.groupType))];

  const handleGroupTypeChange = (type) => {
    setSelectedGroupType(prevState => 
      prevState.includes(type) ? 
      prevState.filter(item => item !== type) : 
      [...prevState, type]
    );
  };



  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">Group Information</h2>
          </div>
          <div className="col-auto">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search groups..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <span className="input-group-text"><FaSearch /></span>
          </div>
          </div>
          <div className="col-auto">
      
        <div className="dropdown d-inline-block">
          <button
            className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1"
            type="button"
            onClick={() => setShowStatusDropdown(!showStatusDropdown)}
          >
            Status
            <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
          </button>
          {showStatusDropdown && (
           <ul className="dropdown-menu show px-[10px]">
           <li>
             <div className="form-check">
               <input
                 className="form-check-input"
                 type="checkbox"
                 value="Active"
                 id="status-active"
                 checked={selectedStatuses?.includes("Active")}
                 onChange={() => handleStatusChange("Active")}
               />
               <label className="form-check-label" htmlFor="status-active">
                 Active
               </label>
             </div>
           </li>
           <li>
             <div className="form-check">
               <input
                 className="form-check-input"
                 type="checkbox"
                 value="Inactive"
                 id="status-inactive"
                 checked={selectedStatuses.includes("Inactive")}
                 onChange={() => handleStatusChange("Inactive")}
               />
               <label
                 className="form-check-label"
                 htmlFor="status-inactive"
               >
                 Inactive
               </label>
             </div>
           </li>
         </ul>
          )}
        </div>
      </div>
        
      <div className="col-auto">
        {showAdd && (
          <Link
            to="/kitty-groups/add"
            className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90"
          >
            Add Group
          </Link>
        )}
      </div>
      </div>
      



      <CustomTable
        tableData={filteredGroups}
        columns={columns}
        handleStatusToggle={handleStatusToggle}
        showStatus={true}
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView,
          showEdit,
          showDelete,
        }}
      />
     
      
        <GroupInformation 
        group={selectedGroup} 
        show={showModal}
        handleClose={handleCloseModal} />
      
      
        <DeleteConfirmationModal
      show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={handleDelete}

        />
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default Group;
