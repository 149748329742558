import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from 'axios';

const FeedbacksInformation = ({ show, handleClose, Feedback }) => {
  const [emails, setEmails] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllUsersList`);
        setEmails(response.data.data);
      } catch (error) {
        console.log("Error fetching users data", error);
      }
    };

    fetchUserList();
  }, [apiUrl]);

  // Function to get email by userId
  const getUserEmailById = (userId) => {
    const user = emails.find((email) => email._id === userId);
    return user ? user.email : 'Unknown email';
  };

  return (
    <Modal show={show} onHide={handleClose} className="flex items-center justify-center min-h-screen">
    <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-4xl mx-auto">
      <Modal.Header className="bg-[#0e121a] text-white border-b border-gray-200">
        <Modal.Title className="text-xl font-semibold">Feedback Information</Modal.Title>
        <Button variant="close" onClick={handleClose} className="text-white hover:text-gray-400">
          &times;
        </Button>
      </Modal.Header>
  
      <Modal.Body className="p-6">
        {Feedback ? (
          <div className="space-y-4">
            <p className="text-gray-700">
              <strong>Description:</strong> <span className="text-gray-500">{Feedback.description}</span>
            </p>
            <p className="text-gray-700">
              <strong>Created At:</strong> <span className="text-gray-500">{new Date(Feedback.createdAt).toISOString().split('T')[0]}</span>
            </p>
  
            <p className="text-gray-700">
              <strong>Email:</strong> 
              {Feedback.userId ? (
                <span className="text-gray-500">{getUserEmailById(Feedback.userId._id)}</span> // Fetch the email by userId
              ) : (
                <span className="text-red-500">No email available</span>
              )}
            </p>
  
            <p className="text-gray-700">
              <strong>Status:</strong> 
              <span className={`ml-1 ${Feedback.isActive ? 'text-green-500' : 'text-red-500'}`}>
                {Feedback.isActive ? "Active" : "Inactive"}
              </span>
            </p>
          </div>
        ) : (
          <p className="text-gray-600">Loading...</p>
        )}
      </Modal.Body>
  
      <Modal.Footer className="bg-gray-100 p-3">
        <Button variant="secondary" onClick={handleClose} className="bg-gray-600 text-white hover:bg-gray-700 transition duration-200">
          Close
        </Button>
      </Modal.Footer>
    </div>
  </Modal>
  
  );
};

export default FeedbacksInformation;
