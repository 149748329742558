import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import messages from "../messages.json";
import DeleteConfirmationModal from "../forms/DeleteConformation";
import TermsandServicesInformation from "../forms/termsandServiceInformation";
import CustomTable from "../components/commonComponent/CustomTable";
import { usePermissions } from "../hooks/PermissionsContext";

const TermsAndServices = () => {
  const [TermsAndServices, setTermsAndServices] = useState([]);
  const [filteredTermsAndServices, setFilteredTermsAndServices] = useState([]);
  const [selectedTermsAndServices, setSelectedTermsAndServices] =
    useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [TermsAndServiceToDelete, setTermsAndServiceToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { hasPermission } = usePermissions();

  const showView = hasPermission('Terms & Service', 'View');
  const showAdd = hasPermission('Terms & Service', 'Add');
  const showEdit = hasPermission('Terms & Service', 'Update');
  const showDelete = hasPermission('Terms & Service', 'Delete');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getTermsAndServices`);
        const fetchedTermsAndServices = response.data;

        setTermsAndServices(fetchedTermsAndServices);
        setFilteredTermsAndServices(fetchedTermsAndServices); // Ensure this is an array
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filterTermsAndServices();
  }, [searchInput, TermsAndServices, selectedStatuses]);

  const filterTermsAndServices = () => {
    let filtered = TermsAndServices;

    if (searchInput !== "") {
      filtered = filtered.filter(
        (TermsAndService) =>
          TermsAndService.name &&
          TermsAndService.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    if (selectedStatuses.length > 0) {
      filtered = filtered.filter((TermsAndService) =>
        selectedStatuses.includes(
          TermsAndService.isActive ? "Active" : "Inactive"
        )
      );
    }

    setFilteredTermsAndServices(filtered);
  };

  const handleShowDeleteModal = (TermsAndServiceId) => {
    setTermsAndServiceToDelete(TermsAndServiceId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setTermsAndServiceToDelete(null);
  };

  const handleDelete = async () => {
    if (TermsAndServiceToDelete) {
      try {
        await axios.delete(
          `${apiUrl}/deleteTermsAndServices/${TermsAndServiceToDelete}`
        );
        setTermsAndServices(
          TermsAndServices.filter(
            (TermsAndService) => TermsAndService._id !== TermsAndServiceToDelete
          )
        );
        toast.success(messages.success.delete, {
          autoClose: 2000,
        });
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setTermsAndServiceToDelete(null);
      }
    }
  };

  const handleUpdate = (TermsAndServiceId) => {
    navigate(`/kitty-TermsAndService/update/${TermsAndServiceId}`);
  };

  const handleStatusToggle = async (TermsAndServiceId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await axios.put(`${apiUrl}/updateTermsAndServices/${TermsAndServiceId}`, {
        isActive: updatedStatus,
      });

      setTermsAndServices(
        TermsAndServices.map((TermsAndService) =>
          TermsAndService._id === TermsAndServiceId
            ? { ...TermsAndService, isActive: updatedStatus }
            : TermsAndService
        )
      );
      toast.success(
        `TermsAndService status updated to ${
          updatedStatus ? "Active" : "Inactive"
        }`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const handleView = async (TermsAndServiceId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/getTermsAndServices/${TermsAndServiceId}`
      );
      setSelectedTermsAndServices(response.data);
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTermsAndServices(null);
  };

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevState) =>
      prevState.includes(status)
        ? prevState.filter((item) => item !== status)
        : [...prevState, status]
    );
  };

  const columns = [
    { header: "S.No", accessor: "id" },
    {
      header: "Terms and Services",
      render: (item) => {
        const terms = item?.termsAndServices;
        return (
          <div>
            {terms.length > 200 ? `${terms.substring(0, 100)}...` : terms}
          </div>
        );
      },
    },
  ];

  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">Terms&Service Information</h2>
        </div>
        <div className="col-auto">
          <div className="dropdown d-inline-block me-2">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1"
              type="button"
              onClick={() => setShowStatusDropdown(!showStatusDropdown)}
            >
              Status
              <svg
                class="ml-2 -mr-1 h-3 w-3 mt-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {showStatusDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Active"
                      id="status-active"
                      checked={selectedStatuses.includes("Active")}
                      onChange={() => handleStatusChange("Active")}
                    />
                    <label className="form-check-label" htmlFor="status-active">
                      Active
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Inactive"
                      id="status-inactive"
                      checked={selectedStatuses.includes("Inactive")}
                      onChange={() => handleStatusChange("Inactive")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="status-inactive"
                    >
                      Inactive
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        {showAdd && (

          <div className="col-auto">
          <Link
            to="/kitty-TermsAndService/add"
            className="flex  justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90"
          >
            Add TermsAndService
          </Link>
        </div>
          )}
      </div>

      <CustomTable
        tableData={filteredTermsAndServices}
        columns={columns}
        handleStatusToggle={handleStatusToggle}
        showStatus={true}
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView,
          showEdit,
          showDelete,
        }}
      />

      <TermsandServicesInformation
        TermsAndService={selectedTermsAndServices}
        show={showModal}
        handleClose={handleCloseModal}
      />
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default TermsAndServices;
