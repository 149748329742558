import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const WalletTransactionInformation = ({ show, handleClose, WalletTransaction }) => {
  console.log(WalletTransaction,"testttttttt");
  

  return (
    <Modal show={show} onHide={handleClose} className="flex items-center justify-center min-h-screen">
    <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-4xl mx-auto">
      <Modal.Header className="bg-[#0e121a] text-white border-b border-gray-200">
        <Modal.Title className="text-xl font-semibold">Wallet Transaction Information</Modal.Title>
        <Button variant="close" onClick={handleClose} className="text-white hover:text-gray-400">
          &times;
        </Button>
      </Modal.Header>
  
      <Modal.Body className="p-6">
        {WalletTransaction ? (
          <div className="space-y-4">
            <p className="text-gray-700 font-semibold">
              <strong>Expenses of Groups:</strong>
            </p>
            <ul className="list-disc pl-5">
              {WalletTransaction[0].kittyTransactions && WalletTransaction[0].kittyTransactions.length > 0 ? (
                WalletTransaction[0].kittyTransactions.map((transaction, index) => (
                  <li key={index} className="text-gray-600 mb-2">
                    {transaction.kittyGroupId && transaction.kittyGroupId.name ? (
                      <>
                        <span className="font-medium">{transaction.kittyGroupId.name}</span> {' => '}
                      </>
                    ) : (
                      "Group name not available"
                    )}
                    <span className="text-gray-500"> Spend amount: {transaction?.spentAmount}</span>
                  </li>
                ))
              ) : (
                <li className="text-gray-500">No transactions available</li>
              )}
            </ul>
  
            <p className="text-gray-700 font-semibold">
              <strong>Total Amount:</strong> {' '} {WalletTransaction[0].totalAmount}
            </p>
  
            <p className="text-gray-700">
              <strong>Status:</strong> 
              <span className={`ml-1 ${WalletTransaction.isActive ? 'text-green-500' : 'text-red-500'}`}>
                {WalletTransaction.isActive ? "Active" : "Inactive"}
              </span>
            </p>
          </div>
        ) : (
          <p className="text-gray-600">Loading...</p>
        )}
      </Modal.Body>
  
      <Modal.Footer className="bg-gray-100 p-3">
        <Button variant="secondary" onClick={handleClose} className="bg-gray-600 text-white hover:bg-gray-700 transition duration-200">
          Close
        </Button>
      </Modal.Footer>
    </div>
  </Modal>
  )  
};

export default WalletTransactionInformation;
