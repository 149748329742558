import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import messages from "../messages.json";

const AddAddressForm = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  const { id } = useParams(); 
  const [formData, setFormData] = useState({
    userId: "",
    title: "",
    address: "",
    location: "",
   
  });
  const [Addresses, setAddresses] = useState([]); // State to hold the list of Addresses

  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  // Fetch Addresses from API on component mount
  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    try {
      const response = await axios.get(`${apiUrl}/getAllAddresses`);
      setAddresses(response?.data?.data); // Assuming the API response contains the Addresses array in response.data.data
    } catch (error) {
      console.error("Failed to fetch Addresses:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAddressData(id);
    }
  }, [id]);

  const fetchAddressData = async (AddressId) => {
    try {
      const response = await axios.get(`${apiUrl}/getAddressById/${AddressId}`);
      const AddressData = response?.data;
      setFormData({
        title: AddressData?.title,
        address: AddressData?.address,
        location: AddressData?.location, // Populate AddressId if it's in the response
      });
    } catch (error) {
      console.error("Failed to fetch Address data:", error);
    }
  };




  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user._id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: user._id,
      }));
    } else {
      console.error("User ID not found in local storage");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const dataToSend = {
        userId: formData?.userId,
        title: formData?.title,
        location: formData?.location,
        address: formData?.address,
        
      };

      if (id) {
        await axios.put(`${apiUrl}/updateAddressById/${id}`, dataToSend);
        toast.success("Address Updated Successfully", {
          autoClose: 2000,
        });
      } else {
        await axios.post(`${apiUrl}/createAddress`, dataToSend);
        toast.success(messages.success.dataAdded, { autoClose: 2000 });
      }

      setFormData({
        // userId: "",
        title: "",
        address: "",
        location: "",
      });

      setTimeout(() => {
        navigate("/kitty-Address");
      }, 1000);
    } catch (error) {
      console.error("Failed to submit Address data:", error);
    }
  };

  const handleChange = async(e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));

    if (id === "location" && value.length > 2) {
        const locationApiUrl = `https://api.olamaps.io/places/v1/autocomplete?input=${value}&api_key=TdoidCwf5FxL1rVCqS7KnbWT8Gq561obQaMWzBoR`;
  
        try {
          const response = await axios.get(locationApiUrl);
          setSuggestions(response?.data?.predictions || []);
        } catch (error) {
          console.error("Error fetching location suggestions:", error);
        }
      } else if (id === "location") {
        setSuggestions([]);
      }
  };
  const handleSelect = (suggestion) => {
    const { description} = suggestion;
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: description,
     
    }));
    setSuggestions([]);
  };

  const handleAddressChange = (e) => {
    const selectedAddressId = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      AddressId: selectedAddressId,
    }));
  };

  const handleClose = () => {
    navigate("/kitty-Address");
  };

  return (
    <div className="container mt-4">
 <div className="row">
  <div className="col-md-8 mx-auto">
  <div className="card p-0">
        <div className=" card-body w-full p-0 pb-3">
          <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
            <h2 className="card-title">{id ? "Update Address" : "Add Address"}</h2>
            <FaTimes className="text-danger cursor-pointer" onClick={handleClose} />
          </div>
          <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
            <div className="mb-3 col-md-12 p-2">
              <label htmlFor="title" className="mb-2 block text-sm font-medium text-black dark:text-white">Title</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="title"
                placeholder="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3 col-md-12 p-2">
              <label htmlFor="address" className="mb-2 block text-sm font-medium text-black dark:text-white">Address</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="address"
                placeholder="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>

            {/* Address Select Dropdown */}
            <div className="mb-3 col-md-12 p-2">
              <label htmlFor="location" className="mb-2 block text-sm font-medium text-black dark:text-white">Location</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="location"
                placeholder="Enter location"
                value={formData.location}
                onChange={handleChange}
                
              />
              {suggestions.length > 0 && (
                <ul className="suggestions-list">
                  {suggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => handleSelect(suggestion)}>
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>

          
            
            <button type="submit" className="flex ms-2 justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
              {id ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </div>
  </div>
 </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default AddAddressForm;
