import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from 'axios';

const AddressInformation = ({ show, handleClose, Address }) => {
  const [emails, setEmails] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllUsersList`);
        setEmails(response.data.data);
      } catch (error) {
        console.log("Error fetching users data", error);
      }
    };
    
    fetchUserList();
  }, [apiUrl]);

  const getUserEmailById = (userId) => {
    if (!emails.length) return 'Loading emails...'; // Check if emails have been fetched

    const user = emails.find((email) => email._id === userId);
    return user ? user.email : 'Unknown email';
  };

  return (
    <Modal show={show} onHide={handleClose} className="flex items-center justify-center min-h-screen">
    <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-4xl mx-auto">
      <Modal.Header className="bg-[#0e121a] text-white border-b border-gray-200">
        <Modal.Title className="text-xl font-semibold">Address Information</Modal.Title>
        <Button variant="close" onClick={handleClose} className="text-white hover:text-gray-400">
          &times;
        </Button>
      </Modal.Header>
  
      <Modal.Body className="p-3">
        {Address ? (
          <div className="space-y-4">
            <p className="text-gray-700">
              <strong>Title:</strong> <span className="text-gray-500">{Address.title}</span>
            </p>
  
            <p className="text-gray-700">
              <strong>Address:</strong> <span className="text-gray-500">{Address.address}</span>
            </p>
  
            <p className="text-gray-700">
              <strong>Location:</strong> <span className="text-gray-500">{Address.location}</span>
            </p>
  
            <p className="text-gray-700">
              <strong>Email:</strong> 
              {Address.userId ? (
                <span className="text-gray-500">{getUserEmailById(Address.userId._id || Address.userId)}</span>
              ) : (
                <span className="text-gray-500">No email available</span>
              )}
            </p>
  
            <p className="text-gray-700">
              <strong>Status:</strong> 
              <span className={`${Address.isActive ? 'text-green-500' : 'text-red-500'}`}>
                {Address.isActive ? "Active" : "Inactive"}
              </span>
            </p>
          </div>
        ) : (
          <p className="text-gray-600">Loading...</p>
        )}
      </Modal.Body>
  
      <Modal.Footer className="bg-gray-100 p-3">
        <Button variant="secondary" onClick={handleClose} className="bg-gray-600 text-white hover:bg-gray-700 transition duration-200">
          Close
        </Button>
      </Modal.Footer>
    </div>
  </Modal>
  
  );
};

export default AddressInformation;
