import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import { RiDeleteBin4Fill } from "react-icons/ri";
import { IoIosEye } from "react-icons/io";
import { toast ,ToastContainer} from "react-toastify";
import messages from "../messages.json";

import DeleteConfirmationModal from "../forms/DeleteConformation";
import WishListInformation from "../forms/wishlistInformation";
import CustomTable from "../components/commonComponent/CustomTable";


const WishList = () => {
  const [Wishlists, setWishlists] = useState([]);
  const [filteredWishlists, setFilteredWishlists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [WishlistsPerPage] = useState(10);
  const [selectedWishlists, setSelectedWishlists] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [WishlistToDelete, setWishlistToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllWishlist`);
        const fetchedWishlists = response.data?.data;

        setWishlists(fetchedWishlists);
        setFilteredWishlists(fetchedWishlists); // Ensure this is an array
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filterWishlists();
  }, [searchInput, Wishlists,  selectedStatuses]);

  const filterWishlists = () => {
    let filtered = Wishlists;

    if (searchInput !== "") {
      filtered = filtered.filter(
        (Wishlist) =>
          Wishlist.name &&
          Wishlist.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    if (selectedStatuses.length > 0) {
      filtered = filtered.filter((Wishlist) =>
        selectedStatuses.includes(Wishlist.isActive ? "Active" : "Inactive")
      );
    }

    setFilteredWishlists(filtered);
  };

  const handleShowDeleteModal = (WishlistId) => {
    setWishlistToDelete(WishlistId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setWishlistToDelete(null);
  };

  const handleDelete = async () => {
    if (WishlistToDelete) {
      try {
        await axios.delete(`${apiUrl}/deleteWishlist/${WishlistToDelete}`);
        setWishlists(Wishlists.filter((Wishlist) => Wishlist._id !== WishlistToDelete));
        toast.success(messages.success.delete, {
          autoClose: 2000,
        });
        setTimeout(() => {
          // navigate('/kitty-users');
        }, 2500);
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setWishlistToDelete(null);
      }
    }
  };

  const handleUpdate = (WishlistId) => {
    navigate(`/kitty-Wishlist/update/${WishlistId}`);
  };

  const handleStatusToggle = async (WishlistId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await axios.put(`${apiUrl}/updateWishlistById/${WishlistId}`, {
        isActive: updatedStatus,
      });

      setWishlists(
        Wishlists.map((Wishlist) =>
          Wishlist._id === WishlistId ? { ...Wishlist, isActive: updatedStatus } : Wishlist
        )
      );
      toast.success(
        `Wishlist status updated to ${updatedStatus ? "Active" : "Inactive"}`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const handleView = async (WishlistId) => {
    try {
      const response = await axios.get(`${apiUrl}/getWishlistById/${WishlistId}`);
      setSelectedWishlists(response.data.data);
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedWishlists(null);
  };

  const indexOfLastWishlist = currentPage * WishlistsPerPage;
  const indexOfFirstWishlist = indexOfLastWishlist - WishlistsPerPage;
  const currentWishlists = filteredWishlists?.slice(indexOfFirstWishlist, indexOfLastWishlist);
  const totalPages = Math.ceil(filteredWishlists.length / WishlistsPerPage);

 


 

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevState) =>
      prevState.includes(status)
        ? prevState.filter((item) => item !== status)
        : [...prevState, status]
    );
  };

  const columns = [
    { header: 'S.No', accessor: 'id' },
    { header: 'Name', accessor: 'name' },
    { header: 'Description', accessor: 'description' },
 
  ];  


  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">Wishlist Information</h2>
        </div>
        <div className="col-auto">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <span className="input-group-text">
              <FaSearch />
            </span>
          </div>
        </div>
        <div className="col-auto">
          

          <div className="dropdown d-inline-block me-2">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 "
              type="button"
              onClick={() => setShowStatusDropdown(!showStatusDropdown)}
            >
              Status
              <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showStatusDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Active"
                      id="status-active"
                      checked={selectedStatuses.includes("Active")}
                      onChange={() => handleStatusChange("Active")}
                    />
                    <label className="form-check-label" htmlFor="status-active">
                      Active
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Inactive"
                      id="status-inactive"
                      checked={selectedStatuses.includes("Inactive")}
                      onChange={() => handleStatusChange("Inactive")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="status-inactive"
                    >
                      Inactive
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="col-auto">
          <Link to="/kitty-Wishlist/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add Wishlist
          </Link>
        </div>
      </div>
    
   
      <CustomTable
        tableData={filteredWishlists}
        columns={columns}
        handleStatusToggle={handleStatusToggle}
        showStatus={true}
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView: true,
          showEdit: true,
          showDelete: true,
        }}
      />
      <WishListInformation
      Wishlist={selectedWishlists}
      show={showModal}
      handleClose={handleCloseModal}
      />
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default WishList;
