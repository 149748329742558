import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContext';

const PublicRoutes = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);


  if (loading) {

    return <div>Loading...</div>
    
  }
  return !isAuthenticated ? children : <Navigate to="/home" />;
};

export default PublicRoutes;
