import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";

const AddRoleForm = () => {
  const [users, setUsers] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate(); // Navigate function from react-router-dom
  const [formData, setFormData] = useState({
    roles: '',
    permissions: [],
    userId: '',
    password: '', // New password field
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllUsersList`);
        response.data.data = response?.data?.data.filter((item) => item.email);
        setUsers(response?.data?.data); // Assuming the users are in response.data.data
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserList();
  }, []);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllPages`);
        setPages(response.data);

        // If editing a role, initialize permissions properly
        if (id) {
          const roleResponse = await axios.get(`${apiUrl}/getRolesById/${id}`);
          const roleData = roleResponse.data;

          // Setting formData with existing role data
          setFormData({
            roles: roleData.roles || '',
            permissions: response.data.map(page => ({
              page: page._id, // Use ObjectId reference
              actions: roleData.permissions.find(p => p.page._id === page._id)?.actions || [],
            })),
            userId: roleData.userId || '',
            password: '', // Reset password on edit
          });
        } else {
          // Initialize permissions if adding a new role
          const initialPermissions = response.data.map(page => ({
            page: page._id, // Use ObjectId reference
            actions: [],
          }));
          setFormData(prev => ({ ...prev, permissions: initialPermissions }));
        }
      } catch (error) {
        console.error('Error fetching pages or role:', error);
      }
    };

    fetchPages();
  }, [id]);

  const handleActionCheckboxChange = (index, action, isChecked) => {
    const updatedPermissions = [...formData.permissions];
    if (isChecked) {
      updatedPermissions[index].actions.push(action);
    } else {
      updatedPermissions[index].actions = updatedPermissions[index].actions.filter(a => a !== action);
    }
    setFormData(prev => ({ ...prev, permissions: updatedPermissions }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiEndpoint = id ? `${apiUrl}/updateRoles/${id}` : `${apiUrl}/addRoles`;
    const method = id ? 'PUT' : 'POST';

    try {
      const response = await axios({
        method,
        url: apiEndpoint,
        data: formData,
      });

      navigate("/kitty-role-management");
      toast.success(id ? 'Role updated successfully!' : 'Role saved successfully!', { autoClose: 2000 });

      handleClose(); // Close the modal or redirect as necessary
    } catch (error) {
      toast.error(error.response?.data?.message || 'An error occurred while saving the role.');
      console.error('Error saving role:', error);
    }
  };
  const handleClose = () =>{
    navigate('/kitty-role-management');
  }

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="card p-0">
            <div className="card-body w-full p-0 pb-3">
              <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
                <h2 className="card-title mb-0">{id ? "Update Role" : "Add Role"}</h2>
                <FaTimes className="text-danger cursor-pointer" onClick={handleClose} />
              </div>
              <form onSubmit={handleSubmit} className="p-3">
                
                {/* Users Dropdown */}
                <div className="mb-3 col-md-4 p-2">
                  <label htmlFor="userId" className="mb-2 block text-sm font-medium text-black dark:text-white">
                    Users
                  </label>
                  <select
                    className="form-control"
                    id="userId"
                    name="userId"
                    value={formData?.userId}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select User</option>
                    {users.map((user) => (
                      <option key={user?._id} value={user?._id}>
                        {user?.fullname} ({user?.email})
                      </option>
                    ))}
                  </select>
                </div>

                {/* Password Input */}
                <div className="mb-3 col-md-4 p-2">
                  <label htmlFor="password" className="mb-2 block text-sm font-medium text-black dark:text-white">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="form-control"
                    required={true} // Password required for new users
                  />
                </div>

                {/* Role Dropdown (Single Select) */}
                <div className="mb-3 col-12 p-2">
                  <label htmlFor="roles" className="mb-2 block text-sm font-medium text-black dark:text-white">
                    Select Role
                  </label>
                  <select
                    id="roles"
                    name="roles"
                    value={formData.roles}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                    <option value="vendor">Vendor</option>
                  </select>
                </div>

                {/* Permissions Table */}
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Page</th>
                        <th>Add</th>
                        <th>View</th>
                        <th>Update</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pages.map((page, index) => (
                        <tr key={index}>
                          <td>{page.pageName}</td>
                          <td>
                            <input
                              type="checkbox"
                              checked={formData.permissions[index]?.actions.includes("Add")}
                              onChange={(e) => handleActionCheckboxChange(index, "Add", e.target.checked)}
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              checked={formData.permissions[index]?.actions.includes("View")}
                              onChange={(e) => handleActionCheckboxChange(index, "View", e.target.checked)}
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              checked={formData.permissions[index]?.actions.includes("Update")}
                              onChange={(e) => handleActionCheckboxChange(index, "Update", e.target.checked)}
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              checked={formData.permissions[index]?.actions.includes("Delete")}
                              onChange={(e) => handleActionCheckboxChange(index, "Delete", e.target.checked)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary">
                    {id ? "Update Role" : "Add Role"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddRoleForm;
