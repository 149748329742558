import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import messages from "../messages.json";

import { FaTimes } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";

const AddVenueForm = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    long: "",
    lat: "",
    contactNo: "",
    pricing: "",
    cityId: "",
    venueCatId: "",
    venueTypeId: "",
    userId: "",
    image: null,
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [category, setCategory] = useState(null);
  const [cities, setCities] = useState([]);
  const [venueType, setVenueType] = useState([]);

  const [imageUrl, setImageUrl] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (id) {
      fetchVenueData(id);
    }
  }, [id]);
  useEffect(() => {
    const fetchVenueCategory = async () => {
      try {
        // Fetch venue categories
        const categoryResponse = await axios.get(
          `${apiUrl}/getAllVenueCategories`
        );

        // Set category state with the fetched data
        setCategory(categoryResponse.data);
        console.log(category, categoryResponse?.data, "sadasdas");

        console.log("Fetched categories:", categoryResponse.data.data);
      } catch (error) {
        // Handle errors
        console.error(messages.error.fetchData, error);
      }
    };

    // Call the function to fetch data
    fetchVenueCategory();
  }, []);
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const cityResponse = await axios.get(`${apiUrl}/getAllCities`);
        // Check if response contains the cities array
        console.log("hhhhhhh", cityResponse);
        if (cityResponse.data && cityResponse.data.cities) {
          setCities(cityResponse.data.cities);
        } else {
          console.error(
            "Invalid response format for cities:",
            cityResponse.data
          );
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    const fetchVenueType = async () => {
      try {
        const venueTypeResponse = await axios.get(`${apiUrl}/getAllVenueTypes`);
        // Check if response contains the cities array
        console.log("ooooooo", venueTypeResponse);
        if (venueTypeResponse.data && venueTypeResponse.data.data) {
          setVenueType(venueTypeResponse.data.data);
        } else {
          console.error(
            "Invalid response format for cities:",
            venueTypeResponse.data
          );
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchVenueType();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user._id) {
      console.log("User ID from local storage:", user._id);
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: user._id, // Set user ID from local storage
      }));
    } else {
      console.error("User ID not found in local storage");
    }
  }, []);

  const fetchVenueData = async (venueId) => {
    try {
      const response = await axios.get(`${apiUrl}/getVenueById/${venueId}`);
      console.log(response, "hhfdhfidfhsdifhsfhklhfkjdshfksfhlka");
      const venueData = response.data;
      setFormData({
        name: venueData.name,
        userId: venueData.userId,
        location: venueData.location,
        long: venueData.long,
        lat: venueData.lat,
        contactNo: venueData.contactNo || "",
        venueCatId: venueData.venueCatId || "",
        venueTypeId: venueData.venueTypeId || "",
        cityId: venueData.cityId || "",
        pricing: venueData.pricing || "",
        image: venueData.image,
      });
      setImagePreview(venueData.image);
      setImageUrl(venueData.image);
    } catch (error) {
      console.error("Failed to fetch venue data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formattedData = {
        ...formData,
        image: imageUrl,
        // image: uploadedImageUrl,
        image: imagePreview,
      };
      console.log("Payload to send:", formattedData);

      if (id) {
        await axios.put(`${apiUrl}/updateVenue/${id}`, formattedData);
        toast.success("Venue Updated Successfully", {
          autoClose: 2000,
        });
      } else {
        await axios.post(`${apiUrl}/addVenue`, formattedData);
        toast.success("Venue Added Successfully", {
          autoClose: 2000,
        });
      }

      setImagePreview(null);
      setImageUrl(null);

      setFormData({
        name: "",
        location: "",
        long: "",
        lat: "",
        contactNo: "",
        pricing: "",
        cityId: "",
        venueCatId: "",
        venueTypeId: "",
        userId: "",
        image: null,
      });

      setTimeout(() => {
        navigate("/kitty-venue");
      }, 1000);
    } catch (error) {
      console.error("Failed to submit venue data:", error);
      toast.error(error?.response?.data?.error, {
        autoClose: 2000,
      });
    }
  };

  const handleChange = async (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    let apikey = "AIzaSyCb3G660acSLL7W4xS4wkgDBHUd8popUz4";
    if (id === "location" && value.length > 2) {
      const locationApiUrl = `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${value}&key=${apikey}`;

      try {
        const response = await axios.get(locationApiUrl);
        setSuggestions(response.data || []);
      } catch (error) {
        console.error("Error fetching location suggestions:", error);
      }
    } else if (id === "location") {
      setSuggestions([]);
    }
    if (id === "name") {
      const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
      setFormData((prevData) => ({
        ...prevData,
        [id]: capitalizedValue,
      }));
    } else {
      // For other fields, just update the form data
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const handleSelect = async (suggestion) => {
    const { formatted_address, geometry } = suggestion;
    console.log(formatted_address, "locationnnnnn");
    console.log(suggestion, "suggggggggg");
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: formatted_address,
      lat: geometry.location.lat,
      long: geometry.location.lng,
    }));
    setSuggestions([]);

    try {
      console.log(suggestion?.place_id);

      const response = await fetch(
        `https://maps.googleapis.com/maps/api/place/details/json?place_id=${suggestion?.place_id}&fields=name,photos&key=AIzaSyCb3G660acSLL7W4xS4wkgDBHUd8popUz4`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch place details");
      }

      const data = await response.json();
      console.log(data?.result, "ddddddddddddd");

      // Process the place details as needed
      const photoRes = await fetch(
        `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${data.result.photos[0].photo_reference}&key=AIzaSyCb3G660acSLL7W4xS4wkgDBHUd8popUz4`
      );
      console.log(photoRes, "testing");
      if (!photoRes.ok) {
        throw new Error("Failed to fetch photos details");
      }
      console.log(photoRes, "jjjjjjjjj");
      setImagePreview(photoRes?.url);

      // You can set additional data to formData if needed
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const file = files[0];

    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
    setImagePreview(URL.createObjectURL(file)); // Update image preview
    console.log("File selected:", file); // Log file details
  };

  const handleClose = () => {
    navigate("/kitty-venue");
  };

  // Handle image removal
  const handleImageRemove = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: null,
    }));
    setImagePreview(null); // Remove image preview
  };
  

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="card p-0 ">
            <div className="card-body w-full p-0  w-full pb-3">
              <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
                <h2 className="card-title mb-4">
                  {id ? "Update Venue" : "Add Venue"}
                </h2>
                <FaTimes
                  className="text-danger cursor-pointer"
                  onClick={handleClose}
                />
              </div>
              <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
                  {imagePreview && (
                <div className="mb-3 col-md-6 p-2">
                  <label className="mb-2 block text-sm font-medium text-black dark:text-white">
                     Image
                  </label>
                  {/* <input
                    type="file"
                    accept=".jpg,.jpeg"
                    className="form-control"
                    onChange={handleFileChange}
                  /> */}
                    <div className="mt-2">
                      <img
                        src={imagePreview}
                        alt="preview"
                        width="380"
                        height="150"
                      />
                      <button
                        className="btn btn-danger"
                        onClick={handleImageRemove}
                      >
                        Remove
                      </button>
                    </div>
                </div>
                  )}
                <div className="mb-3 col-md-6 p-2">
                  <label htmlFor="venueCatId" className="form-label">
                    Select Venue Category
                  </label>
                  <select
                    className="form-control"
                    id="venueCatId"
                    value={formData.venueCatId}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select venueCategory </option>
                    {category &&
                      category?.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="mb-3 col-md-6 p-2">
                  <label htmlFor="cityId" className="form-label">
                    Select Cities
                  </label>
                  <select
                    className="form-control"
                    id="cityId"
                    value={formData.cityId}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select city</option>
                    {cities.map((city) => (
                      <option key={city._id} value={city._id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3 col-md-6 p-2">
                  <label htmlFor="venueTypeId" className="form-label">
                    Select Venue Type
                  </label>
                  <select
                    className="form-control"
                    id="venueTypeId"
                    value={formData.venueTypeId}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Venue Type</option>
                    {venueType.map((types) => (
                      <option key={types._id} value={types._id}>
                        {types.type}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3 col-md-6 p-2">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    id="name"
                    placeholder="Enter venue name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-md-6 p-2">
                  <label htmlFor="location" className="form-label">
                    Location
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    id="location"
                    placeholder="Enter location"
                    value={formData.location}
                    onChange={handleChange}
                    required
                  />
                  {suggestions?.results?.length > 0 && (
                    <ul className="suggestions-list">
                      {suggestions?.results.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelect(suggestion)}
                        >
                          {suggestion.formatted_address}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="mb-3 col-md-6 p-2">
                  <label htmlFor="long" className="form-label">
                    Longitude
                  </label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    id="long"
                    value={formData.long}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-md-6 p-2">
                  <label htmlFor="lat" className="form-label">
                    Latitude
                  </label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    id="lat"
                    value={formData.lat}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-md-6 p-2">
                  <label htmlFor="contactNo" className="form-label">
                    Contact Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="contactNo"
                    placeholder="Enter contact number"
                    value={formData.contactNo}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-md-12 p-2">
                  <label htmlFor="pricing" className="form-label">
                    Price Range (min-max)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="pricing"
                    placeholder="Enter price range (e.g., 2000-3000)"
                    value={formData.pricing}
                    onChange={handleChange}
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90 ms-2"
                >
                  {id ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default AddVenueForm;
