import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

const AddTermsAndServicesForm = () => {
  const { id } = useParams(); 
  const [formData, setFormData] = useState({
    termsAndServices: "",
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  
  const textareaRef = useRef(null); // Reference for the textarea

  useEffect(() => {
    if (id) {
      fetchTermsAndServicesData(id);
    }
  }, [id]);

  const fetchTermsAndServicesData = async (TermsAndServicesId) => {
    try {
      const response = await axios.get(`${apiUrl}/getTermsAndServices/${TermsAndServicesId}`);
      const TermsAndServicesData = response.data;
      console.log(TermsAndServicesData,"fhiflsfjsiflsfa");
      setFormData({
        termsAndServices: TermsAndServicesData.termsAndServices,
      });
    } catch (error) {
      console.error("Failed to fetch TermsAndServices data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    

    try {
      const dataToSend = {
        termsAndServices: formData.termsAndServices,
      };

      if (id) {
        await axios.put(`${apiUrl}/updateTermsAndServices/${id}`, dataToSend);
        toast.success("TermsAndServices Updated Successfully", { autoClose: 2000 });
      } else {
        await axios.post(`${apiUrl}/createTermsAndServices`, dataToSend);
        toast.success("TermsAndServices Added Successfully", { autoClose: 2000 });
      }

      setFormData({ termsAndServices: "" });

      setTimeout(() => {
        navigate('/kitty-terms&Services');
      }, 1000);
    } catch (error) {
      console.error("Failed to submit TermsAndServices data:", error);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleClose = () => {
    navigate('/kitty-terms&Services');
  };

  const scrollUp = () => {
    textareaRef.current.scrollTop -= 100; // Scroll 100px up
  };

  const scrollDown = () => {
    textareaRef.current.scrollTop += 100; // Scroll 100px down
  };

  return (
    <div className="container mt-4">
     <div className="row">
      <div className="col-md-8 mx-auto">
      <div className="card p-0">
        <div className=" card-body w-full p-0 pb-3">
          <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
            <h2 className="card-title mb-0">{id ? "Update TermsAndServices" : "Add TermsAndServices"}</h2>
            <FaTimes className="text-danger cursor-pointer" onClick={handleClose} />
          </div>
          <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
            <div className="mb-3 col-md-12 p-2">
              <label className="mb-2 block text-sm font-medium text-black dark:text-white">TermsAndServices</label>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <textarea
                    ref={textareaRef}
                    id="termsAndServices"
                    className="form-control"
                    rows="20"
                    style={{ overflow: 'auto', resize: 'none' }} // 'overflow: auto' enables scrolling
                    value={formData.termsAndServices}
                    onChange={handleChange}
                  />
                </div>
                <div className="d-flex flex-column ms-2">
                  <button type="button" className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1   mb-2" onClick={scrollUp}>
                    ↑
                  </button>
                  <button type="button" className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1  " onClick={scrollDown}>
                    ↓
                  </button>
                </div>
              </div>
            </div>
            <button type="submit" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
              {id ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </div>

      </div>
     </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default AddTermsAndServicesForm;
