import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;


const roleService = {
  async fetchRoles() {
    try {
      const response = await axios.get(`${apiUrl}/getAllRoles`);
      return response.data;  // Return the fetched roles data
    } catch (error) {
      console.error("Error fetching roles:", error);
      throw error;  // Rethrow the error to handle it in the calling component
    }
  }
};

export default roleService;
