import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import styled from 'styled-components';

const LayoutContainer = styled.div`
  display: flex;
  height: 100vh; /* Full height */
`;

const MainContent = styled.main`
  flex-grow: 1;
  padding: 20px;
  background-color: #F0F2F5; /* Light gray background for content */
  margin-top: 60px; /* Height of the Header */
  overflow-y: auto;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05); /* Subtle inset shadow for content area */
`;

const Layout = ({ children }) => (
  <LayoutContainer>
    <Sidebar />
    <MainContent>
      <Header />
      {children}
    </MainContent>
  </LayoutContainer>
);

export default Layout;
