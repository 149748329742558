import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import { RiDeleteBin4Fill } from "react-icons/ri";
import { IoIosEye } from "react-icons/io";
import VenueInformation from "../forms/VenueInformation"; // Import form for venue
import DeleteConfirmationModal from "../forms/DeleteConformation";
import { toast, ToastContainer } from "react-toastify";
import messages from "../messages.json";

import CustomTable from "../components/commonComponent/CustomTable";
import { usePermissions } from "../hooks/PermissionsContext";

const Venue = () => {
  const [venues, setVenues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [venuesPerPage] = useState(10); // Number of items per page
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [venueToDelete, setVenueToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [showLocationDropdown, setShowLocationDropdown] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [totalPages, setTotalPages] = useState(1); // Total pages from backend
  const [totalCount, settotalCount] = useState(); // Total pages from backend

  const [roles, setRoles] = useState([]);
  const [filteredVenues, setFilteredVenues] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;
  const { hasPermission } = usePermissions();

  const showView = hasPermission('Venues', 'View');
  const showAdd = hasPermission('Venues', 'Add');
  const showEdit = hasPermission('Venues', 'Update');
  const showDelete = hasPermission('Venues', 'Delete');


  const navigate = useNavigate();
  // let checkval = roles.includes("Admin"); 


  // Fetch venues from backend with pagination
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllVenues`, {
          params: {
            page: currentPage,
            limit: totalCount,
            name: searchInput,
          },
        });
        setVenues(response.data.data); // Update venues
        console.log(response?.data?.pagination?.totalCount,'cooooooooooooooo')
        settotalCount(response?.data?.pagination?.totalCount)
        setTotalPages(response.data.pagination.totalPages); // Set total pages from backend
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };
    fetchData();
  }, [currentPage, venuesPerPage, searchInput,totalCount]);  
  
  //For roles

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        console.log("Fetching roles...");
        const response = await axios.get(`${apiUrl}/getAllRoles`); 
        const userRoles = response.data;
      
        // Assuming your role data has a flag or array that indicates permissions
        const canAddVenue = userRoles.permissions.includes("addVenue");
        
        setRoles({ ...userRoles, canAddVenue });
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRoles(); // Call the fetch function
  }, []);

  useEffect(() => {
    const filterVenues = () => {
      let filtered = venues;
      if (searchInput !== '') {
        filtered = filtered.filter(venue =>
          venue.name && venue.name.toLowerCase().includes(searchInput.toLowerCase())
        );
      }
  
      if (selectedLocation.length > 0) {
        filtered = filtered.filter(venue =>
          selectedLocation.includes(venue.location)
        );
      }
  
      if (selectedStatuses.length > 0) {
        filtered = filtered.filter(venue =>
          selectedStatuses.includes(venue.isActive ? 'Active' : 'Inactive')
        );
      }
  
      setFilteredVenues(filtered);
    };
  
    filterVenues();
  }, [venues, searchInput, selectedLocation, selectedStatuses]);



  const handleShowDeleteModal = (venueId) => {
    setVenueToDelete(venueId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setVenueToDelete(null);
  };

  const handleDelete = async () => {
    if (venueToDelete) {
      try {
        await axios.delete(`${apiUrl}/deleteVenue/${venueToDelete}`);
        setVenues(venues.filter((venue) => venue._id !== venueToDelete)); // Remove deleted venue from the list
        toast.success(messages.success.delete, {
          autoClose: 2000,
        });
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setVenueToDelete(null);
      }
    }
  };

  const handleUpdate = (venueId) => {
    navigate(`/kitty-venues/update/${venueId}`);
  };

  const handleView = async (venueId) => {
    try {
      const response = await axios.get(`${apiUrl}/getVenueById/${venueId}`);
      setSelectedVenue(response.data);
      console.log(response.data);
      
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedVenue(null);
  };

  const handleStatusToggle = async (venueId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await axios.patch(`${apiUrl}/updateVenueStatus/${venueId}`, {
        isActive: updatedStatus,
      });
      setVenues(
        venues.map((venue) =>
          venue._id === venueId ? { ...venue, isActive: updatedStatus } : venue
        )
      );
      toast.success(`Venue status updated to ${updatedStatus ? "Active" : "Inactive"}`, {
        autoClose: 2000,
      });
   
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const uniqueLocation = [...new Set(venues.map((venue) => venue.location))];

  const handleLocationChange = (location) => {
    setSelectedLocation(prevState =>
      prevState.includes(location) ? prevState.filter(item => item !== location) : [...prevState, location]
    );
  };
  
  const handleStatusChange = (status) => {
    setSelectedStatuses(prevState =>
      prevState.includes(status) ? prevState.filter(item => item !== status) : [...prevState, status]
    );
  };
  const columns = [
    { header: 'S.No', accessor: 'id' },
    { header: 'Name', accessor: 'name' },
    { header: 'Location', accessor: 'location' },
    // { header: 'Pricing', accessor: 'pricing' },
    // { header: 'Location', accessor: 'location' },
    // { header: 'Profession', accessor: 'profession' },
  ];  

 




  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">Venues Information</h2>
        </div>
        <div className="col-auto">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <span className="input-group-text">
              <FaSearch />
            </span>
          </div>
        </div>
        <div className="col-auto">
          <div className="dropdown d-inline-block me-2">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1"
              type="button"
              onClick={() => setShowLocationDropdown(!showLocationDropdown)}
            >
              Location
              <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showLocationDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                {uniqueLocation.map((location, index) => (
                  <li key={index}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={location}
                        id={`location-${index}`}
                        checked={selectedLocation.includes(location)}
                        onChange={() => handleLocationChange(location)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`location-${index}`}
                      >
                        {location}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="dropdown d-inline-block">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1"
              type="button"
              onClick={() => setShowStatusDropdown(!showStatusDropdown)}
            >
              Status
              <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showStatusDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Active"
                      id="status-active"
                      checked={selectedStatuses.includes("Active")}
                      onChange={() => handleStatusChange("Active")}
                    />
                    <label className="form-check-label" htmlFor="status-active">
                      Active
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Inactive"
                      id="status-inactive"
                      checked={selectedStatuses.includes("Inactive")}
                      onChange={() => handleStatusChange("Inactive")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="status-inactive"
                    >
                      Inactive
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        { showAdd && (

        <div className="col-auto"   > 
          <Link to="/kitty-venues/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add Venue
          </Link>
        </div>
        )}
      </div>
   
    
      <CustomTable
  tableData={filteredVenues}
  columns={columns}
  handleStatusToggle={handleStatusToggle}
  showStatus={true}
  handleView={handleView}
  handleUpdate={handleUpdate}
  handleShowDeleteModal={handleShowDeleteModal}
  actions={{
    showView,
    showEdit,
    showDelete,
  }}
/>
      
        <VenueInformation 
        show={showModal} 
        onClose={handleCloseModal} 
        venue={selectedVenue} />
      
      
        <DeleteConfirmationModal
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={handleDelete}
        />
      
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default Venue;
