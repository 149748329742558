import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./auth/AuthContext";
import AppRouter from "./router/AppRouter";
import { Suspense } from "react";
import { PermissionsProvider } from './hooks/PermissionsContext';


function App() {
  return (
    <>
      <ToastContainer />
    <AuthProvider>
    <PermissionsProvider>

      <Suspense>
        {" "}
        <AppRouter />
      </Suspense>
      </PermissionsProvider>

    </AuthProvider>
    </>
  );
}

export default App;
