import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddGroupForm.css"; // Ensure this CSS file is created for custom styling
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import messages from "../messages.json";
import { FaTimes } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";
const AddGroupForm = () => {
  const { id } = useParams(); // Extract group ID from URL params
  const navigate = useNavigate(); // Navigate function from react-router-dom

  const [formData, setFormData] = useState({
    image: "",
    name: "",
    userId: "",
    groupType: "",
    groupInterestId: [],
    description: "",
    rulesAndRegulation: "",
    
    groupFrequencyId: "",
    groupCityArea: "",
    contributionAmount: "",
    selectedUsers: [],
  });

  const [suggestions, setSuggestions] = useState([]);
  const [groupinterests, setGroupInterests] = useState([]); // State to store interests
  // const [interest, setInterest] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});

  const [users, setUsers] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const validate = () => {
    const newErrors = {};
    const currentDate = new Date();

    if (!formData.image) {
      newErrors.image = "Image is required";
    }
    if (!formData.name) {
      newErrors.name = "name is required";
    }
    if (!formData.groupType) {
      newErrors.groupType = "groupType is required";
    }
    if (!formData.groupInterestId) {
      newErrors.groupInterestId = "groupInterestId is required";
    }
    if (!formData.rulesAndRegulation) {
      newErrors.rulesAndRegulation = "rulesAndRegulation is required";
    }
    if (!formData.contributionAmount) {
      newErrors.contributionAmount = "contributionAmount is required";
    }
    return newErrors;
  };
  const handleBlur = (e) => {
    const { id } = e.target;
    const validationErrors = validate();

    if (validationErrors[id]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: validationErrors[id],
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    }
  };

  // Fetch user data from API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const [
          usersResponse,
          frequencyResponse,
          interestResponse,
         
        ] = await Promise.all([
          axios.get(`${apiUrl}/getAllUsersList`),
          axios.get(`${apiUrl}/getAllGroupsFrequency`),
          axios.get(`${apiUrl}/getAllGroupsInterest`),
          // axios.get(`${apiUrl}/sendInterestAndPreference`)
        ]);
        let filteredUsers = usersResponse?.data?.data.filter(
          (item) => item?.fullname
        );

        setUsers(filteredUsers);
        console.log(usersResponse?.data?.data, "dsuisudiuisduisd");

        console.log("for test", usersResponse?.data?.data);

        setFrequency(frequencyResponse?.data?.data);
        console.log("for test", frequencyResponse?.data?.data);

        setGroupInterests(interestResponse?.data?.data);

        // setInterest(intResponse.data.data.events);
      } catch (error) {
        console.error(messages?.error?.fetchData, error);
      }
    };
    fetchUsers();
  }, []);
  // If in update mode, fetch group data by ID
  useEffect(() => {
    if (id) {
      fetchGroupData(id);
    }
  }, [id]);
  //get id from localstorage
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user?._id) {
      console.log("User ID from local storage:", user?._id);
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: user?._id, // Set user ID from local storage
      }));
    } else {
      console.error("User ID not found in local storage");
    }
  }, []);

  const fetchGroupData = async (groupId) => {
    try {
      const response = await axios.get(`${apiUrl}/getGroupById/${groupId}`);
      const groupData = response?.data?.data;
      console.log("Fetched Group Data:", groupData); // Debugging statement

      const selectedUsers = groupData.userIds.map((user) => ({
        userId: user?.userId, // Store user ID
        fullname: user?.userId?.fullname, // Extract fullname from userId object
        status: user?.status, // Assuming user.status exists
      }));

      setFormData({
        name: groupData?.name,
        userId: groupData?.userId,
        image: groupData?.image,
        groupType: groupData?.groupType,
      
        groupInterestId: groupData?.groupInterestId.map(
          (groupinterests) => groupinterests._id
        ),
        description: groupData?.description,
        rulesAndRegulation: groupData?.rulesAndRegulation,
        groupFrequencyId: groupData?.groupFrequencyId._id,
        groupCityArea: groupData?.groupCityArea,
        contributionAmount: groupData?.contributionAmount,
        selectedUsers: selectedUsers,
      
      });
      if (groupData?.image) {
        setImagePreview(groupData?.image);
        setImageUrl(groupData?.image);
      }
    } catch (error) {
      console.error(messages?.error?.failedFetchData, error);
    }
  };

  // Handle input change
  const handleChange = async (e) => {
    const { id, value } = e.target;
    console.log("Handle Change - Field:", id, "Value:", value); // Debugging statement

    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));

    if (id === "groupCityArea" && value?.length > 2) {
      const groupCityAreaApiUrl = `https://api.olamaps.io/places/v1/autocomplete?input=${value}&api_key=TdoidCwf5FxL1rVCqS7KnbWT8Gq561obQaMWzBoR`;
      try {
        const response = await axios.get(groupCityAreaApiUrl);
        setSuggestions(response?.data?.predictions || []);
      } catch (error) {
        console.error("Error fetching groupCityArea suggestions:", error);
      }
    } else if (id === "groupCityArea") {
      setSuggestions([]);
    }
  };
  const handleSelect = (suggestion) => {
    const { description } = suggestion;
    setFormData((prevFormData) => ({
      ...prevFormData,
      groupCityArea: description,
    }));
    setSuggestions([]);
  };

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    console.log("Handle Checkbox Change - Value:", value, "Checked:", checked); // Debugging statement

    setFormData((prevFormData) => {
      // Check if the user is already in the selectedUsers array
      const userExists = prevFormData?.selectedUsers.find(
        (user) => user?.userId === value
      );

      if (checked && !userExists) {
        // If checked and user is not already in the array, add the user with default status
        return {
          ...prevFormData,
          selectedUsers: [
            ...prevFormData?.selectedUsers,
            { userId: value, status: "approved" }, // You can adjust status as needed
          ],
        };
      } else if (!checked && userExists) {
        // If unchecked and user exists, remove the user from the array
        return {
          ...prevFormData,
          selectedUsers: prevFormData?.selectedUsers.filter(
            (user) => user?.userId !== value
          ),
        };
      }
      return prevFormData; // No changes if user is already selected and re-checked
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.groupInterestId.length === 0) {
      toast.error("At least one group interest is required."); // Notify user
      return;
    }
  

    let uploadedImageUrl = imageUrl;
    const validationErrors = validate();
    if (Object.keys(validationErrors)?.length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});

    if (!formData.userId) {
      toast.error("User ID is required.");
      return;
    }
    // let imageUrl = '';

    if (formData.image) {
      try {
        const file = formData?.image;
        const fileExtension = file?.name?.split(".")?.pop();
        const fileName = `${uuidv4()}.${fileExtension}`;

        // Initialize the S3 Client
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
          region: "ap-south-1",
        });

        const s3 = new AWS.S3();
        const params = {
          Bucket: "kittybee",
          Key: `template/${fileName}`,
          Body: file,
          ContentType: file?.type,
        };

        // Upload the file
        const data = await s3.upload(params).promise();
        uploadedImageUrl = data?.Location;
        setImageUrl(uploadedImageUrl); // Update image URL state
      } catch (err) {
        console.error("Error uploading file:", err);
        toast.error("Failed to upload image", { autoClose: 2000 });
        return;
      }
    }

    const groupData = {
      ...formData,
      image: uploadedImageUrl, // Ensure image is a string URL

      userIds: formData?.selectedUsers,
    };
    console.log("Form Data for Submit:", groupData); // Debugging statement

    try {
      if (id) {
        await axios.put(`${apiUrl}/updateGroup/${id}`, groupData);
        toast.success(messages?.success?.update, { autoClose: 2000 });
      } else {
        await axios.post(`${apiUrl}/addGroup`, groupData);
        toast.success(messages?.success?.dataAdded, { autoClose: 2000 });
        setImagePreview(null);
      }
      setFormData({
        image: "",
        name: "",
        userId: "",
        groupType: "",
       
        groupInterestId: [],
        description: "",
        rulesAndRegulation: "",
        groupFrequencyId: "",
        groupCityArea: "",
        contributionAmount: "",
        selectedUsers: [],
      });
      setTimeout(() => {
        navigate("/kitty-groups");
      }, 2000);

      // Reset form and navigate as needed
    } catch (error) {
      console.error(messages?.error?.errorAdding, error);
      toast.error(messages?.error?.failedAdding);
    }
  };
  const handleImageRemove = () => {
    setFormData((prevFormData) => ({ ...prevFormData, image: null }));
    setImagePreview(null);
    setImageUrl(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file?.type.startsWith("image/")) {
      // Check if the file is an image
      setFormData((prevFormData) => ({
        ...prevFormData,
        image: file,
      }));
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    } else {
      console.error("Selected file is not an image");
    }
  };

  const handleClose = () => {
    navigate("/kitty-groups");
  };
  

  const handleInterestChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevFormData) => {
      if (checked) {
        return {
          ...prevFormData,
          groupInterestId: [...prevFormData.groupInterestId, value], // Add interest ID if checked
        };
      } else {
        return {
          ...prevFormData,
          groupInterestId: prevFormData?.groupInterestId.filter(
            (interestId) => interestId !== value // Remove interest ID if unchecked
          ),
        };
      }
    });
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="card p-0 ">
            <div className="card-body w-full p-0 pb-3 w-full p-0 pb-3">
              <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
                <h2 className="card-title mb-0">
                  {id ? "Update Group" : "Add Group"}
                </h2>
                <FaTimes
                  className="text-danger cursor-pointer"
                  onClick={handleClose}
                />
              </div>

              <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
                <div className="mb-3 col-md-4 p-2">
                  <label
                    htmlFor="name"
                    className="mb-2 block text-sm font-medium text-black dark:text-white"
                  >
                    Group Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    id="name"
                    placeholder="Enter group name"
                    value={formData?.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3 col-md-4 p-2">
                  <label
                    htmlFor="groupType"
                    className="mb-2 block text-sm font-medium text-black dark:text-white"
                  >
                    Group Type
                  </label>
                  <select
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    id="groupType"
                    placeholder="Enter group type"
                    value={formData?.groupType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  >
                    <option value="">Select group type</option>
                    <option value="private">private</option>
                    <option value="public">public</option>
                  </select>
                </div>

                <div className="mb-3 col-md-6 p-2">
                  <label
                    htmlFor="userId"
                    className="mb-2 block text-sm font-medium text-black dark:text-white"
                  >
                    Users <span className="text-danger">*</span>{" "}
                  </label>
                  <div className="dropdown" aria-haspopup="true">
                    <button
                      className="text-left flex rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1  w-100"
                      type="button"
                      id="dropdownMenuButton"
                      value={formData?.userId}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      required
                    >
                      Select Users
                      <svg
                        className="ml-2 -mr-1 h-3 w-3 mt-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </button>
                    <ul
                      className="dropdown-menu w-100 px-[10px]"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {users.map((user) => (
                        <li key={user?._id} className="dropdown-item">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={user?._id}
                              id={`user-${user?._id}`}
                              onChange={handleCheckboxChange} // No need to pass user ID, handled by the checkbox value
                              checked={formData?.selectedUsers.some(
                                (selected) => selected?.userId === user?._id
                              )} // Check if user is already selected
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`user-${user?._id}`}
                            >
                              {user?.fullname}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                    {formData.userId.length === 0 && (
                    <div className="text-danger mt-2">
                      At least one user must be selected.
                    </div>
                  )}
                    {formData?.selectedUsers.map((selectedUser, index) => {
                      return (
                        <span key={index} className="badge bg-primary me-2">
                          {selectedUser?.fullname}
                        </span>
                      );
                    })}
                  </div>
                </div>
                <div className="mb-3 col-md-6 p-2">
                  <label
                    htmlFor="groupInterestId"
                    className="mb-2 block text-sm font-medium text-black dark:text-white"
                  >
                    Group Interest <span className="text-danger">*</span>{" "}
                    {/* Asterisk for required */}
                  </label>
                  <button
                    className="flex rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 w-100"
                    id="dropdownMenuButton"
                    type="button" // Use "button" to prevent immediate form submission
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Select Interests
                    <svg
                      className="ml-2 -mr-1 h-3 w-3 mt-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  <ul
                    className="dropdown-menu w-50"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {groupinterests.map((interest) => (
                      <li key={interest?._id} className="dropdown-item">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={interest?._id}
                            id={`interest-${interest?._id}`}
                            onChange={handleInterestChange}
                            checked={formData?.groupInterestId.includes(
                              interest?._id
                            )}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`interest-${interest?._id}`}
                          >
                            {interest?.name}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {/* Optional message for required field */}
                  {formData.groupInterestId.length === 0 && (
                    <div className="text-danger mt-2">
                      At least one interest must be selected.
                    </div>
                  )}
                </div>

                <div className="mb-3 col-md-6 p-2">
                  <label
                    htmlFor="image"
                    className="mb-2 block text-sm font-medium text-black dark:text-white"
                  >
                    Upload Image
                  </label>
                  <input
                    type="file"
                    accept=".jpg,.jpeg"
                    className="form-control"
                    id="image"
                    onChange={handleFileChange}
                    onBlur={handleBlur}
                    required
                  />
                  {imagePreview && (
                    <div className="mb-3 col-md-6 p-2">
                      <img
                        src={imagePreview}
                        alt="Preview"
                        style={{ maxWidth: "100%", maxHeight: "300px" }}
                      />
                       <button
                        className="btn btn-danger"
                        onClick={handleImageRemove}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
                <div className="mb-3 col-md-6 p-2">
                  <label
                    htmlFor="contributionAmount"
                    className="mb-2 block text-sm font-medium text-black dark:text-white"
                  >
                    Contribution Amount
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="contributionAmount"
                    autoComplete="off"
                    placeholder="Enter contribution amount"
                    value={formData.contributionAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                </div>

                <div className="mb-3 col-md-4 p-2">
                  <label
                    htmlFor="groupFrequencyId"
                    className="mb-2 block text-sm font-medium text-black dark:text-white"
                  >
                    Group Frequency
                  </label>
                  <select
                    className="form-control"
                    id="groupFrequencyId"
                    value={formData?.groupFrequencyId}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select group frequency</option>
                    {frequency.map((frequency) => (
                      <option key={frequency?._id} value={frequency?._id}>
                        {frequency?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3 col-md-6 p-2">
                  <label
                    htmlFor="groupCityArea"
                    className="mb-2 block text-sm font-medium text-black dark:text-white"
                  >
                    Group City/Area
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="groupCityArea"
                    autoComplete="off"
                    placeholder="Enter group city area"
                    value={formData?.groupCityArea}
                    onChange={handleChange}
                    required
                  />
                  {suggestions.length > 0 && (
                    <ul className="suggestions-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelect(suggestion)}
                        >
                          {suggestion.description}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="mb-3 col-md-6 p-2">
                  <label
                    htmlFor="description"
                    className="mb-2 block text-sm font-medium text-black dark:text-white"
                  >
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    autoComplete="off"
                    id="description"
                    placeholder="Enter description"
                    value={formData?.description}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-md-12 p-2">
                  <label
                    htmlFor="rulesAndRegulation"
                    className="mb-2 block text-sm font-medium text-black dark:text-white"
                  >
                    Rules and Regulations
                  </label>
                  <textarea
                    className="form-control"
                    id="rulesAndRegulation"
                    autoComplete="off"
                    placeholder="Enter rules and regulations"
                    value={formData?.rulesAndRegulation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                </div>

                <button
                  type="submit"
                  // onClick={handleSubmit}
                  className="flex justify-center ms-2 rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                >
                  {id ? "Update" : "Add Group"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddGroupForm;
