import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { GetColorName } from "hex-color-to-color-name";


const ColorsInformation = ({ show, handleClose, color }) => {
  console.log(color, "testt");

  const colorName = color ? GetColorName(color.name) : "";

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="flex items-center justify-center min-h-screen"
    >
      <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-4xl mx-auto">
        <Modal.Header className="bg-[#0e121a] text-white border-b border-gray-200">
          <Modal.Title className="text-xl font-semibold">
            Color Information
          </Modal.Title>
          <Button
            variant="close"
            onClick={handleClose}
            className="text-white hover:text-gray-400"
          >
            &times;
          </Button>
        </Modal.Header>

        <Modal.Body className="p-3">
          {color ? (
            <div className="space-y-4">
              <p className="text-gray-700">
                <strong>Hex Code:</strong>{" "}
                <span className="text-gray-500">{color?.hex || color?.name}</span>
              </p>

              <p className="text-gray-700">
                <strong>Color Name:</strong>{" "}
                <span className="text-gray-500">{colorName}</span>
              </p>

              {/* Displaying the color image */}
              <div className="space-y-2">
                <p className="text-gray-700">
                  <strong>Color Preview:</strong>
                </p>
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    backgroundColor: color?.hex || color?.name, 
                    border: "2px solid #000", 
                    borderRadius: "8px",
                  }}
                ></div>
              </div>

              <p className="text-gray-700">
                <strong>Status:</strong>{" "}
                <span
                  className={`${
                    color.isActive ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {color.isActive ? "Active" : "Inactive"}
                </span>
              </p>
            </div>
          ) : (
            <p className="text-gray-600">Loading...</p>
          )}
        </Modal.Body>

        <Modal.Footer className="bg-gray-100 p-3">
          <Button
            variant="secondary"
            onClick={handleClose}
            className="bg-gray-600 text-white hover:bg-gray-700 transition duration-200"
          >
            Close
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ColorsInformation;
