import React from 'react';

const FormInput = ({ label, type = 'text', value, onChange }) => (
  <div className="mb-4">
    <label className="block mb-2 text-gray-700 font-semibold">
      {label}
    </label>
    <input
      type={type}
      value={value}
      onChange={onChange}
      className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      placeholder={`Enter ${label.toLowerCase()}`}
    />
  </div>
);

export default FormInput;
