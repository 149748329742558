// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure chart-container styles are consistent */
.chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  /* Adjust layout styles */
  .container-fluid {
    padding: 20px;
  }
  
  .row {
    margin-bottom: 20px;
  }


  text.apexcharts-text{fill: #fff !important;}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard.css"],"names":[],"mappings":"AAAA,iDAAiD;AACjD;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;EACd;;EAEA,yBAAyB;EACzB;IACE,aAAa;EACf;;EAEA;IACE,mBAAmB;EACrB;;;EAGA,qBAAqB,qBAAqB,CAAC","sourcesContent":["/* Ensure chart-container styles are consistent */\n.chart-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n  }\n  \n  /* Adjust layout styles */\n  .container-fluid {\n    padding: 20px;\n  }\n  \n  .row {\n    margin-bottom: 20px;\n  }\n\n\n  text.apexcharts-text{fill: #fff !important;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
