import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';

const AddTemplateForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    image: null
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (id) {
      fetchTemplateData(id);
    }
  }, [id]);

  const fetchTemplateData = async (templateId) => {
    try {
      const response = await axios.get(`${apiUrl}/getTemplateById/${templateId}`);
      const templateData = response.data.data;
      setFormData({
        name: templateData.name,
        image: templateData.image
      });
      setImagePreview(templateData.image);
      setImageUrl(templateData.image); // Set initial image URL if editing
    } catch (error) {
      console.error("Failed to fetch template data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let uploadedImageUrl = imageUrl; // Use the existing URL if no new image

    if (formData.image) {
      try {
        const file = formData.image;
        const fileExtension = file.name.split('.').pop();
        const fileName = `${uuidv4()}.${fileExtension}`;

        // Initialize the S3 Client
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
          region: 'ap-south-1',
        });

        const s3 = new AWS.S3();
        const params = {
          Bucket: 'kittybee',
          Key: `template/${fileName}`,
          Body: file,
          ContentType: file.type,
        };

        // Upload the file
        const data = await s3.upload(params).promise();
        uploadedImageUrl = data.Location;
        setImageUrl(uploadedImageUrl); // Update image URL state
      } catch (err) {
        console.error("Error uploading file:", err);
        toast.error("Failed to upload image", { autoClose: 2000 });
        return;
      }
    }

    try {
      const dataToSend = {
        name: formData.name,
        image: uploadedImageUrl,
      };

      if (id) {
        await axios.put(`${apiUrl}/updateTemplateById/${id}`, dataToSend);
        toast.success("Template Updated Successfully", { autoClose: 2000 });
      } else {
        await axios.post(`${apiUrl}/createTemplate`, dataToSend);
        toast.success("Template Added Successfully", { autoClose: 2000 });
      }

      setFormData({ name: "", image: null });
      setImagePreview(null);
      setImageUrl(null); // Clear image URL

      setTimeout(() => {
        navigate('/kitty-template');
      }, 1000);
    } catch (error) {
      console.error("Failed to submit template data:", error);
      toast.error("Failed to submit template data", { autoClose: 2000 });
    }
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
    setImagePreview(URL.createObjectURL(file));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleClose = () => {
    navigate('/kitty-template');
  };


  const handleImageRemove = () => {
    setFormData((prevFormData) => ({ ...prevFormData, image: null }));
    setImagePreview(null);
    setImageUrl(null);
  };

  return (
    <div className="container mt-4">
     <div className="row">
      <div className="col-md-8 mx-auto">
      <div className="card p-0">
        <div className="card-body w-full p-0 pb-3">
          <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
            <h2 className="card-title mb-0">{id ? "Update Template" : "Add Template"}</h2>
            <FaTimes className="text-danger cursor-pointer" onClick={handleClose} />
          </div>
          <form onSubmit={handleSubmit} className='flex flex-wrap px-3'>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="name" className="mb-2 block text-sm font-medium text-black dark:text-white">Template Name</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="name"
                placeholder="template name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="image" className="mb-2 block text-sm font-medium text-black dark:text-white">Upload Image</label>
              <input
                type="file"
                className="form-control"
                id="image"
                accept=".jpg,.jpeg,.png"
                onChange={handleFileChange}
              />
              {imagePreview && (
                <div className="mt-3">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "100%", maxHeight: "300px" }}
                  />
                   <button
                        className="btn btn-danger"
                        onClick={handleImageRemove}
                      >
                        Remove
                      </button>
                </div>
              )}
            </div>
            <button type="submit" className="flex ms-2 justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
              {id ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </div>
      </div>
     </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default AddTemplateForm;
