// src/router/ProtectedRoutes.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContext';

const ProtectedRoutes = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);
  

  if (loading) {

    return <div>Loading...</div>
    
  }
  return isAuthenticated ? children : <Navigate to="/login" />;
};

// const ProtectedRoutes = ({ children }) => {


//   return localStorage.getItem('token') ? children : <Navigate to="/login" />;
// };


export default ProtectedRoutes;
