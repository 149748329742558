import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import messages from "../messages.json";
import { toast, ToastContainer } from "react-toastify";
import FrequencyInformation from "../forms/FrequencyInformation";
import DeleteConfirmationModal from "../forms/DeleteConformation";
import CustomTable from "../components/commonComponent/CustomTable";
import { usePermissions } from "../hooks/PermissionsContext";

const Frequency = () => {
  const [frequency, setFrequency] = useState([]);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [frequencyToDelete, setFrequencyToDelete] = useState(null);
  const [filteredFrequency, setFilteredFrequency] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { hasPermission } = usePermissions();

  const showView = hasPermission('Group Frequency', 'View');
  const showAdd = hasPermission('Group Frequency', 'Add');
  const showEdit = hasPermission('Group Frequency', 'Update');
  const showDelete = hasPermission('Group Frequency', 'Delete');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllGroupsFrequency`);
        setFrequency(response.data.data);
        setFilteredFrequency(response.data.data);
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    filterFrequency();
  }, [searchInput, frequency]);

  const filterFrequency = () => {
    let filtered = frequency;
    if (searchInput !== "") {
      filtered = filtered.filter(
        (frequency) =>
          frequency.name &&
          frequency.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    setFilteredFrequency(filtered);
  };

  const handleUpdate = (id) => {
    navigate(`/group-frequency/update/${id}`);
  };

  const handleShowDeleteModal = (frequencyId) => {
    setFrequencyToDelete(frequencyId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setFrequencyToDelete(null);
  };

  const handleDelete = async () => {
    if (frequencyToDelete)
      try {
        await axios.delete(
          `${apiUrl}/deleteFrequencyGroup/${frequencyToDelete}`
        );
        setFrequency(
          frequency.filter((frequency) => frequency._id !== frequencyToDelete)
        );
        toast.success(messages.success.delete, { autoClose: 2000 });
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setFrequencyToDelete(null);
      }
  };

  const handleView = async (frequencyId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/getGroupFrequencyById/${frequencyId}`
      );
      console.log("API Response:", response.data);
      setSelectedFrequency(response.data);
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFrequency(null);
  };

  const columns = [
    { header: "S.No", accessor: "id" },
    { header: "Frequencies", accessor: "name" },
  ];

  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">Frequency Information</h2>
        </div>

        {showAdd  && (

        <div className="col-auto">
          <Link to="/group-frequency/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add Frequency
          </Link>
        </div>
        )}
      </div>

      <CustomTable
        tableData={frequency}
        columns={columns}
        
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView,
          showEdit,
          showDelete,
        }}
      />

      {selectedFrequency && (
        <FrequencyInformation
          frequency={selectedFrequency}
          show={showModal}
          handleClose={handleCloseModal}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={handleDelete}
        />
      )}
      <ToastContainer autoClose={2100}/>
    </div>
  );
};

export default Frequency;
