import React, { createContext, useContext, useState, useEffect } from 'react';
import roleService from '../services/roleService'; // Ensure you import your service

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [roleArray, setRoleArray] = useState([]);
  const [loginUser, setLoginUser] = useState(null);

  // Function to fetch and set roles, with error handling
  const fetchRoles = async () => {
    try {
      const rolesData = await roleService.fetchRoles(); // Call the service method
      setRoleArray(rolesData);
      localStorage.setItem('roleData', JSON.stringify(rolesData));
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };
  useEffect(() => {
    fetchRoles()
  },[])
  // Effect to load data from localStorage and refresh roles from the API
  useEffect(() => {
      // const roleData = localStorage.getItem('roleData');
      const parsedRoles =roleArray || [];
      const userData = localStorage.getItem('user');
      const parsedUser = JSON.parse(userData);

      setRoleArray(parsedRoles);
      setLoginUser(parsedUser);

    if (!roleArray.length) {
      fetchRoles();
    }

   
 
  }, [roleArray]);

  // Effect to update roles whenever loginUser changes
  useEffect(() => {
    if (loginUser && roleArray.length === 0) {
      fetchRoles(); // Fetch fresh roles if loginUser exists and roles are not loaded
    }
  }, [loginUser]);

  // Add a function to manually refresh roles
  const refreshRoles = async () => {
    await fetchRoles(); // Fetch fresh data
  };

  // Function to get allowed actions for a page
  const getActionsForPage = (pageName) => {
    if (!loginUser || !roleArray?.length) return [];
    const userRole = roleArray.find((role) => role?.userId?._id === loginUser?._id);

    if (userRole) {
      const permission = userRole.permissions.find((perm) => perm?.page?.pageName === pageName);
      return permission ? permission?.actions : [];
    }

    return [];
  };

  // Function to check if a user has permission to perform a certain action on a page
  const hasPermission = (pageName, action) => {
    const actionsAllowed = getActionsForPage(pageName);
    return actionsAllowed.includes(action);
  };

  return (
    <PermissionsContext.Provider
      value={{
        getActionsForPage,
        hasPermission,
        roleArray,
        loginUser,
        refreshRoles,  // Expose the refresh function
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  return useContext(PermissionsContext);
};


// import React, { createContext, useContext, useState, useEffect } from 'react';
// import roleService from '../services/roleService'; // Ensure you import your service

// const PermissionsContext = createContext();

// export const PermissionsProvider = ({ children }) => {
//   const [roleArray, setRoleArray] = useState([]);
//   const [loginUser, setLoginUser] = useState(null);
//   const [loading, setLoading] = useState(true); // Add loading state

//   // Function to fetch and set roles, with error handling
//   const fetchRoles = async () => {
//     setLoading(true); // Start loading
//     try {
//       const rolesData = await roleService.fetchRoles(); // Call the service method
//       console.log(rolesData, 'Fetched Roles Data');
//       setRoleArray(rolesData); // Update the context state with fetched roles
//     } catch (error) {
//       console.error("Error fetching roles:", error);
//     } finally {
//       setLoading(false); // End loading
//     }
//   };

//   // Fetch roles on component mount
//   useEffect(() => {
//     fetchRoles();
//   }, []);

//   // Load user data from localStorage when the component mounts
//   useEffect(() => {
//     const userData = localStorage.getItem('user');
//     if (userData) {
//       const parsedUser = JSON.parse(userData);
//       setLoginUser(parsedUser);
//       fetchRoles();  // Fetch roles again when user is set
//     }
//   }, []);

//   // Effect to log the roleArray updates
//   useEffect(() => {
//     console.log(roleArray, 'Updated Role Array');
//   }, [roleArray]);

//   // Function to get allowed actions for a specific page
//   const getActionsForPage = (pageName) => {
//     if (!loginUser || !roleArray.length) return [];
//     const userRole = roleArray.find((role) => role.userId._id === loginUser._id);

//     if (userRole) {
//       const permission = userRole.permissions.find((perm) => perm.page.pageName === pageName);
//       return permission ? permission.actions : [];
//     }
//     return [];
//   };

//   // Function to check if a user has permission to perform a certain action on a page
//   const hasPermission = (pageName, action) => {
//     const actionsAllowed = getActionsForPage(pageName);
//     return actionsAllowed.includes(action);
//   };

//   // Provide context values
//   return (
//     <PermissionsContext.Provider
//       value={{
//         getActionsForPage,
//         hasPermission,
//         roleArray,
//         loginUser,
//         loading,  // Expose the loading state
//         refreshRoles: fetchRoles,  // Expose the refresh function
//       }}
//     >
//       {children}
//     </PermissionsContext.Provider>
//   );
// };

// // Custom hook to use the Permissions context
// export const usePermissions = () => {
//   return useContext(PermissionsContext);
// };