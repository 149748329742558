import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';

const AddPostForm = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    image: null,
    description: '',
    userId: "",
    isActive: true,
    poll: {
      question: '',
      options: [{ text: '', votes: 0 }]
    },
    comments: []
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

 
 

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchPostData(id);
    }
  }, [id]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user._id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: user._id
      }));
    } else {
      console.error('User ID not found in local storage');
    }
  }, []);

  const fetchPostData = async (postId) => {
    try {
      const response = await axios.get(`${apiUrl}/getPostById/${postId}`);
      const postData = response.data.data;
      setFormData({
        name: postData.name,
        image: postData.image,
        description: postData.description,
        userId: postData.userId,
        isActive: postData.isActive,
        poll: postData.poll || { question: '', options: [{ text: '', votes: 0 }] },
        comments: postData.comments || []
      });
      setImagePreview(postData.image);
      setImageUrl(postData.image);
    } catch (error) {
      console.error("Failed to fetch post data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let uploadedImageUrl = imageUrl; 
    if (formData.image) {
      try {
        const file = formData.image;
        const fileExtension = file.name.split('.').pop();
        const fileName = `${uuidv4()}.${fileExtension}`;

        // Initialize the S3 Client
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
          region: 'ap-south-1',
        });

        const s3 = new AWS.S3();
        const params = {
          Bucket: 'kittybee',
          Key: `template/${fileName}`,
          Body: file,
          ContentType: file.type,
        };

        // Upload the file
        const data = await s3.upload(params).promise();
        uploadedImageUrl = data.Location;
        setImageUrl(uploadedImageUrl); // Update image URL state
      } catch (err) {
        console.error("Error uploading file:", err);
        toast.error("Failed to upload image", { autoClose: 2000 });
        return;
      }
    }

    try {
      const dataToSend = {
        ...formData,
        image: uploadedImageUrl
      };

      if (id) {
        await axios.put(`${apiUrl}/updatePostById/${id}`, dataToSend);
        toast.success("Post Updated Successfully");
      } else {
        await axios.post(`${apiUrl}/addPost`, dataToSend);
        toast.success("Post Added Successfully");
      }

      setFormData({
        name: "",
        userId: '',
        image: null,
        description: '',
        isActive: true,
        poll: { question: '', options: [{ text: '', votes: 0 }] },
        comments: []
      });
      setImagePreview(null);
      setImageUrl(null);

      setTimeout(() => {
        navigate('/kitty-post');
      }, 1000);
    } catch (error) {
      console.error("Failed to submit post data:", error);
    }
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
    setImagePreview(URL.createObjectURL(file));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handlePollChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      poll: {
        ...prevFormData.poll,
        [name]: value
      }
    }));
  };

  const handleOptionChange = (index, e) => {
    const { value } = e.target;
    const newOptions = [...formData.poll.options];
    newOptions[index].text = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      poll: {
        ...prevFormData.poll,
        options: newOptions
      }
    }));
  };

  const deleteOption = (index) => {
    setFormData((prevFormData) => {
      const newOptions = [...prevFormData.poll.options];
      newOptions.splice(index, 1); // Remove the option at the specified index
      return {
        ...prevFormData,
        poll: {
          ...prevFormData.poll,
          options: newOptions,
        },
      };
    });
  };
  

  const addOption = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      poll: {
        ...prevFormData.poll,
        options: [...prevFormData.poll.options, { text: '', votes: 0 }]
      }
    }));
  };

  const handleClose = () => {
    navigate('/kitty-post');
  };

 
  const handleImageRemove = () => {
    setFormData((prevFormData) => ({ ...prevFormData, image: null }));
    setImagePreview(null);
    setImageUrl(null);
   
  };


  return (
    <div className="container mt-4">
   <div className="row">
    <div className="col-md-8 mx-auto">
    <div className="card p-0">
        <div className=" card-body w-full p-0 pb-3">
          <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
            <h2 className="card-title">{id ? "Update Post" : "Add Post"}</h2>
            <FaTimes className="text-danger cursor-pointer" onClick={handleClose} />
          </div>
          <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="name" className="mb-2 block text-sm font-medium text-black dark:text-white">Post Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Post Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="image" className="mb-2 block text-sm font-medium text-black dark:text-white">Upload Image</label>
              <input
                type="file"
                className="form-control"
                id="image"
                accept=".jpg,.jpeg,.png"
                onChange={handleFileChange}
             
              />
              {imagePreview && (
                <div className="mt-3">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "100%", maxHeight: "300px" }}
                  />
                  <button
                        className="btn btn-danger"
                        onClick={handleImageRemove}
                      >
                        Remove
                      </button>
                </div>
              )}
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="description" className="mb-2 block text-sm font-medium text-black dark:text-white">Description</label>
              <input
                type="text"
                className="form-control"
                id="description"
                placeholder="Description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="poll-question" className="mb-2 block text-sm font-medium text-black dark:text-white">Poll Question</label>
              <input
                type="text"
                className="form-control"
                id="poll-question"
                name="question"
                placeholder="Poll Question"
                value={formData.poll.question}
                onChange={handlePollChange}
              />
            </div>
            {formData.poll.options.map((option, index) => (
              <div key={index} className="mb-3 flex items-center gap-2 w-full">
  <div className="flex-grow">
    <label
      htmlFor={`option-${index}`}
      className="mb-2 block text-sm font-medium text-black dark:text-white"
    >
      Option {index + 1}
    </label>
    <input
      type="text"
      className="form-control w-full border border-gray-300 rounded-md p-2 text-sm"
      id={`option-${index}`}
      placeholder={`Option ${index + 1}`}
      value={option.text}
      onChange={(e) => handleOptionChange(index, e)}
    />
  </div>
  <div className="ml-2">
    <button
      type="button"
      className="bg-red-600 text-white py-2 px-4 rounded-md mt-4 hover:bg-red-700 transition duration-300"
      onClick={() => deleteOption(index)}
    >
         <FaTimes />
    </button>
  </div>
</div>

))}

<div>
<button type="button" className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1   mb-3" onClick={addOption}>
              Add Option
            </button><br/>
            <button type="submit" className="ms-2 flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
              {id ? "Update" : "Submit"}
            </button>
</div>
         
          </form>
        </div>
      </div>

    </div>
   </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default AddPostForm;
