import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import messages from "../messages.json";

const AddWalletTransactionForm = () => {
  const { id ,objId} = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    userId: "",
    totalAmount: "",
    kittyTransactions: [{ kittyGroupId: "", spentAmount: "" }],
  });
  const [kittyGroups, setKittyGroups] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch kitty groups from API
  useEffect(() => {
    fetchKittyGroups();
  }, []);

  const fetchKittyGroups = async () => {
    try {
      const response = await axios.get(`${apiUrl}/getAllGroups`);
      setKittyGroups(response.data.data); 
    } catch (error) {
      console.error("Failed to fetch kitty groups:", error);
    }
  };

  // Fetch existing wallet transaction data if updating
  console.log(objId,'oooooo')
  useEffect(() => {
    if (id) {
      fetchWalletTransactionData(id);
    }
  }, [id]);

  const fetchWalletTransactionData = async (userId) => {
    try {
    //   const userIdValue = userId._id; 
    const response = await axios.get(`${apiUrl}/walletTransactions/user/${userId}`);
    console.log(response,"hdiffllllllllllll");
    const savedata = response.data;
    
    if (savedata.length > 0) {
      const formattedTransactions = savedata[0].kittyTransactions.map(transaction => ({
        kittyGroupId: transaction.kittyGroupId?._id,  // Use the _id for select value
        kittyGroupName: transaction.kittyGroupId?.name,  // Store the group name separately if needed
        spentAmount: transaction.spentAmount,
      }));
    
      setFormData({
        totalAmount: savedata[0].totalAmount,
        kittyTransactions: formattedTransactions,
      });
    }
    
    } catch (error) {
      console.error("Failed to fetch transaction data:", error);
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user._id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: user._id,
      }));
    } else {
      console.error("User ID not found in local storage");
    }
  }, []);


  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleKittyTransactionChange = (index, e) => {
    const { name, value } = e.target;
    const updatedKittyTransactions = [...formData.kittyTransactions];
    updatedKittyTransactions[index][name] = value;
    setFormData({ ...formData, kittyTransactions: updatedKittyTransactions });
  };

  const addKittyTransaction = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      kittyTransactions: [...prevFormData.kittyTransactions, { kittyGroupId: "", spentAmount: "" }],
    }));
  };

  const removeKittyTransaction = (index) => {
    const updatedKittyTransactions = [...formData.kittyTransactions];
    updatedKittyTransactions.splice(index, 1);
    setFormData({ ...formData, kittyTransactions: updatedKittyTransactions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        console.log(id,formData,'dsaaaaaaaaaaaa')
        await axios.put(`${apiUrl}/walletTransactions/${objId}`, formData);
        toast.success("Transaction updated successfully", { autoClose: 2000 });
      } else {
        await axios.post(`${apiUrl}/walletTransactions`, formData);
        toast.success(messages.success.dataAdded, { autoClose: 2000 });
      }

      setTimeout(() => {
        navigate("/kitty-walletTransactionHistory");
      }, 1000);
    } catch (error) {
      console.error("Failed to submit wallet transaction:", error);
    }
  };
  const handleClose =()=>{
    navigate("/kitty-walletTransactionHistory")
  }
  console.log(formData,'ffffffffffff')

  return (
    <div className="container mt-4">
     <div className="row">
      <div className="col-md-8 mx-auto">
      <div className="card p-0">
        <div className=" card-body w-full p-0 pb-3">
          <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
          <h2 className="card-title mb-0">{id ? "Update Wallet Transaction" : "Add Wallet Transaction"}</h2>
          <FaTimes className="text-danger cursor-pointer" onClick={handleClose} />
          </div>
          <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
            <div className="mb-3 col-md-12 p-2">
              <label htmlFor="totalAmount" className="mb-2 block text-sm font-medium text-black dark:text-white">Total Amount</label>
              <input
                type="number"
                id="totalAmount"
                className="form-control"
                value={formData.totalAmount}
                onChange={handleChange}
                required
              />
            </div>

            <h5>Kitty Transactions</h5>
            {formData.kittyTransactions?.map((transaction, index) => (
              <div key={index} className="mb-3 col-md-12 p-2">
            
            <div className="flex flex-wrap -mx-2">
  <div className="w-full md:w-6/12 p-2"> {/* Increased width */}
    <label
      htmlFor={`kittyGroupId-${index}`}
      className="mb-2 block text-sm font-medium text-black dark:text-white"
    >
      Group's
    </label>
    <select
      id={`kittyGroupId-${index}`}
      name="kittyGroupId"
      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      value={transaction.kittyGroupId}
      onChange={(e) => handleKittyTransactionChange(index, e)}
      required
    >
      <option value="">Select a group</option>
      {kittyGroups?.map((group) => (
        <option key={group._id} value={group._id}>
          {group.name}
        </option>
      ))}
    </select>
  </div>

  <div className="w-full md:w-6/12 p-2"> {/* Increased width */}
    <label
      htmlFor={`spentAmount-${index}`}
      className="mb-2 block text-sm font-medium text-black dark:text-white"
    >
      Spent Amount
    </label>
    <input
      type="number"
      id={`spentAmount-${index}`}
      name="spentAmount"
      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      value={transaction.spentAmount}
      onChange={(e) => handleKittyTransactionChange(index, e)}
      required
    />
  </div>

  <div className="w-full md:w-auto p-2 flex justify-end items-center"> {/* Button aligned to the right */}
    {index > 0 && (
      <button
        type="button"
        className="btn btn-danger bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-200"
        onClick={() => removeKittyTransaction(index)}
      >
        <FaTimes />
      </button>
    )}
  </div>
</div>



              </div>
            ))}

    <div className="col-md-12">
   
    <button type="button" className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1   mb-3" onClick={addKittyTransaction}>
              Add Kitty Transaction
            </button><br/>

            <button type="submit" className="flex ms-2 justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
              {id ? "Update" : "Submit"}
            </button>
   
    </div>
          </form>
        </div>
      </div>
      </div>
     </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default AddWalletTransactionForm;
