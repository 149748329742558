import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import { RiDeleteBin4Fill } from "react-icons/ri";
import { IoIosEye } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify";
import messages from "../messages.json";
import DeleteConfirmationModal from "../forms/DeleteConformation";
import WalletTransactionInformation from "../forms/walletTransactionInformation";
import CustomTable from "../components/commonComponent/CustomTable";
import { usePermissions } from "../hooks/PermissionsContext";


const WalletTransaction = () => {
  const [WalletTransactions, setWalletTransactions] = useState([]);
  const [filteredWalletTransactions, setFilteredWalletTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [WalletTransactionsPerPage] = useState(10);
  const [selectedWalletTransactions, setSelectedWalletTransactions] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [WalletTransactionToDelete, setWalletTransactionToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { hasPermission } = usePermissions();

  const showView = hasPermission('Wallet', 'View');
  const showAdd = hasPermission('Wallet', 'Add');
  const showEdit = hasPermission('Wallet', 'Update');
  const showDelete = hasPermission('Wallet', 'Delete');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/walletTransactions`);
        const fetchedWalletTransactions = response.data;

        setWalletTransactions(fetchedWalletTransactions);
        setFilteredWalletTransactions(fetchedWalletTransactions); // Ensure this is an array
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filterWalletTransactions();
  }, [searchInput, WalletTransactions,  selectedStatuses]);

  const filterWalletTransactions = () => {
    let filtered = WalletTransactions;

    if (searchInput !== "") {
      filtered = filtered.filter(
        (WalletTransaction) =>
          WalletTransaction.userId.fullname &&
          WalletTransaction.userId.fullname.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    if (selectedStatuses.length > 0) {
      filtered = filtered.filter((WalletTransaction) =>
        selectedStatuses.includes(WalletTransaction.isActive ? "Active" : "Inactive")
      );
    }

    setFilteredWalletTransactions(filtered);
  };

  const handleShowDeleteModal = (WalletTransactionId) => {
    setWalletTransactionToDelete(WalletTransactionId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setWalletTransactionToDelete(null);
  };

  const handleDelete = async () => {
    if (WalletTransactionToDelete) {
      try {
        await axios.delete(`${apiUrl}/walletTransactions/${WalletTransactionToDelete}`);
        setWalletTransactions(WalletTransactions.filter((WalletTransaction) => WalletTransaction._id !== WalletTransactionToDelete));
        toast.success(messages.success.delete, {
          autoClose: 2000,
        });
        setTimeout(() => {
          // navigate('/kitty-users');
        }, 2500);
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setWalletTransactionToDelete(null);
      }
    }
  };

  const handleUpdate = (userId,objId) => {
    console.log(objId,'osodosd')
    navigate(`/kitty-WalletTransaction/update/${userId}/${objId}`);


  };

  const handleStatusToggle = async (WalletTransactionId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await axios.put(`${apiUrl}/walletTransactions/${WalletTransactionId}`, {
        isActive: updatedStatus,
      });

      setWalletTransactions(
        WalletTransactions?.map((WalletTransaction) =>
          WalletTransaction?._id === WalletTransactionId ? { ...WalletTransaction, isActive: updatedStatus } : WalletTransaction
        )
      );
      toast.success(
        `WalletTransaction status updated to ${updatedStatus ? "Active" : "Inactive"}`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const handleView = async () => {
    try {
      const response = await axios.get(`${apiUrl}/walletTransactions`);
      console.log(response,'ooooooooooooo');

      setSelectedWalletTransactions(response.data);
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedWalletTransactions(null);
  };

  const indexOfLastWalletTransaction = currentPage * WalletTransactionsPerPage;
  const indexOfFirstWalletTransaction = indexOfLastWalletTransaction - WalletTransactionsPerPage;
  const currentWalletTransactions = filteredWalletTransactions.slice(indexOfFirstWalletTransaction, indexOfLastWalletTransaction);
  const totalPages = Math.ceil(filteredWalletTransactions.length / WalletTransactionsPerPage);




 

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevState) =>
      prevState.includes(status)
        ? prevState.filter((item) => item !== status)
        : [...prevState, status]
    );
  };
  const columns = [
    { header: 'S.No', accessor: 'id' },
    { header: 'TotalAmount', accessor: 'totalAmount' },
    // { header: 'Email', accessor: 'email' },
    // { header: 'Contact Number', accessor: 'phoneNumber' },
    // { header: 'Location', accessor: 'location' },
    // { header: 'Profession', accessor: 'profession' },
  ]; 

  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">WalletTransaction Information</h2>
        </div>
        <div className="col-auto">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <span className="input-group-text">
              <FaSearch />
            </span>
          </div>
        </div>
        <div className="col-auto">
          

          <div className="dropdown d-inline-block me-2">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1  "
              type="button"
              onClick={() => setShowStatusDropdown(!showStatusDropdown)}
            >
              Status <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showStatusDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Active"
                      id="status-active"
                      checked={selectedStatuses.includes("Active")}
                      onChange={() => handleStatusChange("Active")}
                    />
                    <label className="form-check-label" htmlFor="status-active">
                      Active
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Inactive"
                      id="status-inactive"
                      checked={selectedStatuses.includes("Inactive")}
                      onChange={() => handleStatusChange("Inactive")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="status-inactive"
                    >
                      Inactive
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        {showAdd && (
          
          <div className="col-auto">
          <Link to="/kitty-WalletTransaction/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add WalletTransaction
          </Link>
        </div>
        )}
      </div>
     
      <CustomTable
        tableData={filteredWalletTransactions}
        columns={columns}
        handleStatusToggle={handleStatusToggle}
        showStatus={true}
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView,
          showEdit,
          showDelete,
        }}
      />
      <WalletTransactionInformation
      WalletTransaction={selectedWalletTransactions}
      show={showModal}
      handleClose={handleCloseModal}
      />
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default WalletTransaction;
