import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import messages from "../messages.json";
import DeleteConfirmationModal from "../forms/DeleteConformation";
import PostInformation from "../forms/PostInformation";
import CustomTable from "../components/commonComponent/CustomTable";
import { Toast } from "react-bootstrap";
import { usePermissions } from "../hooks/PermissionsContext";


const Post = () => {
  const [posts, setposts] = useState([]);
  const [filteredposts, setFilteredposts] = useState([]);
  const [selectedposts, setSelectedposts] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [postToDelete, setpostToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { hasPermission } = usePermissions();

  const showView = hasPermission('Posts', 'View');
  const showAdd = hasPermission('Posts', 'Add');
  const showEdit = hasPermission('Posts', 'Update');
  const showDelete = hasPermission('Posts', 'Delete');
  const navigate = useNavigate();
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllPost`);
        const fetchedposts = response.data.data;

        setposts(fetchedposts);
        setFilteredposts(fetchedposts); // Ensure this is an array
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filterposts();
  }, [searchInput, posts, selectedStatuses]);

  const filterposts = () => {
    let filtered = posts;

    if (searchInput !== "") {
      filtered = filtered.filter(
        (post) =>
          post.name &&
          post.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    if (selectedStatuses.length > 0) {
      filtered = filtered.filter((post) =>
        selectedStatuses.includes(post.isActive ? "Active" : "Inactive")
      );
    }

    setFilteredposts(filtered);
  };

  const handleShowDeleteModal = (postId) => {
    setpostToDelete(postId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setpostToDelete(null);
  };

  const handleDelete = async () => {
    if (postToDelete) {
      try {
        await axios.delete(`${apiUrl}/deletePostById/${postToDelete}`);
        setposts(posts.filter((post) => post._id !== postToDelete));
          // Show success toast message
      toast.success(messages.success.delete, {
        autoClose: 2000, // 2 seconds auto-close
      });
    
       
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setpostToDelete(null);
      
      }
    }
  };

  const handleUpdate = (postId) => {
    navigate(`/kitty-post/update/${postId}`);
  };

  const handleStatusToggle = async (postId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await axios.put(`${apiUrl}/updatePostById/${postId}`, {
        isActive: updatedStatus,
      });

      setposts(
        posts.map((post) =>
          post._id === postId ? { ...post, isActive: updatedStatus } : post
        )
      );
      toast.success(
        `post status updated to ${updatedStatus ? "Active" : "Inactive"}`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const handleView = async (postId) => {
    try {
      const response = await axios.get(`${apiUrl}/getPostById/${postId}`);
      setSelectedposts(response.data.data);
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedposts(null);
  };

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevState) =>
      prevState.includes(status)
        ? prevState.filter((item) => item !== status)
        : [...prevState, status]
    );
  };

  const columns = [
    { header: 'S.No', accessor: 'id' },
    { header: 'Name', accessor: 'name' },
  ];  

  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">Post Information</h2>
        </div>

        <div className="col-auto">
          <div className="dropdown d-inline-block me-2">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 "
              type="button"
              onClick={() => setShowStatusDropdown(!showStatusDropdown)}
            >
              Status
              <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showStatusDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Active"
                      id="status-active"
                      checked={selectedStatuses.includes("Active")}
                      onChange={() => handleStatusChange("Active")}
                    />
                    <label className="form-check-label" htmlFor="status-active">
                      Active
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Inactive"
                      id="status-inactive"
                      checked={selectedStatuses.includes("Inactive")}
                      onChange={() => handleStatusChange("Inactive")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="status-inactive"
                    >
                      Inactive
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        {showAdd && (

          <div className="col-auto">
          <Link to="/kitty-post/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add post
          </Link>
        </div>
        )}
      </div>

      <CustomTable
        tableData={filteredposts}
        columns={columns}
        handleStatusToggle={handleStatusToggle}
        showStatus={true}
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView,
          showEdit,
          showDelete,
        }}
      />

      <PostInformation
        post={selectedposts}
        show={showModal}
        handleClose={handleCloseModal}
      />
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default Post;
