import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// We are using specific service from firebase i.e., storage. Get the getStorage function. This function accepts an application
// This fun tells firebase that we are using storage in this application
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyCTVVo65qV1MSGrjLcxFR2pwm5tGMftRaI",
  authDomain: "kittybeeadminpanel.firebaseapp.com",
  projectId: "kittybeeadminpanel",
  storageBucket: "kittybeeadminpanel.appspot.com",
  messagingSenderId: "1044739430584",
  appId: "1:1044739430584:web:055b81103e72d029197c4d",
  measurementId: "G-X3TJ4RZ7MK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
const analytics = getAnalytics(app);