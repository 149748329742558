// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AddGroupForm.css */

.dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
  }
  
  .dropdown-item .form-check {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  
  .dropdown-item .form-check-input {
    margin-right: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/forms/AddGroupForm.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;IACI,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":["/* AddGroupForm.css */\n\n.dropdown-menu {\n    max-height: 200px;\n    overflow-y: auto;\n  }\n  \n  .dropdown-item {\n    display: flex;\n    align-items: center;\n  }\n  \n  .dropdown-item .form-check {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    padding-left: 10px;\n  }\n  \n  .dropdown-item .form-check-input {\n    margin-right: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
