import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import messages from "../messages.json";
import DeleteConfirmationModal from "../forms/DeleteConformation";
import ColorsInformation from "../forms/colorInformation";
import CustomTable from "../components/commonComponent/CustomTable";
import { GetColorName } from 'hex-color-to-color-name';
import { usePermissions } from "../hooks/PermissionsContext";

const Color = () => {
  const [colors, setcolors] = useState([]);
  const [filteredcolors, setFilteredcolors] = useState([]);
  const [selectedcolors, setSelectedcolors] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [colorToDelete, setcolorToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  const showView = hasPermission('Color', 'View');
  const showAdd = hasPermission('Color', 'Add');
  const showEdit = hasPermission('Color', 'Update');
  const showDelete = hasPermission('Color', 'Delete');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllColor`);
        console.log(response, 'API response');
  
        const fetchedcolors = response?.data?.data?.events || [];
        console.log(fetchedcolors, 'API data (fetchedcolors)');
  
        if (Array.isArray(fetchedcolors)) {
          const data = fetchedcolors.map(item => {
            console.log(item, 'Color item');
  
            // Check if the name exists, handle missing names
            const colorname = item?.name ? GetColorName(item?.name) : 'No Color Name';
            console.log(colorname, 'Color name after conversion');
  
            return { ...item, name: colorname };
          });
  
          console.log(data, 'Final mapped data');
          setcolors(data);
          setFilteredcolors(data); // Make sure to set filtered colors as well
        } else {
          console.log('fetchedcolors is not an array');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [apiUrl]);
  
  

  useEffect(() => {
  
    filtercolors();
  }, [searchInput, colors, selectedStatuses]);

  const filtercolors = () => {
    let filtered = colors;

    if (searchInput !== "") {
      filtered = filtered.filter(
        (color) =>
          color?.name &&
          color?.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    if (selectedStatuses?.length > 0) {
      filtered = filtered.filter((color) =>
        selectedStatuses.includes(color.isActive ? "Active" : "Inactive")
      );
    }

    setFilteredcolors(filtered);
  };

  const handleShowDeleteModal = (colorId) => {
    setcolorToDelete(colorId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setcolorToDelete(null);
  };

  const handleDelete = async () => {
    if (colorToDelete) {
      try {
        await axios.delete(`${apiUrl}/deleteColor/${colorToDelete}`);
        setcolors(colors.filter((color) => color._id !== colorToDelete));
        toast.success(messages.success.delete, {
          autoClose: 2000,
        });
        setTimeout(() => {
          // navigate('/kitty-users');
        }, 2500);
      } catch (error) {
        console.error(messages?.error?.errorDeleting, error);
        toast.error(messages?.error?.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setcolorToDelete(null);
      }
    }
  };

  const handleUpdate = (colorId) => {
    navigate(`/kitty-color/update/${colorId}`);
  };

  const handleStatusToggle = async (colorId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await axios.patch(`${apiUrl}/updateColorStatus/${colorId}`, {
        isActive: updatedStatus,
      });

      setcolors(
        colors.map((color) =>
          color?._id === colorId ? { ...color, isActive: updatedStatus } : color
        )
      );
      toast.success(
        `color status updated to ${updatedStatus ? "Active" : "Inactive"}`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const handleView = async (colorId) => {
    try {
      const response = await axios.get(`${apiUrl}/getColorById/${colorId}`);
      setSelectedcolors(response?.data);
      setShowModal(true);
    } catch (error) {
      console.error(messages?.error?.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedcolors(null);
  };

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevState) =>
      prevState.includes(status)
        ? prevState.filter((item) => item !== status)
        : [...prevState, status]
    );
  };

  const columns = [
    { header: "S.No", accessor: "id" },
    { header: "Color Name", accessor: "name" },
  ];

  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">Color Information</h2>
        </div>

        <div className="col-auto">
          <div className="dropdown d-inline-block me-2">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 "
              type="button"
              onClick={() => setShowStatusDropdown(!showStatusDropdown)}
            >
              Status
              <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showStatusDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Active"
                      id="status-active"
                      checked={selectedStatuses.includes("Active")}
                      onChange={() => handleStatusChange("Active")}
                    />
                    <label className="form-check-label" htmlFor="status-active">
                      Active
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Inactive"
                      id="status-inactive"
                      checked={selectedStatuses.includes("Inactive")}
                      onChange={() => handleStatusChange("Inactive")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="status-inactive"
                    >
                      Inactive
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        {showAdd && (

          <div className="col-auto">
          <Link to="/kitty-color/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add color
          </Link>
        </div>
        )}
      </div>
         
            
      <CustomTable
        tableData={filteredcolors}
        columns={columns}
        handleStatusToggle={handleStatusToggle}
        showStatus={true}
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView,
          showEdit,
          showDelete,
        }}
      />

      <ColorsInformation
        color={selectedcolors}
        show={showModal}
        handleClose={handleCloseModal}
      />
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default Color;
