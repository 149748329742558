import React, { useContext } from 'react';
import { AuthContext } from '../auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <header className="fixed top-0 left-0 w-full h-16 bg-white shadow-lg z-50 flex items-center justify-between px-4 md:px-6 2xl:px-10">
      <h1 className="text-black text-4xl font-bold">KittyBee</h1>
      {user && (
        <div className="relative">
          <Dropdown>
            <Dropdown.Toggle as="div" id="dropdown-basic" className="cursor-pointer flex items-center">
              <img
                src={user.image || '/images/defaultProfile.jpg'}
                alt="Profile"
                className="w-10 h-10 rounded-full border-2 border-white"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu className="absolute right-0 mt-2 bg-white shadow-lg rounded-md overflow-hidden border border-gray-300">
              <Dropdown.Item
                onClick={() => navigate('/profile')}
                className="hover:bg-gray-100 transition-colors p-2"
              >
                My Profile
              </Dropdown.Item>
              <Dropdown.Item
                onClick={handleLogout}
                className="hover:bg-gray-100 transition-colors p-2"
              >
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </header>
  );
};

export default Header;
