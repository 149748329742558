import React from 'react';
import PropTypes from 'prop-types';
import './card.css'; // Styles for the Card component

const Card = ({ title, content, className }) => (
  <div className={`card ${className}`}>
    <div className="card-body card-body-custom">
      <h5>{title}</h5>
      {content}
    </div>
  </div>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Card;
