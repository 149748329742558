import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

import UserInformation from "../forms/UserInformation";
import { toast ,ToastContainer} from "react-toastify";
import messages from "../messages.json";

import DeleteConfirmationModal from "../forms/DeleteConformation";
import "./user.css";
import CustomTable from "../components/commonComponent/CustomTable";
import { usePermissions } from "../hooks/PermissionsContext";

const User = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedProfessions, setSelectedProfessions] = useState([]);
  const [showProfessionDropdown, setShowProfessionDropdown] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { hasPermission } = usePermissions();

  const showView = hasPermission('Users', 'View');
  const showAdd = hasPermission('Users', 'Add');
  const showEdit = hasPermission('Users', 'Update');
  const showDelete = hasPermission('Users', 'Delete');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllUsersList`);
        console.log(response,"check response");
        const fullyFilledUsers = response.data.data
     
        console.log(fullyFilledUsers,"testtttttttttisdhfispp");
        setUsers(fullyFilledUsers);
        setFilteredUsers(fullyFilledUsers);
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };
    fetchData();
  }, []);
  

  useEffect(() => {
    filterUsers();
    console.log(selectedProfessions,'dsssss');
    
  }, [searchInput, users, selectedProfessions, selectedStatuses]);

  const filterUsers = () => {
    let filtered = users;
    
    
    if (searchInput !== "") {
      filtered = filtered.filter(
        (user) =>
          user.fullname &&
        user.fullname.toLowerCase().includes(searchInput.toLowerCase())
      );
    }
    
    if (selectedProfessions?.length) {
      filtered = filtered.filter((user) =>
        selectedProfessions.includes(user?.profession)
    );
  }
   console.log(filtered,'ffffffffff')

    if (selectedStatuses?.length > 0) {
      filtered = filtered?.filter((user) =>
        selectedStatuses?.includes(user?.isActive ? "Active" : "Inactive")
      );
    }

    setFilteredUsers(filtered);
  };

  const handleShowDeleteModal = (userId) => {
    setUserToDelete(userId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setUserToDelete(null);
  };

  const handleDelete = async () => {
    if (userToDelete) {
      try {
        await axios.delete(`${apiUrl}/deleteUserById/${userToDelete}`);
        setUsers(users.filter((user) => user._id !== userToDelete));
        toast.success("Deleted successfully", { autoClose: 2000 });
      
          navigate('/kitty-users');
      
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
          pauseOnFocusLoss: false,
        });
        navigate('/kitty-users');
      } finally {
        setShowDeleteModal(false);
        setUserToDelete(null);
      }
    }
  };

  const handleUpdate = (userId) => {
    console.log(userId,'sdddddddddddd')
    navigate(`/kitty-users/update/${userId}`);
  };

  const handleStatusToggle = async (userId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      const response = await axios.patch(`${apiUrl}/updateStatus/${userId}`, {
        isActive: updatedStatus,
      });

      setUsers(
        users.map((user) =>
          user?._id === userId ? { ...user, isActive: updatedStatus } : user
        )
      );
      toast.success(
        `User status updated to ${updatedStatus ? "Active" : "Inactive"}`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const handleView = async (userId) => {
    try {
      const response = await axios.get(`${apiUrl}/getuserById/${userId}`);
      setSelectedUser(response.data.data);
      // console.log(selectedUser,'ooooo')
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  let uniqueProfessions = [...new Set(users.map((user) => user?.profession))];
  uniqueProfessions =uniqueProfessions.filter((item)=> item != undefined)
  console.log(uniqueProfessions,'ddddddddddddddd');
  

  const handleProfessionChange = (profession) => {
    console.log(profession,'pppppp',users)
    setSelectedProfessions((prevState) =>
      prevState.includes(profession)
        ? prevState.filter((item) => item != profession)
        : [...prevState, profession]
    );
  };

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevState) =>
      prevState.includes(status)
        ? prevState.filter((item) => item !== status)
        : [...prevState, status]
    );
  };

  const columns = [
    { header: 'S.No', accessor: 'id' },
    { header: 'Full Name', accessor: 'fullname' },
    { header: 'Email', accessor: 'email' },
    { header: 'Contact Number', accessor: 'phoneNumber' },
    { header: 'Location', accessor: 'location' },
    { header: 'Profession', accessor: 'profession' },
  ];  

  const professionOpen = () => {
    setShowProfessionDropdown(!showProfessionDropdown);
    setShowStatusDropdown(false);
  };
  const statusOpen = () => {
    setShowProfessionDropdown(false);
    setShowStatusDropdown(!showStatusDropdown);
  };
  const close = () => {
    if (showProfessionDropdown) {
      setShowProfessionDropdown(false);
    } else if (showStatusDropdown) {
      setShowStatusDropdown(false);
    }
  };

  return (
    <div onClick={() => close()}>
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto justify-end  mt-2 py-2 w-full max-w-7xl mx-auto ">
        <div className="col-auto">
          <div className="dropdown d-inline-block me-2">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1"
              type="button"
              onClick={() => professionOpen()}
            >
              Profession
              <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showProfessionDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                {uniqueProfessions.map((profession, index) => (
                  <li key={index}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={profession}
                        id={`profession-${index}`}
                        checked={selectedProfessions.includes(profession)}
                        onChange={() => handleProfessionChange(profession)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`profession-${index}`}
                      >
                        {profession}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="dropdown d-inline-block">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1"
              type="button"
              onClick={() => statusOpen()}            >
              Status
              <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showStatusDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Active"
                      id="status-active"
                      checked={selectedStatuses.includes("Active")}
                      onChange={() => handleStatusChange("Active")}
                    />
                    <label className="form-check-label" htmlFor="status-active">
                      Active
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Inactive"
                      id="status-inactive"
                      checked={selectedStatuses.includes("Inactive")}
                      onChange={() => handleStatusChange("Inactive")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="status-inactive"
                    >
                      Inactive
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        {showAdd && (

        <div className="col-auto">
          <Link to="/kitty-users/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add User
          </Link>
        </div>
        )}
      </div>

      <CustomTable
        tableData={filteredUsers}
        columns={columns}
        handleStatusToggle={handleStatusToggle}
        showStatus={true}
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView,
          showEdit,
          showDelete,
        }}
      />

      <UserInformation
        user={selectedUser}
        show={showModal}
        handleClose={handleCloseModal}
      />
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
      <ToastContainer autoClose={2000}/>
    </div>
    </div>
  );
};

export default User;

