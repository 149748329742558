import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const CityInformation = ({ show, handleClose, city }) => {
    console.log(city,"hfjkdsfhksdflaaaaaaaaaaaaa");
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>City Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        {city ? (
          <div>
            <p>
              <strong>Name:</strong> {city.name}
            </p>
         
            <p>
              <strong>Status:</strong> {city.isActive ? "Active" : "Inactive"}
            </p>

           
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CityInformation;
