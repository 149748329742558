import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import messages from "../messages.json";
import DeleteConfirmationModal from "../forms/DeleteConformation";
import CityInformation from "../forms/cityInformation";
import CustomTable from "../components/commonComponent/CustomTable";

const City = () => {
  const [cities, setcities] = useState([]);
  const [filteredcities, setFilteredcities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [citiesPerPage] = useState(10);
  const [selectedcities, setSelectedcities] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [cityToDelete, setcityToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllCities`);
        const fetchedcities = response.data.cities;

        setcities(fetchedcities);
        setFilteredcities(fetchedcities); // Ensure this is an array
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filtercities();
  }, [searchInput, cities, selectedStatuses]);

  const filtercities = () => {
    let filtered = cities;

    if (searchInput !== "") {
      filtered = filtered.filter(
        (city) =>
          city.name &&
          city.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    if (selectedStatuses.length > 0) {
      filtered = filtered.filter((city) =>
        selectedStatuses.includes(city.isActive ? "Active" : "Inactive")
      );
    }

    setFilteredcities(filtered);
  };

  const handleShowDeleteModal = (cityId) => {
    setcityToDelete(cityId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setcityToDelete(null);
  };

  const handleDelete = async () => {
    if (cityToDelete) {
      try {
        await axios.delete(`${apiUrl}/deleteCity/${cityToDelete}`);
        setcities(cities.filter((city) => city._id !== cityToDelete));
        toast.success(messages.success.delete, {
          autoClose: 2000,
        });
        setTimeout(() => {
          // navigate('/kitty-users');
        }, 2500);
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setcityToDelete(null);
      }
    }
  };

  const handleUpdate = (cityId) => {
    navigate(`/kitty-city/update/${cityId}`);
  };

  const handleStatusToggle = async (cityId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await axios.put(`${apiUrl}/updateCity/${cityId}`, {
        isActive: updatedStatus,
      });

      setcities(
        cities.map((city) =>
          city._id === cityId ? { ...city, isActive: updatedStatus } : city
        )
      );
      toast.success(
        `city status updated to ${updatedStatus ? "Active" : "Inactive"}`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const handleView = async (cityId) => {
    try {
      const response = await axios.get(`${apiUrl}/getCityById/${cityId}`);
      console.log(response,"dfhidsgfusifsdfuw");
      setSelectedcities(response.data.city);
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedcities(null);
  };

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevState) =>
      prevState.includes(status)
        ? prevState.filter((item) => item !== status)
        : [...prevState, status]
    );
  };

  const columns = [
    { header: "S.No", accessor: "id" },
    { header: "Name", accessor: "name" },
  ];

  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">City Information</h2>
        </div>

        <div className="col-auto">
          <div className="dropdown d-inline-block me-2">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1"
              type="button"
              onClick={() => setShowStatusDropdown(!showStatusDropdown)}
            >
              Status
              <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showStatusDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Active"
                      id="status-active"
                      checked={selectedStatuses.includes("Active")}
                      onChange={() => handleStatusChange("Active")}
                    />
                    <label className="form-check-label" htmlFor="status-active">
                      Active
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Inactive"
                      id="status-inactive"
                      checked={selectedStatuses.includes("Inactive")}
                      onChange={() => handleStatusChange("Inactive")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="status-inactive"
                    >
                      Inactive
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="col-auto">
          <Link to="/kitty-city/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add city
          </Link>
        </div>
      </div>

      <CustomTable
        tableData={filteredcities}
        columns={columns}
        handleStatusToggle={handleStatusToggle}
        showStatus={true}
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView: true,
          showEdit: true,
          showDelete: true,
        }}
      />

      <CityInformation
        city={selectedcities}
        show={showModal}
        handleClose={handleCloseModal}
      />
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default City;
