import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true)
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const loggedUser = localStorage.getItem('user');
    const authToken = localStorage.getItem('token');
    if (loggedUser && authToken) {
      setUser(JSON.parse(loggedUser));
      setToken(authToken);
      axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    }

    setLoading(false)
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${apiUrl}/login`, { email, password });
      const { token, user } = response.data;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      setUser(user);
      setToken(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return true;
    } catch (error) {
      console.error('Login failed', error);  
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('roleData');

    setUser(null);
    setToken(null);
    delete axios.defaults.headers.common['Authorization'];
  };

  const isAuthenticated = !!user;

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated, setUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
