import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import messages from "../messages.json";
import DeleteConfirmationModal from "../forms/DeleteConformation";
import AddressInformation from "../forms/addressInformation";
import CustomTable from "../components/commonComponent/CustomTable";

const Address = () => {
  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [AddressToDelete, setAddressToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllAddresses`);
        const fetchedActivities = response.data;

        setActivities(fetchedActivities);
        setFilteredActivities(fetchedActivities); // Ensure this is an array
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filterAddress();
  }, [searchInput, activities, selectedStatuses]);

  const filterAddress = () => {
    let filtered = activities;

    if (searchInput !== "") {
      filtered = filtered.filter(
        (Address) =>
          Address.name &&
          Address.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    if (selectedStatuses.length > 0) {
      filtered = filtered.filter((Address) =>
        selectedStatuses.includes(Address.isActive ? "Active" : "Inactive")
      );
    }

    setFilteredActivities(filtered);
  };

  const handleShowDeleteModal = (AddressId) => {
    setAddressToDelete(AddressId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setAddressToDelete(null);
  };

  const handleDelete = async () => {
    if (AddressToDelete) {
      try {
        await axios.delete(`${apiUrl}/deleteAddressById/${AddressToDelete}`);
        setActivities(
          activities.filter((Address) => Address._id !== AddressToDelete)
        );
        toast.success(messages.success.delete, {
          autoClose: 1500,
        });
        setTimeout(() => {
          // navigate('/kitty-users');
        }, 2500);
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setAddressToDelete(null);
      }
    }
  };

  const handleUpdate = (AddressId) => {
    navigate(`/kitty-Address/update/${AddressId}`);
  };

  const handleStatusToggle = async (AddressId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await axios.put(`${apiUrl}/updateAddressById/${AddressId}`, {
        isActive: updatedStatus,
      });

      setActivities(
        activities.map((Address) =>
          Address._id === AddressId
            ? { ...Address, isActive: updatedStatus }
            : Address
        )
      );
      toast.success(
        `Address status updated to ${updatedStatus ? "Active" : "Inactive"}`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const handleView = async (AddressId) => {
    try {
      const response = await axios.get(`${apiUrl}/getAddressById/${AddressId}`);
      setSelectedActivities(response.data);
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedActivities(null);
  };

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevState) =>
      prevState.includes(status)
        ? prevState.filter((item) => item !== status)
        : [...prevState, status]
    );
  };

  const columns = [
    { header: "S.No", accessor: "id" },
    { header: "Address", accessor: "address" },
    { header: "Title", accessor: "title" },
  ];

  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">Address Information</h2>
        </div>
        <div className="col-auto">
          <div className="dropdown d-inline-block me-2">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1   "
              type="button"
              onClick={() => setShowStatusDropdown(!showStatusDropdown)}
            >
              Status
              <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showStatusDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Active"
                      id="status-active"
                      checked={selectedStatuses.includes("Active")}
                      onChange={() => handleStatusChange("Active")}
                    />
                    <label className="form-check-label" htmlFor="status-active">
                      Active
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Inactive"
                      id="status-inactive"
                      checked={selectedStatuses.includes("Inactive")}
                      onChange={() => handleStatusChange("Inactive")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="status-inactive"
                    >
                      Inactive
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="col-auto">
          <Link to="/kitty-Address/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add Address
          </Link>
        </div>
      </div>

      <CustomTable
        tableData={filteredActivities}
        columns={columns}
        handleStatusToggle={handleStatusToggle}
        showStatus={true}
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView: true,
          showEdit: true,
          showDelete: true,
        }}
      />

      <AddressInformation
        Address={selectedActivities}
        show={showModal}
        handleClose={handleCloseModal}
      />
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default Address;
