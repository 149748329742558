import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../auth/AuthContext';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import styled from 'styled-components';
import messages from '../messages.json'
// Styled component for profile image
const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
`;

const Profile = () => {
  const { user, setUser } = useContext(AuthContext);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getuserById/${user._id}`);
        const { fullname, email, image } = response.data.data;
        setName(fullname);
        setEmail(email);
        setImageUrl(image); // Set the fetched profile image URL
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };

    fetchUserData();
  }, [user._id]);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image) return;

    const storageRef = ref(storage, `profile-images/${user._id}/${image.name}`);
    console.log(storageRef);

    try {
      await uploadBytes(storageRef, image);
      const newImageUrl = await getDownloadURL(storageRef);
      console.log("Image URL:", newImageUrl);

      await axios.put(`${apiUrl}/users/${user._id}`, { image: newImageUrl });
      setUser((prevUser) => ({ ...prevUser, image: newImageUrl }));
      setImageUrl(newImageUrl); // Update the local state with the new image URL

      alert(messages.success.update);
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      alert(messages.error.errorUpdating);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-lg min-w-[600px]">
    <h1 className="text-2xl font-bold mb-6 text-gray-800">My Profile</h1>
    <form onSubmit={handleSubmit}>
      {/* Name Field */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">Name</label>
        <input
          type="text"
          value={name}
          readOnly
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>
  
      {/* Email Field */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
        <input
          type="email"
          value={email}
          readOnly
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>
  
      {/* Profile Image Upload */}
      <div className="mb-4">
        {imageUrl && (
          <div className="mb-4">
            <img
              src={imageUrl}
              alt="Profile"
              className="h-24 w-24 object-cover rounded-full mx-auto border-2 border-gray-300"
            />
          </div>
        )}
        <label className="block text-sm font-medium text-gray-700 mb-2">Upload Profile Image</label>
        <input
          type="file"
          onChange={handleImageChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>
  
      {/* Submit Button */}
      <div className="mt-6">
        <button
          type="submit"
          className="w-full flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90"
        >
          Update Profile Image
        </button>
      </div>
    </form>
  </div>
  
  );
};

export default Profile;
