import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { DatePicker, TimePicker } from "antd";
import { FaTimes } from "react-icons/fa";
import messages from "../messages.json";
import moment from "moment/moment";
import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";
import { GetColorName } from "hex-color-to-color-name";

const AddKittyForm = () => {
  const { id } = useParams();
  const [selectedActivities, setSelectedActivities] = useState([]);

  const [formData, setFormData] = useState({
    image: "",
    userId: "",
    theamepoll: { question: "", options: [""] },
    locationpoll: { question: "", options: [""] },
    venuepoll: { question: "", options: [""] },
    instructions: "",
    themeId: "",
    colorId: "",
    venueId: "",
    activityId: [],
    templateId: "",
    groupId: [],
    name: "",
    date: null,
    time: null,
  });
  const [themes, setThemes] = useState([]);
  const [color, setColor] = useState([]);
  const [venues, setVenues] = useState([]);
  const [activity, setActivity] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [groups, setGroups] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchGroups();
    if (id) {
      fetchKittyInfoData(id);
      console.log(formData.date, "dateeeeeeeeeeeee");
    }
  }, [id]);

  const fetchGroups = async () => {
    try {
      const [
        groupResponse,
        themeResponse,
        colorResponse,
        venueResponse,
        activityResponse,
        templateResponse,
      ] = await Promise.all([
        axios.get(`${apiUrl}/getAllGroups`),
        axios.get(`${apiUrl}/getAllThemes`),
        axios.get(`${apiUrl}/getAllColor`),
        axios.get(`${apiUrl}/getAllVenues`),
        axios.get(`${apiUrl}/getAllActivity`),
        axios.get(`${apiUrl}/getAllTemplates`),
      ]);

      setGroups(groupResponse.data.data);
      setThemes(themeResponse.data.data.events);

      //for color convert

      setColor(colorResponse.data.data.events);
      console.log(colorResponse.data.data.events, "check colorrrrrr");

      const data = colorResponse.data.data.events.map((item) => {
        let colorname = GetColorName(item.name);
        console.log(colorname, "coooooooooooooooo");
        return { ...item, name: colorname };
      });
      setColor(data);

      setVenues(venueResponse.data.data);
      setActivity(activityResponse.data.data.events);
      setTemplates(templateResponse.data.data);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const fetchKittyInfoData = async (kittyId) => {
    try {
      const { data } = await axios.get(`${apiUrl}/getKittyById/${kittyId}`);
      const kittyInfo = data;

      setFormData({
        // groupId: kittyInfo.data.groupId?.[0]?._id || "",  // Handle array for groupId
        name: kittyInfo.data.name || "",
        userId: kittyInfo.data.userId?._id || "", // Ensure userId is set correctly
        image: kittyInfo.data.image || "",
        colorId: kittyInfo.data.colorId?._id || "", // Handle colorId object
        themeId: Array.isArray(kittyInfo.data.themeId)
          ? kittyInfo.data.themeId.map((theme) => theme._id)
          : kittyInfo.data.themeId._id, // Ensure themeId is mapped correctly

        venueId: Array.isArray(kittyInfo.data.venueId)
          ? kittyInfo.data.venueId.map((venue) => venue._id)
          : kittyInfo.data.venueId._id,

        activityId: Array.isArray(kittyInfo.data.activityId)
          ? kittyInfo.data.activityId.map((activity) => activity._id)
          : [kittyInfo.data.activityId._id],

        groupId: Array.isArray(kittyInfo.data.groupId)
          ? kittyInfo.data.groupId.map((group) => group._id)
          : [kittyInfo.data.groupId._id],

        templateId: Array.isArray(kittyInfo.data.templateId)
          ? kittyInfo.data.templateId.map((template) => template._id)
          : kittyInfo.data.templateId._id,

        date: kittyInfo.data.date, // Ensure proper date conversion
        time: kittyInfo.data.time, // Ensure proper time conversion
        instructions: kittyInfo.data.instructions || "",
        theamepoll: kittyInfo.data.theamepoll || {
          question: "",
          options: [""],
        },
        locationpoll: kittyInfo.data.locationpoll || {
          question: "",
          options: [""],
        },
        venuepoll: kittyInfo.data.venuepoll || { question: "", options: [""] },
      });

      setImagePreview(kittyInfo.data.image);
      setImageUrl(kittyInfo.data.image);
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      // Add the checked activity to the selectedActivities array
      setSelectedActivities([...selectedActivities, value]);
    } else {
      // Remove the unchecked activity from the selectedActivities array
      setSelectedActivities(
        selectedActivities.filter((activityId) => activityId !== value)
      );
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user._id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: user?._id,
      }));
    } else {
      console.error("User ID not found in local storage");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let uploadedImageUrl = imageUrl;

    if (formData?.image) {
      try {
        const file = formData?.image;
        const fileExtension = file?.name?.split(".")?.pop();
        const fileName = `${uuidv4()}.${fileExtension}`;

        // Initialize the S3 Client
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
          region: "ap-south-1",
        });

        const s3 = new AWS.S3();
        const params = {
          Bucket: "kittybee",
          Key: `template/${fileName}`,
          Body: file,
          ContentType: file.type,
        };

        // Upload the file
        const data = await s3.upload(params).promise();
        uploadedImageUrl = data.Location;
        setImageUrl(uploadedImageUrl); // Update image URL state
      } catch (err) {
        console.error("Error uploading file:", err);
        toast.error("Failed to upload image", { autoClose: 2000 });
        return;
      }
    }

    try {
      const formatPollData = (poll) => ({
        question: poll.question,
        options: poll.options.map((option) => ({
          optionText: option,
          votes: 0, // Initialize with 0 votes if necessary
        })),
        type: poll.type, // Ensure type is present
      });

      console.log(formData, "dddddddddddddddddddddddddda");
      const formattedData = {
        ...formData,
        image: uploadedImageUrl,
        date: formData.date,
        time: formData.time,
        theamepoll: formatPollData(formData.theamepoll),
        locationpoll: formatPollData(formData.locationpoll),
        venuepoll: formatPollData(formData.venuepoll),
      };

      if (id) {
        await axios.put(`${apiUrl}/updateKitty/${id}`, formattedData);
        toast.success(messages.success.update, { autoClose: 2000 });
      } else {
        await axios.post(`${apiUrl}/addKitty`, formattedData);
        toast.success(messages.success.dataAdded, { autoClose: 2000 });
      }
      setImagePreview(null);
      setImageUrl(null);

      setFormData({
        image: "",
        userId: "",
        theamepoll: { question: "", options: [""] },
        locationpoll: { question: "", options: [""] },
        venuepoll: { question: "", options: [""] },
        instructions: "",
        themeId: "",
        colorId: "",
        venueId: "",
        activityId: [],
        templateId: "",
        groupId: [],
        name: "",
        date: null,
        time: null,
      });

      setTimeout(() => navigate("/kitty-info"), 1000);
    } catch (error) {
      toast.error(error.response.data.error, { autoClose: 2000 });
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [id]: value }));
  };

  const handleDateTimeChange = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value
        ? key === "date"
          ? value.format("DD/MM/YYYY")
          : value.format("HH:mm")
        : null, // Properly format time
    }));
    console.log(value, "daaaaaaaa", key);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({ ...prevFormData, image: file }));
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = () => {
    setFormData((prevFormData) => ({ ...prevFormData, image: null }));
    setImagePreview(null);
    setImageUrl(null);
  };

  const handleOptionChange = (pollType, index, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [pollType]: {
        ...prevFormData[pollType],
        options: prevFormData[pollType].options.map((option, idx) =>
          idx === index ? value : option
        ),
      },
    }));
  };

  const handleAddOption = (pollType) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [pollType]: {
        ...prevFormData[pollType],
        options: [...prevFormData[pollType].options, ""],
      },
    }));
  };
  const handleActivityChange = (e, activityId) => {
    const { checked } = e.target;
    if (checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        activityId: [...prevFormData.activityId, activityId.toString()],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        activityId: prevFormData.activityId.filter(
          (id) => id !== activityId.toString()
        ),
      }));
    }
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="card p-0">
            <div className="  card-body w-full p-0 pb-3">
              <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
                <h2 className="card-title mb-0">
                  {id ? "Update Kitty" : "Add Kitty"}
                </h2>
                <FaTimes
                  className="text-danger cursor-pointer"
                  onClick={() => navigate("/kitty-info")}
                />
              </div>
              <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
                {[
                  { label: "Theme", field: "themeId", options: themes },
                  { label: "Color", field: "colorId", options: color },
                  { label: "Venue", field: "venueId", options: venues },
                  // { label: "Activity", field: "activityId", options: activity },
                  {
                    label: "Template",
                    field: "templateId",
                    options: templates,
                  },
                ].map(({ label, field, options }) => (
                  <div className="mb-3 col-md-6 p-2" key={field}>
                    <label className="mb-2 block text-sm font-medium text-black dark:text-white">
                      {label}
                    </label>
                    <select
                      id={field}
                      className="form-select"
                      value={formData[field]}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select {label}</option>
                      {options.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.name || option.type || option.title}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}

                <div className="mb-3 col-md-6 p-2">
                  <label className="mb-2 block text-sm font-medium text-black dark:text-white">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    value={formData.name}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="mb-3 col-md-6 p-2">
                  <label className="mb-2 block text-sm font-medium text-black dark:text-white">
                    Activity <span className="text-danger">*</span>{" "}
                  </label>
                  <div className="dropdown" aria-haspopup="true">
                    <button
                      className="text-left flex rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1  w-100"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Select Activities
                      <svg
                        class="ml-2 -mr-1 h-3 w-3 mt-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </button>
                    <ul
                      className="dropdown-menu w-100 px-[10px]"
                      aria-labelledby="dropdownMenuButton"
                      required

                    >
                      {activity.map((option) => (
                        <li key={option._id} className="dropdown-item">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={option._id}
                              id={`activity-${option._id}`}
                              onChange={(e) =>
                                handleActivityChange(e, option._id)
                              } // Pass event and activity ID
                              checked={formData.activityId.includes(
                                option._id.toString()
                              )} // Check if activity is already selected
                              
                            />

                            <label
                              className="form-check-label"
                              htmlFor={`activity-${option._id}`}
                            >
                              {option.name}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                    {formData.activityId.length === 0 && (
                    <div className="text-danger mt-2">
                      At least one activity must be selected.
                    </div>
                  )}
                  </div>
                </div>

                <div className="mb-3 col-md-6 p-2">
                  <label
                    htmlFor="groupId"
                    className="mb-2 block text-sm font-medium text-black dark:text-white"
                  >
                    Select Group
                  </label>
                  <select
                    className="form-select"
                    id="groupId"
                    value={formData.groupId}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Group</option>
                    {groups.map((group) => (
                      <option key={group._id} value={group._id}>
                        {group.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3 col-md-6 p-2">
                  <label className="mb-2 block text-sm font-medium text-black dark:text-white">
                    Date
                  </label>
                  <DatePicker
                    onChange={(date) => handleDateTimeChange("date", date)}
                    value={
                      formData.date ? moment(formData.date, "DD/MM/YYYY") : null
                    }
                    format="YYYY-MM-DD"
                    className="form-control"
                    required
                  />
                </div>

                <div className="mb-3 col-md-6 p-2">
                  <label className="mb-2 block text-sm font-medium text-black dark:text-white">
                    Time
                  </label>
                  <TimePicker
                    onChange={(time) => handleDateTimeChange("time", time)}
                    value={
                      formData.time ? moment(formData.time, "HH:mm ") : null
                    } // Handle time formatting
                    format="HH:mm A"
                    className="form-control"
                    required
                  />
                </div>

                <div className="mb-3 col-md-6 p-2">
                  <label className="mb-2 block text-sm font-medium text-black dark:text-white">
                    Instructions
                  </label>
                  <textarea
                    id="instructions"
                    className="form-control"
                    rows="4"
                    value={formData.instructions}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3 col-md-6 p-2">
                  <label className="mb-2 block text-sm font-medium text-black dark:text-white">
                    Upload Image
                  </label>
                  <input
                    type="file"
                    accept=".jpg,.jpeg"
                    className="form-control"
                    onChange={handleFileChange}
                    required
                  />
                  {imagePreview && (
                    <div className="mt-2">
                      <img
                        src={imagePreview}
                        alt="preview"
                        width="150"
                        height="150"
                      />
                      <button
                        className="btn btn-danger"
                        onClick={handleImageRemove}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>

                {["theamepoll", "locationpoll", "venuepoll"].map((pollType) => (
                  <div className="mb-3 col-md-6 p-2" key={pollType}>
                    <label className="mb-2 block text-sm font-medium text-black dark:text-white">
                      Poll Question for {pollType}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={formData[pollType].question}
                      onChange={(e) =>
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          [pollType]: {
                            ...prevFormData[pollType],
                            question: e.target.value,
                          },
                        }))
                      }
                    />
                    <label className="mb-2 block text-sm font-medium text-black dark:text-white">
                      Options:
                    </label>
                    {formData[pollType].options.map((option, idx) => (
                      <div key={idx} className="d-flex align-items-center mb-2">
                        <input
                          type="text"
                          className="form-control"
                          value={option.optionText}
                          onChange={(e) =>
                            handleOptionChange(pollType, idx, e.target.value)
                          }
                        />
                      </div>
                    ))}
                    <button
                      type="button"
                      className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1  "
                      onClick={() => handleAddOption(pollType)}
                    >
                      Add Option
                    </button>
                  </div>
                ))}

                <div className="d-grid mt-4">
                  <button
                    type="submit"
                    className="flex ms-2 justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                  >
                    {id ? "Update" : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddKittyForm;
