import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

const VenueInformation = ({ show, onClose, venue }) => {
  const [venueCategory, setVenueCategory] = useState([]);
  const [venueType, setVenuetype] = useState([]);
  const [cities, setCities] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchVenueList = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllVenueCategories`);
        setVenueCategory(response.data); 
        console.log(response.data);
      } catch (error) {
        console.log("Error fetching venue category", error);
      }
    };

    fetchVenueList();
  }, [apiUrl]);

  useEffect(() => {
    const fetchCitiesList = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllCities`);
        setCities(response.data.cities); 
        console.log(response.data.cities);
      } catch (error) {
        console.log("Error fetching cities", error);
      }
    };

    fetchCitiesList();
  }, [apiUrl]);

  useEffect(() => {
    const fetchVenuetypeList = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllVenueTypes`);
        console.log(response,'testttttt');
        setVenuetype(response.data.data); 
        console.log(response.data.data);
      } catch (error) {
        console.log("Error fetching venue type", error);
      }
    };

    fetchVenuetypeList();
  }, [apiUrl]);

  const getCategoryNameById = (venueCatId) => {
    const venueCat = venueCategory.find((cat) => cat._id === venueCatId);
    return venueCat ? venueCat.name : 'Unknown Category';
  };

  const getCitiesNameById = (cityId) => {
    const city = cities.find((City) => City._id === cityId);
    return city ? city.name : 'Unknown city';
  };

  const getVenuetypeById = (venueTypeId) => {
    const venuetype = venueType.find((Vtype) => Vtype._id === venueTypeId);
    return venuetype ? venuetype.type : 'Unknown venuetype';
  };

  return (
    <Modal show={show} onHide={onClose} className="modal-lg modal-dialog-centered">
  <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-7xl mx-auto">
    <Modal.Header className="bg-[#0e121a] text-white border-b border-gray-200">
      <Modal.Title className="text-xl font-semibold">Venue Information</Modal.Title>
      <Button variant="close" onClick={onClose} className="text-white hover:text-gray-400">
        &times;
      </Button>
    </Modal.Header>

    <Modal.Body className="p-6">
      {venue ? (
        <div className="flex flex-wrap ">
          <div className="w-1/2">
            <p className="text-gray-700 mb-[10px]"><strong>Name:</strong> <span className="text-gray-500 font-medium">{venue.name}</span></p>
            <p className="text-gray-700 mb-[10px]"><strong>Location:</strong> <span className="text-gray-500">{venue.location}</span></p>
            <p className="text-gray-700 mb-[10px]"><strong>Longitude:</strong> <span className="text-gray-500">{venue.long}</span></p>
            <p className="text-gray-700 mb-[10px]"><strong>Latitude:</strong> <span className="text-gray-500">{venue.lat}</span></p>
            <p className="text-gray-700 mb-[10px]"><strong>Contact No:</strong> <span className="text-gray-500">{venue.contactNo}</span></p>
            <p className="text-gray-700 mb-[10px]"><strong>Price Range:</strong> <span className="text-gray-500">{venue.pricing}</span></p>
            <p className="text-gray-700 mb-[10px]"><strong>Status:</strong> <span className={`${venue.isActive ? 'text-green-500' : 'text-red-500'}`}>{venue.isActive ? 'Active' : 'Inactive'}</span></p>
          </div>

          <div className="w-1/2">
            <p className="text-gray-700 mb-[10px]"><strong>Image:</strong></p>
            <img 
              src={venue.image} 
              alt={venue.name} 
              className="w-1/2 max-h-64 object-cover rounded-md shadow-md mb-4"
            />
            <p className="text-gray-700 mb-[10px]"><strong>City:</strong></p>
            <ul className="list-disc ml-4 text-gray-500">
              {venue.cityId ? <li>{getCitiesNameById(venue.cityId)}</li> : <li>No cities</li>}
            </ul>

            <p className="text-gray-700 mb-[10px]"><strong>Venue Category:</strong></p>
            <ul className="list-disc ml-4 text-gray-500">
              {venue.venueCatId ? <li>{getCategoryNameById(venue.venueCatId)}</li> : <li>No category</li>}
            </ul>

            <p className="text-gray-700 mb-[10px]"><strong>Venue Type:</strong></p>
            <ul className="list-disc ml-4 text-gray-500">
              {venue.venueTypeId ? <li>{getVenuetypeById(venue.venueTypeId)}</li> : <li>No venue type</li>}
            </ul>
          </div>
        </div>
      ) : (
        <p className="text-gray-600">Loading...</p>
      )}
    </Modal.Body>

    <Modal.Footer className="bg-gray-100 p-3">
      <Button variant="secondary" onClick={onClose} className="bg-gray-600 text-white hover:bg-gray-700 transition duration-200">
        Close
      </Button>
    </Modal.Footer>
  </div>
</Modal>

  );
};

export default VenueInformation;
