import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import messages from "../messages.json";
import DeleteConfirmationModal from "../forms/DeleteConformation";
import ThemesInformation from "../forms/ThemesInformation";
import CustomTable from "../components/commonComponent/CustomTable";
import { usePermissions } from "../hooks/PermissionsContext";

const Themes = () => {
  const [themes, setThemes] = useState([]);
  const [filteredThemes, setFilteredThemes] = useState([]);
  const [selectedThemes, setSelectedThemes] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [themeToDelete, setThemeToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  const showView = hasPermission('Themes', 'View');
  const showAdd = hasPermission('Themes', 'Add');
  const showEdit = hasPermission('Themes', 'Update');
  const showDelete = hasPermission('Themes', 'Delete');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllThemes`);
        const fetchedThemes = response.data.data.events;

        setThemes(fetchedThemes);
        setFilteredThemes(fetchedThemes); // Ensure this is an array
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filterThemes();
  }, [searchInput, themes, selectedStatuses]);

  const filterThemes = () => {
    let filtered = themes;

    if (searchInput !== "") {
      filtered = filtered.filter(
        (theme) =>
          theme.name &&
          theme.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    if (selectedStatuses.length > 0) {
      filtered = filtered.filter((theme) =>
        selectedStatuses.includes(theme.isActive ? "Active" : "Inactive")
      );
    }

    setFilteredThemes(filtered);
  };

  const handleShowDeleteModal = (themeId) => {
    setThemeToDelete(themeId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setThemeToDelete(null);
  };

  const handleDelete = async () => {
    if (themeToDelete) {
      try {
        await axios.delete(`${apiUrl}/deleteThemes/${themeToDelete}`);
        setThemes(themes.filter((theme) => theme._id !== themeToDelete));
        toast.success(messages.success.delete, {
          autoClose: 2000,
        });
        setTimeout(() => {
          // navigate('/kitty-users');
        }, 2500);
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setThemeToDelete(null);
      }
    }
  };

  const handleUpdate = (themeId) => {
    navigate(`/kitty-theme/update/${themeId}`);
  };

  const handleStatusToggle = async (themeId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await axios.patch(`${apiUrl}/updateThemesStatus/${themeId}`, {
        isActive: updatedStatus,
      });

      setThemes(
        themes.map((theme) =>
          theme._id === themeId ? { ...theme, isActive: updatedStatus } : theme
        )
      );
      toast.success(
        `Theme status updated to ${updatedStatus ? "Active" : "Inactive"}`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const handleView = async (themeId) => {
    try {
      const response = await axios.get(`${apiUrl}/getThemesById/${themeId}`);
      setSelectedThemes(response.data);
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedThemes(null);
  };

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevState) =>
      prevState.includes(status)
        ? prevState.filter((item) => item !== status)
        : [...prevState, status]
    );
  };

  const columns = [
    { header: 'S.No', accessor: 'id' },
    { header: 'Name', accessor: 'name' },
  ];  

  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">Theme Information</h2>
        </div>
        <div className="col-auto">
          <div className="dropdown d-inline-block me-2">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1"
              type="button"
              onClick={() => setShowStatusDropdown(!showStatusDropdown)}
            >
              Status
              <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showStatusDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Active"
                      id="status-active"
                      checked={selectedStatuses.includes("Active")}
                      onChange={() => handleStatusChange("Active")}
                    />
                    <label className="form-check-label" htmlFor="status-active">
                      Active
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Inactive"
                      id="status-inactive"
                      checked={selectedStatuses.includes("Inactive")}
                      onChange={() => handleStatusChange("Inactive")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="status-inactive"
                    >
                      Inactive
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        {showAdd &&(


          <div className="col-auto">
          <Link to="/kitty-theme/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add Theme
          </Link>
        </div>
        )}
      </div>

      <CustomTable
        tableData={filteredThemes}
        columns={columns}
        handleStatusToggle={handleStatusToggle}
        showStatus={true}
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView,
          showEdit,
          showDelete,
        }}
      />

      <ThemesInformation
        theme={selectedThemes}
        show={showModal}
        handleClose={handleCloseModal}
      />
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default Themes;
