import React from "react";
import { useState, useMemo, useEffect } from "react";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";

const CustomTable = ({
  tableData,
  handleStatusToggle,
  showStatus = false,
  handleShowDeleteModal,
  handleView,
  handleUpdate,
  columns,
  actions = {},
}) => {
  const [rowsLimit] = useState(10);

  const [searchValue, setSearchValue] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [productList, setProductList] = useState(tableData);
  const [customPagination, setCustomPagination] = useState([]);
  const [activeColumn, setActiveColumn] = useState(["Price"]);
  const [sortingColumn, setSortingColumn] = useState(["Price"]);

  const [totalPage, setTotalPage] = useState(
    Math.ceil(productList?.length / rowsLimit)
  );

  useEffect(() => {
    setProductList(tableData);
    setCurrentPage(0);
  }, [tableData]);

  const rowsToShow = useMemo(() => {
    const startIndex = currentPage * rowsLimit;
    return productList.slice(startIndex, startIndex + rowsLimit);
  }, [productList, currentPage, rowsLimit]);

  useEffect(() => {
    setTotalPage(Math.ceil(productList.length / rowsLimit));
    setCustomPagination(
      Array(Math.ceil(productList.length / rowsLimit)).fill(null)
    );
  }, [productList, rowsLimit]);

  function searchProducts(keyword) {
    keyword = keyword.toLowerCase();
    setSearchValue(keyword);
    if (keyword !== "") {
      const results = productList.filter((product) => {
        return Object.keys(product).some((key) => {
          if (product[key]) {
            return product[key].toString().toLowerCase().includes(keyword);
          }
          return false;
        });
      });
      setProductList(results);
      setCurrentPage(0);
      setTotalPage(Math.ceil(results?.length / rowsLimit));
      setCustomPagination(
        Array(Math.ceil(results?.length / rowsLimit)).fill(null)
      );
    } else {
      clearData();
    }
  }

  const clearData = () => {
    setSearchValue("");
    const sortedProducts = tableData.slice().sort((a, b) => a.Price - b.Price);
    setProductList(sortedProducts);
    setCustomPagination(
      Array(Math.ceil(tableData?.length / rowsLimit)).fill(null)
    );
    setTotalPage(Math.ceil(tableData?.length / rowsLimit));
  };

  const sortByColumn = (column, changeSortingColumn = true) => {
    const isCurrentlySortedAscending =
      sortingColumn?.includes(column) && changeSortingColumn;

    const sortData = productList.slice().sort((a, b) => {
      const aValue = a[column] !== undefined ? a[column].toString() : "";
      const bValue = b[column] !== undefined ? b[column].toString() : "";

      return isCurrentlySortedAscending
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

    if (changeSortingColumn) {
      // console.log(sortData, "sortData....");
      setProductList(sortData);
      setSortingColumn(isCurrentlySortedAscending ? [] : [column]);
    }
    setActiveColumn([`${column}`]);
  };

  const changePage = (value) => {
    setCurrentPage(value);
  };

  const nextPage = () => {
    if (currentPage < totalPage - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const truncateText = (text, charLimit) => {
    if (!text) return "";
    if (text.length > charLimit) {
      return text.slice(0, charLimit) + "...";
    }
    return text;
  };

  // console.log("columns ", columns);

  return (
    <div className="h-full bg-white flex justify-center pb-14">
      <div className="w-full px-4">
        <div className="flex justify-end  mt-2 py-2 w-full max-w-7xl mx-auto">
          <div className="px-2 bg-[#222E3A]/[6%]  py-3  rounded-lg">
            <div className="flex items-center gap-2">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2741 9.05133C11.1214 7.89518 11.5009 6.46176 11.3366 5.03784C11.1724 3.61391 10.4766 2.3045 9.38841 1.37157C8.30022 0.438638 6.8999 -0.0490148 5.4676 0.0061742C4.0353 0.0613632 2.67666 0.655324 1.66348 1.66923C0.650303 2.68313 0.0573143 4.0422 0.00315019 5.47454C-0.0510139 6.90687 0.437641 8.30685 1.37135 9.39437C2.30506 10.4819 3.61497 11.1768 5.03901 11.34C6.46305 11.5032 7.8962 11.1227 9.05174 10.2746H9.05087C9.07712 10.3096 9.10512 10.3428 9.13662 10.3752L12.5054 13.744C12.6694 13.9081 12.892 14.0004 13.1241 14.0005C13.3562 14.0006 13.5789 13.9085 13.7431 13.7444C13.9072 13.5803 13.9995 13.3578 13.9996 13.1256C13.9997 12.8935 13.9076 12.6709 13.7435 12.5067L10.3747 9.13796C10.3435 9.10629 10.3098 9.07704 10.2741 9.05046V9.05133ZM10.4999 5.68783C10.4999 6.31982 10.3754 6.94562 10.1335 7.5295C9.89169 8.11338 9.5372 8.6439 9.09032 9.09078C8.64344 9.53767 8.11291 9.89215 7.52903 10.134C6.94515 10.3759 6.31936 10.5003 5.68737 10.5003C5.05538 10.5003 4.42959 10.3759 3.84571 10.134C3.26183 9.89215 2.7313 9.53767 2.28442 9.09078C1.83754 8.6439 1.48305 8.11338 1.2412 7.5295C0.999349 6.94562 0.87487 6.31982 0.87487 5.68783C0.87487 4.41148 1.3819 3.1874 2.28442 2.28488C3.18694 1.38236 4.41102 0.875332 5.68737 0.875332C6.96372 0.875332 8.1878 1.38236 9.09032 2.28488C9.99284 3.1874 10.4999 4.41148 10.4999 5.68783Z"
                  fill="black"
                />
              </svg>
              <input
                type="text"
                className="max-w-[150px] text-sm bg-transparent focus:ring-0 border-transparent outline-none placeholder:text-black text-black w-[85%]"
                placeholder="Keyword Search"
                onChange={(e) => searchProducts(e.target.value)}
                value={searchValue}
              />
              <svg
                stroke="currentColor"
                fill="black"
                className={`text-black cursor-pointer ${
                  searchValue?.length > 0 ? "visible" : "invisible"
                }`}
                strokeWidth="0"
                viewBox="0 0 1024 1024"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
                onClick={clearData}
              >
                <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
              </svg>
            </div>
          </div>
        </div>
        {/* <div className="w-full max-w-7xl mx-auto">
          <table className="table-auto w-full text-left font-inter border border-gray-300 rounded-lg overflow-hidden shadow-md">
            <thead className="bg-gray-800 text-white">
              <tr className="text-base font-semibold">
                {columns.map((col, index) => (
                  <th
                    key={index}
                    className={`py-2 px-3 text-left  ${
                      col.accessor === "id" || col.accessor === "location"
                        ? "max-w-[80px]"
                        : "max-w-[80px]"
                    }`}
                  >
                    {col.header}
                  </th>
                ))}
                {showStatus && (
                  <th className="py-2 px-3 text-left max-w-[100px]">Status</th>
                )}
                <th className="py-2 px-3 text-left max-w-[150px]">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {rowsToShow?.map((data, index) => (
                <tr
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-gray-100"
                  } hover:bg-gray-200 transition duration-200`}
                  key={index}
                >
                  {columns.map((col, colIndex) => (
                    // <td

                    //   className={`py-3 px-3 border-t border-gray-300 font-normal text-base ${
                    //     col.accessor === 'id' || col.accessor === 'location' ? 'max-w-[120px]' : 'max-w-[120px]'
                    //   }`}
                    //   key={colIndex}
                    // >
                    //   {col.render
                    //     ? col.render(data)
                    //     : col.accessor === "id"
                    //     ? index + 1
                    //     : data[col.accessor]}
                    // </td>

                    <td
                      className={`py-2 px-3 border-t border-gray-300 font-normal text-base ${
                        col.accessor === "id" || col.accessor === "location"
                          ? "max-w-[120px]"
                          : "max-w-[120px]"
                      }`}
                      key={colIndex}
                    >
                      {["location", "fullname", "email", "profession"].includes(
                        col.accessor
                      )
                        ? truncateText(data[col.accessor], 12)
                        : col.render
                        ? col.render(data)
                        : col.accessor === "id"
                        ? index + 1
                        : data[col?.accessor]||"--"}
                    </td>
                  ))}
                  {showStatus && (
                    <td className={`py-2 px-3 border-t border-gray-300`}>
                      <button
                        className={`inline-flex items-center rounded-full px-3 py-1 text-sm font-medium cursor-pointer ${
                          data.isActive
                            ? "bg-green-500 text-white hover:bg-green-600"
                            : "bg-red-500 text-white hover:bg-red-600"
                        }`}
                        onClick={() =>
                          handleStatusToggle(data?._id, data.isActive)
                        }
                      >
                        {data.isActive ? "Active" : "Inactive"}
                      </button>
                    </td>
                  )}
                  <td className="py-2 w-[150px] border-t border-gray-300">
                    {" "}
                    {/* Fixed width and centered items */}
        {/* <div className="flex justify-center items-center"> */}
        {/* {actions?.showView && (
                        <button
                          className="flex items-center justify-center text-[#1f2937a3] transition duration-200"
                          onClick={() => handleView(data?._id)}
                        >
                          <FaEye size={20} />
                        </button>
                      )}
                      {actions?.showEdit && (
                        <button
                          className="flex items-center justify-center  text-[#1f2937a3] mx-3 transition duration-200 rounded px-2 py-1"
                          onClick={() => handleUpdate(data?._id)}
                        >
                          <FaEdit size={20} />
                        </button>
                      )}
                      {actions?.showDelete && (
                        <button
                          className="flex items-center justify-center text-[#1f2937a3] transition duration-200"
                          onClick={() => handleShowDeleteModal(data._id)}
                        >
                          <FaTrash size={20} />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>  */}

        <div className="w-full max-w-7xl mx-auto overflow-x-auto lg:overflow-x-visible">
          <table className="table-auto w-full text-left font-inter border border-gray-300 rounded-lg overflow-hidden shadow-md min-w-[1200px]">
            <thead className="bg-gray-800 text-white">
              <tr className="text-base font-semibold">
                {columns.map((col, index) => (
                  <th
                    key={index}
                    className={`py-[10px] px-[10px] text-left ${
                      col.accessor === "id"
                        ? "w-[80px]"
                        : col.accessor === "location"
                        ? "max-w-[220px] min-w-[220px]"
                        : col.accessor === "profession"
                        ? "max-w-[200px] truncate"
                        : col.accessor === "email"
                        ? "max-w-[250px] truncate"
                        : col.accessor === "description"
                        ? "max-w-[300px] truncate"
                        : "min-w-[100px]"
                    }`}
                  >
                    <div className="flex items-center">
                      <span
                        className="cursor-pointer pl-1"
                        onClick={() => sortByColumn(col?.accessor)}
                      >
                        {col?.header}
                      </span>
                    </div>
                  </th>
                ))}
                {showStatus && (
                  <th className="py-[10px] px-[10px] text-left min-w-[80px]">
                    Status
                  </th>
                )}
                <th className="py-[10px] px-[10px] text-left min-w-[150px]">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {rowsToShow?.map((data, index) => (
                <tr
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-gray-100"
                  } hover:bg-gray-200 transition duration-200`}
                  key={index}
                >
                  {columns.map((col, colIndex) => (
                    <td
                      className={`py-[10px] px-[10px] border-t border-gray-300 font-normal whitespace-nowrap overflow-hidden text-ellipsis ${
                        col.accessor === "id"
                          ? "w-[80px]"
                          : col.accessor === "location"
                          ? "max-w-[220px] min-w-[220px]"
                          : col.accessor === "profession"
                          ? "max-w-[200px] truncate"
                          : col.accessor === "email"
                          ? "max-w-[250px] truncate"
                          : col.accessor === "description"
                          ? "max-w-[300px] truncate"
                          : "min-w-[120px]"
                      }`}
                      key={colIndex}
                      style={
                        ["profession", "email", "description"].includes(
                          col.accessor
                        )
                          ? {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }
                          : {}
                      }
                    >
                      {col?.render
                        ? col.render(data)
                        : col.accessor === "id"
                        ? index + 1
                        : data[col?.accessor] || "--"}
                    </td>
                  ))}
                  {showStatus && (
                    <td className="py-[10px] px-[10px] border-t border-gray-300">
                      <button
                        className={`inline-flex items-center rounded-full px-3 py-1 text-sm font-medium cursor-pointer ${
                          data.isActive
                            ? "bg-green-500 text-white hover:bg-green-600"
                            : "bg-red-500 text-white hover:bg-red-600"
                        }`}
                        onClick={() =>
                          handleStatusToggle(data?._id, data.isActive)
                        }
                      >
                        {data.isActive ? "Active" : "Inactive"}
                      </button>
                    </td>
                  )}
                  <td className="py-[10px] w-[150px] border-t border-gray-300 px-[10px]">
                    <div className="flex justify-start items-center">
                      {actions?.showView && (
                        <button
                          className="flex items-center justify-center text-[#1f2937a3] transition duration-200"
                          onClick={() => handleView(data?._id)}
                        >
                          <FaEye size={20} />
                        </button>
                      )}
                      {actions?.showEdit && (
                        <button
                          className="flex items-center justify-center text-[#1f2937a3] mx-3 transition duration-200 rounded px-2 py-1"
                          onClick={() => handleUpdate(data?._id)}
                        >
                          <FaEdit size={20} />
                        </button>
                      )}
                      {actions?.showDelete && (
                        <button
                          className="flex items-center justify-center text-[#1f2937a3] transition duration-200"
                          onClick={() => handleShowDeleteModal(data._id)}
                        >
                          <FaTrash size={20} />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div
          className={`w-full justify-center sm:justify-between flex-col sm:flex-row gap-5 mt-[20px] px-1 items-center flex max-w-7xl mx-auto ${
            productList?.length > 0 ? "flex" : "hidden"
          }`}
        >
          <div className="text-lg  text-[#1f2937] font-semibold capitalize">
            Showing {currentPage == 0 ? 1 : currentPage * rowsLimit + 1} to{" "}
            {currentPage == totalPage - 1
              ? productList?.length
              : (currentPage + 1) * rowsLimit}{" "}
            of {productList?.length} entries
          </div>
          <div className="flex">
            <ul
              className="flex justify-center items-center gap-x-[10px] z-30"
              role="navigation"
              aria-label="Pagination"
            >
              <li
                className={`prev-btn flex items-center justify-center w-[36px] rounded-[6px] h-[36px] border-[1px] border-[#E4E4EB] disabled] ${
                  currentPage == 0
                    ? "bg-[#cccccc] pointer-events-none"
                    : " cursor-pointer"
                }`}
                onClick={previousPage}
              >
                <img src="https://www.tailwindtap.com/assets/travelagency-admin/leftarrow.svg" />
              </li>
              {customPagination?.map((data, index) => (
                <li
                  className={`flex items-center justify-center w-[36px] rounded-[6px] h-[34px] border-[1px] bg-[#FFFFFF] cursor-pointer ${
                    currentPage == index
                      ? "text-[#1f2937]  border-[#1f2937]"
                      : "border-[#E4E4EB] "
                  }`}
                  onClick={() => changePage(index)}
                  key={index}
                >
                  {index + 1}
                </li>
              ))}
              <li
                className={`flex items-center justify-center w-[36px] rounded-[6px] h-[36px] border-[1px] border-[#E4E4EB] ${
                  currentPage == totalPage - 1
                    ? "bg-[#cccccc] pointer-events-none"
                    : " cursor-pointer"
                }`}
                onClick={nextPage}
              >
                <img src="https://www.tailwindtap.com/assets/travelagency-admin/rightarrow.svg" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomTable;
