


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import roleService from '../services/roleService';  

import { useNavigate } from "react-router-dom";

import messages from "../messages.json";

import { toast, ToastContainer } from "react-toastify";
import CustomTable from "../components/commonComponent/CustomTable";

const RoleManagement = () => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        let rolesData = await roleService.fetchRoles();  // Call the service method
        
        // Store fetched roles in localStorage
        localStorage.setItem('roleData', JSON.stringify(rolesData));
  
        // Filter out admin role
        const filteredRoles = rolesData.filter((item) => item?.userId?.email !== 'admin@gmail.com');
        
        // Update the state with filtered roles
        setRoles(filteredRoles);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    
    fetchRoles();  // Fetch roles once on mount
  }, []);  // Empty dependency array to run only once when the component mounts
  

  const handleDelete = async (roleId) => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this role?"
      );
      if (confirmDelete) {
        await axios.delete(`${apiUrl}/deleteRoles/${roleId}`);
        setRoles((prevRoles) =>
          prevRoles.filter((role) => role._id !== roleId)
        );
        toast.success(messages.success.delete, {
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error deleting role:", error);
    }
  };

  const handleUpdate = (roleId) => {
    // console.log(roleId,'oooooooo')
    navigate(`/kitty-role-management/update/${roleId}`);
  };

  const handleStatusToggle = async (roleId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await axios.put(`${apiUrl}/updateRoles/${roleId}`, {
        isActive: updatedStatus,
      });

      setRoles(
        roles.map((role) =>
          role._id === roleId ? { ...role, isActive: updatedStatus } : role
        )
      );
      toast.success(
        `Role status updated to ${updatedStatus ? "Active" : "Inactive"}`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const HandleActions = ({ actions }) => {
    return (
      <div>
        {actions.map((action, index) => (
          <label key={index} className="me-2">
            {action}
          </label>
        ))}
      </div>
    );
  };

  const columns = [
    { header: "S.No", accessor: 'id'},
    { header: "Role Name", accessor: "roles" },
    // {
    //   header: "Pages",
    //   render: (item) => (
    //     <div>
    //       {item.permissions.map((permission, index) => (
    //         <div key={index}>{permission.page.pageName}</div>
    //       ))}
    //     </div>
    //   ),
    // },
    {
      header: "User Email",
      render: (item) => (
        <div>
          {item?.userId?.email}
        </div>
      ),
    },
    // {
    //   header: "Role Actions",
    //   render: (item) => (
    //     <div>
    //       {item.permissions.map((permission, index) => (
    //         <HandleActions key={index} actions={permission.actions} />
    //       ))}
    //     </div>
    //   ),
    // },
    {
      header: "Status",
      render: (item) => (
        <button
          onClick={() => handleStatusToggle(item._id, item.isActive)}
          className={`btn ${item.isActive ? "btn-success" : "btn-danger"}`}
        >
          {item.isActive ? "Active" : "Inactive"}
        </button>
      ),
    },
    // {
    //   header: "Actions",
    //   render: (item) => (
    //     <div>
    //       <button onClick={() => handleUpdate(item._id)}>Edit</button>
    //       <button onClick={() => handleDelete(item._id)}>Delete</button>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col">
          <h2 className="table-heading">Roles Management</h2>
        </div>
        <div className="col-auto">
          <Link to="/kitty-roles/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add Roles
          </Link>
        </div>
      </div>

      <CustomTable
        tableData={roles}
        columns={columns}
        handleStatusToggle={handleStatusToggle}
        showStatus={true}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleDelete}
        actions={{
          showDelete: true,
          showEdit: true,
        }}
      />

      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default RoleManagement;

