
import { usePermissions } from '../hooks/PermissionsContext';
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaDashcube, FaUsers, FaBuilding, FaClipboardList } from "react-icons/fa";
import { MdCompost, MdEventNote } from "react-icons/md";
import { GiCakeSlice, GiWallet } from "react-icons/gi";
import { AiOutlineForm } from "react-icons/ai";

// Hooks
const Sidebar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [activeLink, setActiveLink] = useState(null);

  // Retrieve permissions
  const { hasPermission } = usePermissions();

  // Define links with permission checks
  const links = [
    { to: "/home", label: "Dashboard", icon: <FaDashcube /> },
    hasPermission('Users', 'View') && { to: "/kitty-users", label: "Users", icon: <FaUsers /> },
    hasPermission('Groups', 'View') &&{
      label: "Groups",
      dropdown: [
        hasPermission('Groups', 'View') && { to: "/kitty-groups", label: "Groups", icon: <FaClipboardList /> },
        hasPermission('Group Frequency', 'View') &&  { to: "/group-frequency", label: "Group Frequency", icon: <MdEventNote /> },
        hasPermission('Group Interest', 'View') &&  { to: "/group-interest", label: "Group Interest", icon: <MdCompost /> },
      ],
      icon: <FaUsers />, // Main icon for Groups
    },
    {
      label: "Venues",
      dropdown: [
        hasPermission('Venues', 'View') && { to: "/kitty-venue", label: "Venue", icon: <FaBuilding /> },
        hasPermission('Venue Category', 'View') &&{ to: "/kitty-venue-category", label: "Venue Category", icon: <FaBuilding /> },
      ],
      icon: <FaBuilding />, // Main icon for Venues
    },
  
    // { to: "/kitty-interest", label: "Interest", icon: <MdCompost /> },
    hasPermission('Kitty', 'View') &&{ to: "/kitty-info", label: "Kitty", icon: <GiCakeSlice /> }, // Changed here
    hasPermission('Activity', 'View') && { to: "/kitty-activity", label: "Activity", icon: <MdEventNote /> },
    hasPermission('Themes', 'View') &&{ to: "/kitty-theme", label: "Themes", icon: <FaClipboardList /> },
  hasPermission('Role Management', 'View') &&{ to: "/kitty-role-management", label: "Role Management", icon: <AiOutlineForm /> },
  hasPermission('Banner', 'View') &&{ to: "/kitty-banner", label: "Banner", icon: <GiCakeSlice /> }, // Changed here
  hasPermission('Post', 'View') &&{ to: "/kitty-post", label: "Post", icon: <MdCompost /> },
  hasPermission('Template', 'View') &&{ to: "/kitty-template", label: "Template", icon: <AiOutlineForm /> },
  hasPermission('Color', 'View') &&{ to: "/kitty-color", label: "Color", icon: <GiCakeSlice /> }, 
 //hasPermission('City', 'View') &&  { to: "/kitty-city", label: "City", icon: <FaBuilding /> },
 //hasPermission('Address', 'View') &&  { to: "/kitty-address", label: "Address", icon: <FaBuilding /> },
 hasPermission('Booking', 'View') && { to: "/kitty-booking", label: "Bookings", icon: <GiWallet /> },
 hasPermission('Wishlist', 'View') && { to: "/kitty-wishlist", label: "WishList", icon: <GiCakeSlice /> }, // Changed here
 hasPermission('Support', 'View') && { to: "/kitty-feedback", label: "Support", icon: <MdCompost /> },
 hasPermission('Terms & Service', 'View') && { to: "/kitty-terms&Services", label: "Terms & Services", icon: <MdCompost /> },
 hasPermission('Wallet', 'View') && { to: "/kitty-walletTransactionHistory", label: "Wallet Transaction History", icon: <GiWallet /> },


  ].filter(Boolean); // Filter out any false values (i.e., links with no permission)

  const toggleDropdown = (index) => {
    setOpenDropdown((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <aside className="absolute left-0 top-0 z-50 flex h-screen w-[250px] flex-col bg-[#1c2434] overflow-y-hidden transition-transform duration-300 ease-linear lg:static lg:translate-x-0 -translate-x-full shadow-lg">
      <div className="mt-24 py-4 px-3 max-w-[250px] h-[100vh] overflow-auto scrollbar scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-slate-700">
        {links.map((link, index) => (
          <div key={index}>
            {link.dropdown ? (
              <div>
                <div
                  className={`flex items-center justify-between cursor-pointer font-semibold text-white hover:bg-gray-600 rounded-md px-3 py-2 transition duration-200 ${
                    activeLink === link.label ? "bg-graydark" : ""
                  }`}
                  onClick={() => {
                    toggleDropdown(index);
                    handleLinkClick(link.label);
                  }}
                >
                  <span className="flex gap-[8px] items-center">{link.icon} {link.label}</span>
                  <svg
                    className={`w-4 h-4 transform transition-transform duration-200 ${
                      openDropdown === index ? "rotate-180" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 14l-4-4m0 0l4-4m-4 4h8"
                    />
                  </svg>
                </div>
                {openDropdown === index && (
                  <div className="ml-4 mt-2">
                    {link.dropdown.map((sublink, subIndex) => (
                      <Link
                        key={subIndex}
                        to={sublink.to}
                        className={`flex items-center block py-2 pl-4 text-gray-300 hover:bg-gray-600 rounded-md transition duration-200 ${
                          activeLink === sublink.label ? "bg-graydark" : ""
                        }`}
                        onClick={() => handleLinkClick(sublink.label)}
                      >
                        {sublink.icon} {sublink.label}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <Link
                to={link.to}
                className={`flex items-center block py-2 px-3 text-white hover:bg-gray-600 rounded-md transition duration-200 gap-[6px] ${
                  activeLink === link.label ? "bg-graydark" : ""
                }`}
                onClick={() => handleLinkClick(link.label)}
              >
                {link.icon} {link.label}
              </Link>
            )}
          </div>
        ))}
      </div>
    </aside>
  );
};

export default Sidebar;
