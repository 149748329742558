import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { DatePicker, theme, TimePicker } from "antd";
import moment from "moment/moment";



const AddBookingForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // Extract Booking ID from URL params
  const [formData, setFormData] = useState({
    contactPersonName: "",
    emailAddress: "",
    contactNumber: "",
    date: '',
    time: '',
    minimumGuests: "",
    vegNonVegDrinks: "",
    preference: "",
    starters: '',
    mainCourse: '',
    sweets:'',
    drinks:'',
    message:""
   
  });
  const [suggestions, setSuggestions] = useState([]);
  const [errors, setErrors] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (id) {
      fetchBookingData(id);
    }
  }, [id]);

  const fetchBookingData = async (BookingId) => {
    try {
      const response = await axios.get(`${apiUrl}/getBookingRequestById/${BookingId}`);
      console.log(response.data.data);
      const BookingData = response.data.bookingRequest;
      setFormData({
        contactPersonName: BookingData.contactPersonName,
        emailAddress: BookingData.emailAddress,
        contactNumber: BookingData.contactNumber,
        date: moment(BookingData.date), 
      time: moment(BookingData.time),
        minimumGuests: BookingData.minimumGuests, 
        vegNonVegDrinks: BookingData.vegNonVegDrinks,
        preference: BookingData.preference,
        starters: BookingData.starters,
        mainCourse: BookingData.mainCourse,
        sweets: BookingData.sweets,
        drinks: BookingData.drinks,
        message: BookingData.message,

       
       
      });
      
    } catch (error) {
      console.error("Failed to fetch Booking data:", error);
    }
  };

//   const validate = () => {
//     const newErrors = {};
//     const currentDate = new Date();

//     if (!formData.fullname) {
//       newErrors.fullname = "fullname is required";
//     }

//     if (!formData.dob) {
//       newErrors.dob = "Date of Birth is required";
//     } else if (new Date(formData.dob) > currentDate) {
//       newErrors.dob = "Date of Birth cannot be in the future";
//     }
  
//     if (!formData.email) {
//       newErrors.email = "Email is required";
//     } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
//       newErrors.email = "Email address is invalid";
//     }
//     if (!formData.profession) {
//       newErrors.profession = "Profession is required";
//     }
//     if (!formData.Bookingname) {
//       newErrors.Bookingname = "Bookingname is required";
//     }
//     if (!formData.location) {
//       newErrors.location = "Location is required";
//     }
//     if (!formData.emergencyNumber) {
//       newErrors.emergencyNumber = "Emergency Number is required";
//     } else if (!/^\d{10}$/.test(formData.emergencyNumber)) {
//       newErrors.emergencyNumber = "Emergency Number must be 10 digits";
//     }
//     if (!formData.phoneNumber) {
//       newErrors.phoneNumber = "Phone Number is required";
//     } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
//       newErrors.phoneNumber = "Phone Number must be 10 digits";
//     }
  
//     return newErrors;
//   };
//   const handleBlur = (e) => {
//     const { id } = e.target;
//     const validationErrors = validate();
    
//     if (validationErrors[id]) {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [id]: validationErrors[id],
//       }));
//     } else {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [id]: "",
//       }));
//     }
//   };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const validationErrors = validate();
    // if (Object.keys(validationErrors).length > 0) {
    //   setErrors(validationErrors);
    //   return;
    // }
    setErrors({});

    try {
      if (id) {
        await axios.put(`${apiUrl}/updateBookingRequestById/${id}`, formData);
        toast.success("Booking Updated Successfully", {
          autoClose: 2000,
        });
      } else {
        await axios.post(`${apiUrl}/createBookingRequest`, formData);
        toast.success("Booking Added Successfully", {
          autoClose: 2000,
        });
      }
      setFormData({
        contactPersonName: "",
        emailAddress: "",
        contactNumber: "",
        date: '',
        time: '',
        minimumGuests: "",
        vegNonVegDrinks: "",
        preference: "",
        starters: '',
        mainCourse: '',
        sweets:'',
        drinks:'',
        message:""
        
      });
      // Delay navigation after toast message
      setTimeout(() => {
        navigate("/kitty-booking");
      }, 2500); // Adjust the delay as needed
    } catch (error) {
      console.error("Failed to submit Booking data:", error);
    }
   
  };
  const handleDateChange = (date) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      date: date
    }));
  };

  const handleTimeChange = (time) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      time: time
    }));
  };

  const handleChange = async (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));

    // Reset the error for the current field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  }
   


  
  

  const handleSelect = (suggestion) => {
    const { description } = suggestion;
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: description,
    }));
    setSuggestions([]);
  };

  const handleClose = () => {
    navigate("/kitty-booking");
  };
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8 mx-auto">
        <div className="card p-0">
        <div className=" card-body w-full p-0 pb-3">
          <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
            <h2 className="card-title">{id ? "Update Booking" : "Add Booking"}</h2>
            <FaTimes
              className="text-danger cursor-pointer"
              onClick={handleClose}
            />
          </div>

          <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="contactPersonName" className="mb-2 block text-sm font-medium text-black dark:text-white">
               ContactPerson Name
              </label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="contactPersonName"
                placeholder="Enter  name"
                value={formData.contactPersonName}
                onChange={handleChange}
                // onBlur={handleBlur}
               
              />
              {/* {errors.fullname && (
                <div className="text-danger mt-1">{errors.fullname}</div>
              )} */}
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="emailAddress" className="mb-2 block text-sm font-medium text-black dark:text-white">
              EmailAddress
              </label>
              <input
                type="test"
                autoComplete="off"
                className="form-control"
                id="emailAddress"
                placeholder="Enter email"
                value={formData.emailAddress}
                onChange={handleChange}
                // onBlur={handleBlur}
                
              />
              {/* {errors.dob && (
                <div className="text-danger mt-1">{errors.dob}</div>
              )} */}
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="contactNumber" className="mb-2 block text-sm font-medium text-black dark:text-white">
              ContactNumber
              </label>
              <input
                type="number"
                autoComplete="off"
                className="form-control"
                id="contactNumber"
                placeholder="Enter contactNumber"
                
                value={formData.contactNumber}
                onChange={handleChange}
                
              />
              {/* {errors.Bookingname && (
                <div className="text-danger mt-1">{errors.Bookingname}</div>
              )} */}
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="date" className="mb-2 block text-sm font-medium text-black dark:text-white">
                Date
              </label>
              
              <DatePicker
                id="date"
                selected={formData.date}
                onChange={handleDateChange}
                className="form-control"
                placeholderText="Select Date"
                required
                dateFormat="yyyy/MM/dd"
              />
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="time" className="mb-2 block text-sm font-medium text-black dark:text-white">
                Time
              </label>
              
              <TimePicker
                id="time"
                selected={formData.time}
                onChange={handleTimeChange}
                className="form-control"
                placeholderText="Select Time"
                required
                use12Hours
                format="h:mm A"
              />
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="minimumGuests" className="mb-2 block text-sm font-medium text-black dark:text-white">
              MinimumGuests
              </label>
              <input
                type="number"
                autoComplete="off"
                className="form-control"
                id="minimumGuests"
                placeholder="Enter minimumGuests"
                value={formData.minimumGuests}
                onChange={handleChange}
                // onBlur={handleBlur}
               
              />
              {/* {errors.profession && (
                <div className="text-danger mt-1">{errors.profession}</div>
              )} */}
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="vegNonVegDrinks" className="mb-2 block text-sm font-medium text-black dark:text-white">
              VegNonVegDrinks
              </label>
              <select
                type="text"
                autoComplete="off"
                className="form-control"
                id="vegNonVegDrinks"
                placeholder="Enter vegNonVegDrinks"
                value={formData.vegNonVegDrinks}
                onChange={handleChange}
                // onBlur={handleBlur}
              >
                <option value="">Select VegNonVegDrinks type</option>
                <option value="Veg">Veg</option>
                <option value="NonVeg">NonVeg</option>
                <option value="Drinks">Drinks</option>

              </select>

              {/* {errors.phoneNumber && (
                <div className="text-danger mt-1">{errors.phoneNumber}</div>
              )} */}
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="preference" className="mb-2 block text-sm font-medium text-black dark:text-white">
              Preference
              </label>
              <select
                type="text"
                autoComplete="off"
                className="form-control"
                id="preference"
                placeholder="Enter preference"
                value={formData.preference}
                onChange={handleChange}
                // onBlur={handleBlur}
              >
                <option value="">Select preference </option>
                <option value="Buffet">Buffet</option>
                <option value="AlaCarte">AlaCarte</option>
                <option value="MainCourse">MainCourse</option>

              </select>

              {/* {errors.phoneNumber && (
                <div className="text-danger mt-1">{errors.phoneNumber}</div>
              )} */}
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="starters" className="mb-2 block text-sm font-medium text-black dark:text-white">
              Starters
              </label>
              <textarea
                className="form-control"
                id="starters"
                rows="3"
                placeholder="Enter starters"
                value={formData.starters}
                onChange={handleChange}
                
              />
            </div>

            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="mainCourse" className="mb-2 block text-sm font-medium text-black dark:text-white">
              MainCourse
              </label>
              <textarea
                className="form-control"
                id="mainCourse"
                rows="3"
                placeholder="Enter mainCourse"
                value={formData.mainCourse}
                onChange={handleChange}
                
              />
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="sweets" className="mb-2 block text-sm font-medium text-black dark:text-white">
              Sweets
              </label>
              <textarea
                className="form-control"
                id="sweets"
                rows="3"
                placeholder="Enter sweets"
                value={formData.sweets}
                onChange={handleChange}
                
              />
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="drinks" className="mb-2 block text-sm font-medium text-black dark:text-white">
              Drinks
              </label>
              <textarea
                className="form-control"
                id="drinks"
                rows="3"
                placeholder="Enter drinks"
                value={formData.drinks}
                onChange={handleChange}
                
              />
            </div>
            <div className="mb-3 col-md-12 p-2">
              <label htmlFor="message" className="mb-2 block text-sm font-medium text-black dark:text-white">
              Message
              </label>
              <textarea
                className="form-control"
                autoComplete="off"
                id="message"
                placeholder="Enter message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className=" ms-2 flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
              {id ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddBookingForm;
