import React, { useState } from 'react';
import FormInput from '../components/FormInput';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import messages from '../messages.json';
import { validateName, validateEmail, validatePassword } from '../utils/validation';
import { toast, ToastContainer } from 'react-toastify';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({ name: '', email: '', password: '', confirmPassword: '' });
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleRegister = async (e) => {
    e.preventDefault();

    // Reset previous errors
    setErrors({ name: '', email: '', password: '', confirmPassword: '' });

    if (!name || !email || !password || !confirmPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, name: messages.error.allFieldsRequired }));
      setTimeout(() => {
        setErrors((prevErrors) => ({ ...prevErrors, name: '' }));
      }, 2000);
      return;
    }

    if (!validateName(name)) {
      setErrors((prevErrors) => ({ ...prevErrors, name: messages.error.nameInvalid }));
      setTimeout(() => {
        setErrors((prevErrors) => ({ ...prevErrors, name: '' }));
      }, 2000);
      return;
    }

    if (!validateEmail(email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: messages.error.emailInvalid }));
      setTimeout(() => {
        setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
      }, 2000);
      return;
    }

    if (!validatePassword(password)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: messages.error.passwordInvalid
      }));
      setTimeout(() => {
        setErrors((prevErrors) => ({ ...prevErrors, password: '' }));
      }, 2000);
      return;
    }

    if (password !== confirmPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: messages.error.passwordsDoNotMatch }));
      setTimeout(() => {
        setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: '' }));
      }, 2000);
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/signup`, {
        email,
        password,
        fullname: name,
      });

      toast.success(messages.success.registrationSuccessful);
      setTimeout(() => {
        navigate('/login');
      }, 3000); // Navigate to login after 3 seconds
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({ ...errors, email: error.response.data.error || messages.error.somethingWentWrong });
      } else {
        setErrors({ ...errors, email: messages.error.somethingWentWrong });
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 min-w-[500px]">
      <form onSubmit={handleRegister} className="bg-white p-8 rounded-lg shadow-md w-full max-w-[600px]"> {/* Changed max-w-md to max-w-[600px] */}
        <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Register</h2>
        <FormInput label="Name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
        {errors.name && <p className="text-red-500 text-sm mb-2">{errors.name}</p>}
        <FormInput label="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        {errors.email && <p className="text-red-500 text-sm mb-2">{errors.email}</p>}
        <FormInput label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        {errors.password && <p className="text-red-500 text-sm mb-2">{errors.password}</p>}
        <FormInput label="Confirm Password" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        {errors.confirmPassword && <p className="text-red-500 text-sm mb-2">{errors.confirmPassword}</p>}
        <button
          type="submit"
          className="w-full flex ms-2 justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90"
        >
          Register
        </button>
        <Link to="/login" className="text-blue-600 hover:underline text-center block mt-4">
          Already have an account? Login
        </Link>
      </form>
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default Register;
