import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import { RiDeleteBin4Fill } from "react-icons/ri";
import { IoIosEye } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify";
import messages from "../messages.json";
import Pagination from "../components/Pagination";
import DeleteConfirmationModal from "../forms/DeleteConformation";
import CategorysInformation from "../forms/venueCategoryInformation";
import CustomTable from "../components/commonComponent/CustomTable";
import { usePermissions } from "../hooks/PermissionsContext";


const Category = () => {
  const [categories, setcategories] = useState([]);
  const [filteredcategories, setFilteredcategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(10);
  const [selectedcategories, setSelectedcategories] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [categoryToDelete, setcategoryToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { hasPermission } = usePermissions  ();

  const showView = hasPermission('Venue Category', 'View');
  const showAdd = hasPermission('Venue Category', 'Add');
  const showEdit = hasPermission('Venue Category', 'Update');
  const showDelete = hasPermission('Venue Category', 'Delete');


  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllVenueCategories`);
        const fetchedcategories = response.data;

        setcategories(fetchedcategories);
        setFilteredcategories(fetchedcategories); // Ensure this is an array
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filtercategories();
  }, [searchInput, categories,  selectedStatuses]);

  const filtercategories = () => {
    let filtered = categories;

    if (searchInput !== "") {
      filtered = filtered.filter(
        (category) =>
          category.name &&
          category.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    if (selectedStatuses.length > 0) {
      filtered = filtered.filter((category) =>
        selectedStatuses.includes(category.isActive ? "Active" : "Inactive")
      );
    }

    setFilteredcategories(filtered);
  };

  const handleShowDeleteModal = (categoryId) => {
    setcategoryToDelete(categoryId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setcategoryToDelete(null);
  };

  const handleDelete = async () => {
    if (categoryToDelete) {
      try {
        await axios.delete(`${apiUrl}/deleteVenueCategory/${categoryToDelete}`);
        setcategories(categories.filter((category) => category._id !== categoryToDelete));
        toast.success(messages.success.delete, {
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate('/kitty-venue-category');
        }, 2000);
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setcategoryToDelete(null);
      }
    }
  };

  const handleUpdate = (categoryId) => {
    navigate(`/kitty-category/update/${categoryId}`);
  };

  const handleStatusToggle = async (categoryId, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await axios.put(`${apiUrl}/updateVenueCategory/${categoryId}`, {
        isActive: updatedStatus,
      });

      setcategories(
        categories.map((category) =>
          category._id === categoryId ? { ...category, isActive: updatedStatus } : category
        )
      );
      toast.success(
        `category status updated to ${updatedStatus ? "Active" : "Inactive"}`,
        {
          autoClose: 2000,
        }
      );
    } catch (error) {
      console.error(messages.error.errorUpdating, error);
      toast.error(messages.error.errorUpdating, {
        autoClose: 2000,
      });
    }
  };

  const handleView = async (categoryId) => {
    try {
      const response = await axios.get(`${apiUrl}/getVenueCategoryById/${categoryId}`);
      setSelectedcategories(response.data);
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedcategories(null);
  };

  const indexOfLastcategory = currentPage * categoriesPerPage;
  const indexOfFirstcategory = indexOfLastcategory - categoriesPerPage;
  const currentcategories = filteredcategories.slice(indexOfFirstcategory, indexOfLastcategory);
  const totalPages = Math.ceil(filteredcategories.length / categoriesPerPage);



 

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevState) =>
      prevState.includes(status)
        ? prevState.filter((item) => item !== status)
        : [...prevState, status]
    );
  };
  const columns = [
    { header: 'S.No', accessor: 'id' },
    { header: ' Venue Category Name', accessor: 'name' },
  
  ];  

  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">Venue Category Information</h2>
        </div>
        <div className="col-auto">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <span className="input-group-text">
              <FaSearch />
            </span>
          </div>
        </div>
        <div className="col-auto">
          

          <div className="dropdown d-inline-block me-2">
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1"
              type="button"
              onClick={() => setShowStatusDropdown(!showStatusDropdown)}
            >
              Status
              <svg class="ml-2 -mr-1 h-3 w-3 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {showStatusDropdown && (
              <ul className="dropdown-menu show px-[10px]">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Active"
                      id="status-active"
                      checked={selectedStatuses.includes("Active")}
                      onChange={() => handleStatusChange("Active")}
                    />
                    <label className="form-check-label" htmlFor="status-active">
                      Active
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Inactive"
                      id="status-inactive"
                      checked={selectedStatuses.includes("Inactive")}
                      onChange={() => handleStatusChange("Inactive")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="status-inactive"
                    >
                      Inactive
                    </label>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        {showAdd && (

        <div className="col-auto">
          <Link to="/kitty-category/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add category
          </Link>
        </div>
        )}
      </div>
   
      <CustomTable
        tableData={filteredcategories}
        columns={columns}
        handleStatusToggle={handleStatusToggle}
        showStatus={true}
        handleView={handleView}
        handleUpdate={handleUpdate}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView,
          showEdit,
          showDelete,
        }}
      />

      <CategorysInformation
      category={selectedcategories}
      show={showModal}
      handleClose={handleCloseModal}
      />
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default Category;
