import React from "react";

const ActivityInformation = ({ show, handleClose, activity }) => {
  if (!show) return null; // If the modal is not shown, return nothing

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50" onClick={handleClose}></div>
      <div className="bg-white rounded-lg shadow-lg w-96 z-[9]">
        <div className="flex justify-between items-center px-4 py-2 border-b">
          <h2 className="text-lg font-semibold">Activity Information</h2>
          <button
            className="text-gray-600 hover:text-gray-900"
            onClick={handleClose}
          >
            &times;
          </button>
        </div>
        <div className="p-4">
          {activity ? (
            <div>
              <p>
                <strong>Name:</strong> {activity?.name}
              </p>
              <p>
                <strong>Description:</strong> {activity?.description}
              </p>
              <p>
                <strong>Status:</strong> {activity?.isActive ? "Active" : "Inactive"}
              </p>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <div className="flex justify-end px-4 py-2 border-t">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActivityInformation;
