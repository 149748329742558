import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import messages from "../messages.json";
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';

const AddWishlistForm = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const { id } = useParams(); 
  const [formData, setFormData] = useState({
    userId: "",
    description: "",
    name: "",
    image: "",
    venueId: "",
  });
  const [venues, setVenues] = useState([]); // State to hold the list of venues
  const [imageUrl, setImageUrl] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  // Fetch venues from API on component mount
  useEffect(() => {
    fetchVenues();
  }, []);

  const fetchVenues = async () => {
    try {
      const response = await axios.get(`${apiUrl}/getAllVenues`);
      setVenues(response.data.data); // Assuming the API response contains the venues array in response.data.data
    } catch (error) {
      console.error("Failed to fetch venues:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchWishlistData(id);
    }
  }, [id]);

  const fetchWishlistData = async (WishlistId) => {
    try {
      const response = await axios.get(`${apiUrl}/getWishlistById/${WishlistId}`);
      const WishlistData = response.data.data;
      setFormData({
        description: WishlistData.description,
        createdAt: new Date(WishlistData.createdAt),
        venueId: WishlistData.venueId || "", // Populate venueId if it's in the response
      });
      setImagePreview(WishlistData.image);
      setImageUrl(WishlistData.image); 
    } catch (error) {
      console.error("Failed to fetch Wishlist data:", error);
    }
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
    setImagePreview(URL.createObjectURL(file)); // Show image preview
    console.log("File selected:", file); // Log selected file
  };

  const handleImageRemove = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: null,
    }));
    setImagePreview(null); // Remove image preview
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user._id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: user._id,
      }));
    } else {
      console.error("User ID not found in local storage");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let uploadedImageUrl = imageUrl;

    if (formData.image) {
      try {
        const file = formData.image;
        const fileExtension = file.name.split('.').pop();
        const fileName = `${uuidv4()}.${fileExtension}`;

        // Initialize the S3 Client
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
          region: 'ap-south-1',
        });

        const s3 = new AWS.S3();
        const params = {
          Bucket: 'kittybee',
          Key: `template/${fileName}`,
          Body: file,
          ContentType: file.type,
        };

        // Upload the file
        const data = await s3.upload(params).promise();
        uploadedImageUrl = data.Location;
        setImageUrl(uploadedImageUrl); // Update image URL state
      } catch (err) {
        console.error("Error uploading file:", err);
        toast.error("Failed to upload image", { autoClose: 2000 });
        return;
      }
    }

    try {
      const dataToSend = {
        userId: formData.userId,
        description: formData.description,
        name: formData.name,
        image: uploadedImageUrl,
        venueId: formData.venueId, // Send the selected venueId to the database
      };

      if (id) {
        await axios.put(`${apiUrl}/updateWishlistById/${id}`, dataToSend);
        toast.success("Wishlist Updated Successfully", {
          autoClose: 2000,
        });
      } else {
        await axios.post(`${apiUrl}/addWishlist`, dataToSend);
        toast.success(messages.success.dataAdded, { autoClose: 2000 });
      }

      setFormData({
        userId: "",
        description: "",
        name: "",
        image: "",
        venueId: "",
      });

      setImagePreview(null);
      setImageUrl(null);

      setTimeout(() => {
        navigate("/kitty-Wishlist");
      }, 1000);
    } catch (error) {
      console.error("Failed to submit Wishlist data:", error);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleVenueChange = (e) => {
    const selectedVenueId = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      venueId: selectedVenueId,
    }));
  };

  const handleClose = () => {
    navigate("/kitty-Wishlist");
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8 mx-auto">
        <div className="card p-0">
        <div className=" card-body w-full p-0 pb-3">
          <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
            <h2 className="card-title">{id ? "Update Wishlist" : "Add Wishlist"}</h2>
            <FaTimes className="text-danger cursor-pointer" onClick={handleClose} />
          </div>
          <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="name" className="mb-2 block text-sm font-medium text-black dark:text-white">Name</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="name"
                placeholder="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="description" className="mb-2 block text-sm font-medium text-black dark:text-white">Description</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="description"
                placeholder="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </div>

            {/* Venue Select Dropdown */}
            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="venue" className="mb-2 block text-sm font-medium text-black dark:text-white">Select Venue</label>
              <select
                id="venueId"
                className="form-control"
                value={formData.venueId}
                onChange={handleVenueChange}
                required
              >
                <option value="">Select a venue</option>
                {venues.map((venue) => (
                  <option key={venue._id} value={venue._id}>
                    {venue.name} {/* Assuming the venue object contains a 'type' field */}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3 col-md-6 p-2">
              <label htmlFor="image" className="mb-2 block text-sm font-medium text-black dark:text-white">Upload Image</label>
              <input
                type="file"
                className="form-control"
                id="image"
                accept=".jpg,.jpeg,.png"
                onChange={handleFileChange}
              />
              {imagePreview && (
                <div className="mt-3">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "100%", maxHeight: "300px" }}
                  />
                  <FaTimes
                    className="image-remove-icon"
                    onClick={handleImageRemove}
                  />
                </div>
              )}
            </div>
            
            <button type="submit" className="flex ms-2 justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
              {id ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default AddWishlistForm;
