import React, { useState, useContext } from 'react';
import { AuthContext } from '../auth/AuthContext';
import FormInput from '../components/FormInput';
import { Link, useNavigate } from 'react-router-dom';
import { toast ,ToastContainer} from 'react-toastify';
import messages from '../messages.json';
import 'react-toastify/dist/ReactToastify.css';
import { BiHide ,BiShow  } from "react-icons/bi";
import { usePermissions } from '../hooks/PermissionsContext';

const Login = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const { handleLoginOne } = usePermissions();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setErrors({ email: '', password: '' });

    if (!email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: messages.error.emailRequired }));
      return;
    }

    if (!password) {
      setErrors((prevErrors) => ({ ...prevErrors, password: messages.error.passwordRequired }));
      return;
    }

    try {
      const loggedIn = await login(email, password);

      if (loggedIn) {
        toast.success(messages.success.loginSuccessful, { autoClose: 2000 });
        setTimeout(() => {
          navigate('/home');
          handleLoginOne();

        }, 0);
      } else {
        setErrors({ email: '', password: messages.error.invalidCredentials });
      }
    } catch (error) {
      setErrors({ email: '', password: messages.error.passwordRequired });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleLogin} className="bg-white shadow-md rounded-lg p-8 w-[600px]">
        <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Login</h2>

        <FormInput
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mb-4"
        />
        {errors.email && <p className="text-red-500 text-sm mb-2">{errors.email}</p>}

      {/* Password Input with Eye Icon */}
      <div className="relative mb-4">
        <input
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="w-full p-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <span
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
        >
          {showPassword ? <BiShow size={20} /> : <BiHide size={20} />}
        </span>
        {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
      </div>

        <button
          type="submit"
          className="w-full flex ms-2 justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90"
        >
          Login
        </button>

        <Link to="/register" className="text-blue-600 hover:underline text-center block mt-4">
          Don't have an account? Create Account
        </Link>
      </form>
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default Login;
