import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';

const BookingInformation = ({ show, handleClose, Booking}) => {
  return (
<Modal show={show} onHide={handleClose} className="modal-lg modal-dialog-centered">
  <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-7xl mx-auto">
    <Modal.Header className="bg-[#0e121a] text-white border-b border-gray-200">
      <Modal.Title className="text-xl font-semibold">Booking Information</Modal.Title>
      <Button variant="close" onClick={handleClose} className="text-white hover:text-gray-400">
        &times;
      </Button>
    </Modal.Header>

    <Modal.Body className="p-3">
      {Booking ? (
        <div className="flex flex-wrap space-y-4">
          <div className="w-1/2">
            <p className="text-gray-700 mb-[10px]">
              <strong>Contact Person Name:</strong> <span className="text-gray-500 capitalize font-normal">{Booking.contactPersonName}</span>
            </p>
            <p className="text-gray-700 mb-[10px]">
              <strong>Email Address:</strong> <span className="text-gray-500">{Booking.emailAddress}</span>
            </p>
            <p className="text-gray-700 mb-[10px]">
              <strong>Contact Number:</strong> <span className="text-gray-500">{Booking.contactNumber}</span>
            </p>
            <p className="text-gray-700 mb-[10px]">
              <strong>Date:</strong> <span className="text-gray-500">{Booking.date}</span>
            </p>
            <p className="text-gray-700 mb-[10px]">
              <strong>Time:</strong> <span className="text-gray-500">{Booking.time}</span>
            </p>
            <p className="text-gray-700 mb-[10px]">
              <strong>Minimum Guests:</strong> <span className="text-gray-500">{Booking.minimumGuests}</span>
            </p>
            <p className="text-gray-700 mb-[10px]">
              <strong>Veg/Non-Veg Drinks:</strong> <span className="text-gray-500">{Booking.vegNonVegDrinks}</span>
            </p>
            <p className="text-gray-700 mb-[10px]">
              <strong>Preference:</strong> <span className="text-gray-500">{Booking.preference}</span>
            </p>
          </div>

          <div className="w-1/2">
            <p className="text-gray-700 mb-[10px]">
              <strong>Starters:</strong> <span className="text-gray-500">{Booking.starters}</span>
            </p>
            <p className="text-gray-700 mb-[10px]">
              <strong>Main Course:</strong> <span className="text-gray-500">{Booking.mainCourse}</span>
            </p>
            <p className="text-gray-700 mb-[10px]">
              <strong>Sweets:</strong> <span className="text-gray-500">{Booking.sweets}</span>
            </p>
            <p className="text-gray-700 mb-[10px]">
              <strong>Drinks:</strong> <span className="text-gray-500">{Booking.drinks}</span>
            </p>
            <p className="text-gray-700 mb-[10px]">
              <strong>Message:</strong> <span className="text-gray-500">{Booking.message}</span>
            </p>
            <p className="text-gray-700 mb-[10px]">
              <strong>Status:</strong>
              <span className={`${Booking.isActive ? 'text-green-500' : 'text-red-500'}`}>
                {Booking.isActive ? 'Active' : 'Inactive'}
              </span>
            </p>
          </div>
        </div>
      ) : (
        <p className="text-gray-600">Loading...</p>
      )}
    </Modal.Body>

    <Modal.Footer className="bg-gray-100 p-3">
      <Button variant="secondary" onClick={handleClose} className="bg-gray-600 text-white hover:bg-gray-700 transition duration-200">
        Close
      </Button>
    </Modal.Footer>
  </div>
</Modal>

  
  )
}

export default BookingInformation;
