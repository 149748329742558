import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";


const AddCityForm = () => {
  const { id } = useParams(); 
  const [formData, setFormData] = useState({
    name: "",
  });
  

  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchCityData(id);
    }
  }, [id]);

  const fetchCityData = async (CityId) => {
    try {
      const response = await axios.get(`${apiUrl}/getCityById/${CityId}`);
      console.log(response,"citytyytyt");

      const CityData = response.data.city;
      setFormData({
        name: CityData.name,
      });
    } catch (error) {
      console.error("Failed to fetch City data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

 
    try {
      const dataToSend = {
        name: formData.name,
      };


      if (id) {
        await axios.put(`${apiUrl}/updateCity/${id}`, dataToSend);
        toast.success("City Updated Successfully", {
          autoClose: 2000,
        });
      } else {
        await axios.post(`${apiUrl}/createCity`, dataToSend);
        toast.success("City Added Successfully", {
          autoClose: 2000,
        });
      }

      setFormData({
        name: "",
      });

      setTimeout(() => {
        navigate('/kitty-City');
      }, 1000);
    } catch (error) {
      console.error("Failed to submit City data:", error?.response?.data?.message);
      toast.error( error?.response?.data?.message ||"City Already Exists", {
        autoClose: 2000,
      });
    }
  };



  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleClose = () => {
    navigate('/kitty-City');
  };


  return (
    <div className="container mt-4">
     <div className="row">
      <div className="col-md-8 mx-auto">
      <div className="card p-0">
        <div className="card-body w-full p-0 pb-3">
          <div className="mb-4 border-b border-stroke py-3 px-6.5 flex justify-between items-center mb-3">
            <h2 className="card-title">{id ? "Update City" : "Add City"}</h2>
            <FaTimes className="text-danger cursor-pointer" onClick={handleClose} />
          </div>
          <form onSubmit={handleSubmit} className="flex flex-wrap px-3">
            <div className="mb-3 col-md-12 p-2">
              <label htmlFor="name" className="mb-2 block text-sm font-medium text-black dark:text-white">City Name</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="name"
                placeholder="City name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
         
            <button type="submit" className="flex ms-2 justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
              {id ? "Update" : "Submit"}
            </button>
          </form>
        </div>
      </div>
      </div>
     </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default AddCityForm;
