// DeleteConfirmationModal.js
import React from "react";
import { Modal, Button } from "react-bootstrap";

const DeleteConfirmationModal = ({ show, handleClose, handleDelete }) => {
  // Custom inline styles for the buttons
  const noButtonStyle = {
    backgroundColor: "#6c757d", // Bootstrap's secondary color
    color: "white",
    borderRadius: "8px",
    padding: "10px 20px",
    fontWeight: "bold",
    border: "none",
    transition: "background-color 0.3s ease",
  };

  const yesButtonStyle = {
    backgroundColor: "#dc3545", // Bootstrap's danger color
    color: "white",
    borderRadius: "8px",
    padding: "10px 20px",
    fontWeight: "bold",
    border: "none",
    transition: "background-color 0.3s ease",
  };

  const noButtonHoverStyle = {
    backgroundColor: "#5a6268",
  };

  const yesButtonHoverStyle = {
    backgroundColor: "#c82333",
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-black font-bold">Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-black font-semibold">
        Are you sure you want to delete this item?
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={noButtonStyle}
          onMouseEnter={(e) => (e.target.style.backgroundColor = noButtonHoverStyle.backgroundColor)}
          onMouseLeave={(e) => (e.target.style.backgroundColor = noButtonStyle.backgroundColor)}
          onClick={handleClose}
        >
          No
        </Button>
        <Button
          style={yesButtonStyle}
          onMouseEnter={(e) => (e.target.style.backgroundColor = yesButtonHoverStyle.backgroundColor)}
          onMouseLeave={(e) => (e.target.style.backgroundColor = yesButtonStyle.backgroundColor)}
          onClick={handleDelete}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
