import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import messages from "../messages.json";
import { toast, ToastContainer } from "react-toastify";
import InterestInformation from "../forms/InterestInformation";
import DeleteConfirmationModal from "../forms/DeleteConformation";
import CustomTable from "../components/commonComponent/CustomTable";
import { usePermissions } from "../hooks/PermissionsContext";

const GroupInterest = () => {
  const [categories, setCategories] = useState([]);
  const [selectedInterest, setSelectedInterest] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [interestToDelete, setInterestToDelete] = useState(null);
  const [filteredInterest, setFilteredCategories] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { hasPermission } = usePermissions();

  const showView = hasPermission('Group Interest', 'View');
  const showAdd = hasPermission('Group Interest', 'Add');
  const showEdit = hasPermission('Group Interest', 'Update');
  const showDelete = hasPermission('Group Interest', 'Delete');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getAllGroupsInterest`);
        setCategories(response.data.data);
        setFilteredCategories(response.data.data);
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    filterCategories();
  }, [searchInput, categories]);

  const filterCategories = () => {
    let filtered = categories;
    if (searchInput !== "") {
      filtered = filtered.filter(
        (interest) =>
          interest.name &&
          interest.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    setFilteredCategories(filtered);
  };

  const handleEdit = (id) => {
    navigate(`/group-interest/update/${id}`);
  };

  const handleShowDeleteModal = (interestId) => {
    setInterestToDelete(interestId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setInterestToDelete(null);
  };

  const handleDelete = async () => {
    if (interestToDelete)
      try {
        await axios.delete(`${apiUrl}/deleteInterestGroup/${interestToDelete}`);
        setCategories(
          categories.filter((interest) => interest._id !== interestToDelete)
        );
        toast.success(messages.success.delete, { autoClose: 2000 });
      } catch (error) {
        console.error(messages.error.errorDeleting, error);
        toast.error(messages.error.errorDeleting, {
          autoClose: 2000,
        });
      } finally {
        setShowDeleteModal(false);
        setInterestToDelete(null);
      }
  };

  const handleView = async (interestId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/getGroupInterestById/${interestId}`
      );
      console.log("API Response:", response.data);
      setSelectedInterest(response.data);
      setShowModal(true);
    } catch (error) {
      console.error(messages.error.fetchData, error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedInterest(null);
  };

  const columns = [
    { header: "S.No", accessor: "id" },
    { header: "Interest Name", accessor: "name" },
  ];

  return (
    <div className="container mt-4">
      <div className="row mb-3 align-items-center w-full max-w-7xl mx-auto">
        <div className="col">
          <h2 className="table-heading">Group Interest Information</h2>
        </div>


        {showAdd && (
        <div className="col-auto">
          <Link to="/group-interest/add" className="flex justify-center rounded bg-[#1c2434] py-2 px-6 font-medium text-gray hover:bg-opacity-90">
            Add Interest
          </Link>
        </div>
        )}
      </div>

      <CustomTable
        tableData={categories}
        columns={columns}
        handleView={handleView}
        handleUpdate={handleEdit}
        handleShowDeleteModal={handleShowDeleteModal}
        actions={{
          showView,
          showEdit,
          showDelete,
        }}
      />

      {selectedInterest && (
        <InterestInformation
          interest={selectedInterest}
          show={showModal}
          handleClose={handleCloseModal}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={handleDelete}
        />
      )}
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default GroupInterest;
