import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "../components/Card";
import BarChart from "../components/BarChart";
import DonutChart from "../components/DonutChart";
import { FaUsers, FaUsersCog, FaBuilding, FaPiggyBank } from "react-icons/fa";
import "./dashboard.css";
import messages from "../messages.json";
import { MdGroups2 } from "react-icons/md";
import { FaHotel } from "react-icons/fa";
import { MdCardMembership } from "react-icons/md"
import { FaUser } from "react-icons/fa";

const Dashboard = () => {
  const [counts, setCounts] = useState({
    user: 0,
    group: 0,
    venue: 0,
    kitty: 0,
  });
  const [barChartData, setBarChartData] = useState([]);
  const [circleChartData, setCircleChartData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/getUserKittyVenueGroupCount`
        );
        const data = response.data;
        setCounts({
          user: data.user,
          group: data.group,
          venue: data.venue,
          kitty: data.kitty,
        });
        // Prepare chart data
        setBarChartData([
          { name: "Kitty Users", data: [data.user] },
          { name: "Kitty Groups", data: [data.group] },
          { name: "Kitty Venues", data: [data.venue] },
          { name: "Kitty Count", data: [data.kitty] },
        ]);

        setCircleChartData([data.user, data.group, data.venue, data.kitty]);
      } catch (error) {
        console.error(messages.error.fetchData, error);
      }
    };

    fetchCounts();
  }, [apiUrl]);

  return (
   <div className="container">
     <div className="mx-auto max-w-screen-2xl py-6">
      {/* Header */}
      <h1 className="text-3xl font-semibold text-gray-900 mb-8 font-nunito">
        Admin Dashboard
      </h1>

      <div class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <div class="rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default  ">
          <div class="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 ">
          <FaHotel />
          </div>
          <div class="mt-4 flex items-end justify-between">
            <div>
              <h4 class="text-title-md font-bold text-black ">
                {counts.venue}
              </h4>
              <span class="text-sm font-medium">Total Venues</span>
            </div>
          
          </div>
        </div>
        <div class="rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default  ">
          <div class="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 ">
        
          <MdCardMembership />
          </div>
          <div class="mt-4 flex items-end justify-between">
            <div>
              <h4 class="text-title-md font-bold text-black ">
                {counts.kitty}
              </h4>
              <span class="text-sm font-medium">Total Kitty</span>
            </div>
         
          </div>
        </div>
        <div class="rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default  ">
          <div class="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 ">
          <MdGroups2/>
          </div>
          <div class="mt-4 flex items-end justify-between">
            <div>
              <h4 class="text-title-md font-bold text-black ">
                {counts.group}
              </h4>
              <span class="text-sm font-medium">Total Groups</span>
            </div>
          
          </div>
        </div>
        <div class="rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default  ">
          <div class="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 ">
          <FaUser />
          </div>
          <div class="mt-4 flex items-end justify-between">
            <div>
              <h4 class="text-title-md font-bold text-black ">{counts.user}</h4>
              <span class="text-sm font-medium">Total Users</span>
            </div>
         
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div className="mt-10 grid grid-cols-1 gap-4 lg:grid-cols-2">
        {/* Bar Chart */}
        <div className="bg-white p-6 shadow-md border border-gray-200 ">
          <h2 className="text-xl font-medium text-gray-900 mb-6">
            Kitty Statistics
          </h2>
          <BarChart data={barChartData} />
        </div>

        {/* Donut Chart */}
        <div className="bg-white p-6 shadow-md border border-gray-200">
          <h2 className="text-xl font-medium text-gray-900 mb-4 ">
            Participation Breakdown
          </h2>
          <DonutChart
            data={circleChartData}
            labels={["Users", "Groups", "Venues", "Kitty"]}
          />
        </div>
      </div>
    </div>

   </div>
  );
};

export default Dashboard;
